<!-- Created by henian.xu on 2020/4/27. -->

<template>
    <Page>
        <Container>
            <DynamicForm
                ref="dynamicForm"
                :data="formData"
            />
        </Container>
        <PageBar
            v-if="isEdit"
            position="bottom">
            <XButton
                class="item"
                label="提交"
                theme="main"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { getMaterialsValueList, MaterialsDetailMixin } from 'pages/Materials/store';

export default {
    name: 'EditMaterials',
    mixins: [MaterialsDetailMixin],
    data() {
        return {};
    },
    computed: {
        isNeedSignature() {
            const { formDataJson, submissionMode } = this;
            if (submissionMode !== 1 || !formDataJson) return false;
            const { fields } = formDataJson;
            if (!fields) return false;
            return fields.some(field => field.type === 'signature');
        },
        isNeedPMImage() {
            return this.submissionMode === 2;
        },
    },
    methods: {
        async onSubmit() {
            const formModel = await this.$refs.dynamicForm.submit();
            const { materialsValueType, materialsValueId, materialsId, isNeedSignature, isNeedPMImage } = this;
            const { msg } = await this.$api.Member.Sv.MaterialsValue.saveMaterials({
                valueType: materialsValueType,
                valueId: materialsValueId,
                materialsId,
                materialsValue: JSON.stringify(formModel),
            });
            await getMaterialsValueList({ valueType: materialsValueType, valueId: materialsValueId });
            const query = {
                valueType: materialsValueType,
                valueId: materialsValueId,
            };
            if (isNeedSignature) {
                query.isNeedSignature = true;
                await this.$router.replace({
                    path: `/materials/previewMaterials/${materialsId}`,
                    query,
                });
            } else if (isNeedPMImage) {
                query.isNeedPMImage = true;
                query.isPreviewDownload = true;
                await this.$router.replace({
                    path: `/materials/previewMaterials/${materialsId}`,
                    query,
                });
            } else {
                await this.$router.back();
            }
            if (msg) this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
</style>
