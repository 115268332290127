<!-- Created by henian.xu on 2020/7/9. -->

<template>
    <Page>
        <Container fill>
            <div
                v-if="applyDetail.groupQrCodeUrl"
                class="qrcode-wrap"
            >
                <div class="header">
                    <div class="label">{{applyDetail.certCatName}}</div>
                    <div class="desc">学习群</div>

                </div>
                <XImage
                    width="50%"
                    :aspect-ratio="1"
                    :src="applyDetail.groupQrCodeUrl"
                ></XImage>
                <div class="footer">
                    <div class="label">长按识别二维码立即加入</div>
                    <div class="desc">和同学一起交流学习，请教更顺利哦!</div>
                    <div
                        v-if="applyDetail.teacherTel"
                        class="desc">班主任电话：<a
                            class="tc-primary"
                            :href="`tel://applyDetail.teacherTel`">{{applyDetail.teacherTel}}</a>（微信同号）</div>
                </div>
            </div>
            <div
                v-else-if="!detail.isNull"
                class="qrcode-wrap"
            >
                <div class="header">
                    <div class="desc">糟糕，学习群还未建立</div>
                    <div class="desc">请联系老师建立学习群</div>
                </div>
                <XImage
                    width="50%"
                    :aspect-ratio="1"
                    :src="customerImage"
                ></XImage>
                <div class="footer">
                    <div class="desc">长按识别二维码添加老师微信</div>
                    <div
                        v-if="applyDetail.teacherTel"
                        class="desc">班主任电话：<a
                            class="tc-primary"
                            :href="`tel://applyDetail.teacherTel`">{{applyDetail.teacherTel}}</a>（微信同号）</div>
                </div>
            </div>
        </Container>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Learn',
    data() {
        return {
            certExamApplyId: this.$route.params.id,
            detail: { isNull: true },
        };
    },
    computed: {
        ...mapGetters('PortalInfo', ['customerImage']),
        applyDetail() {
            return this.detail.applyDetail || {};
        },
    },
    methods: {
        async getDetail() {
            const { data } = await this.$api.Member.Ce.CertExamApply.applyDetail(this.certExamApplyId);
            this.detail = data || {};
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
.qrcode-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: $margin-big * 5;

    > .header {
        margin-bottom: $margin-big;
        > .label {
            font-size: $big * 100%;
            font-weight: bold;
            margin-bottom: $margin-small;
        }
    }
    > .footer {
        margin-top: $margin-big;
        > .desc {
            margin-top: $margin;
            color: $color-text-minor;
        }
    }
}
</style>
