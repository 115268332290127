<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container>
            <XForm
                v-if="invoiceDetail&&invoiceDetail.orderId"
                ref="form"
                :model="formModel"
                :rules="formRules"
                align="right"
                border
                show-feedback
                :disabled="!!invoiceStatus"
            >
                <template v-if="invoiceDetail">
                    <FormItem label="关联订单:">
                        <div class="tc-minor">{{invoiceDetail.orderSn}}</div>
                    </FormItem>
                    <FormItem label="开票金额(元):">
                        <div class="price">{{invoiceDetail.invoiceAmount}}</div>
                    </FormItem>
                </template>
                <FormItem
                    key="invoiceTitleType"
                    prop="invoiceTitleType"
                    label="抬头类型:">
                    <XSelect
                        tiling
                        :tiling-column="4"
                        :data="invoiceTitleType"
                        v-model="formModel.invoiceTitleType"
                    />
                </FormItem>
                <FormItem
                    prop="invoiceType"
                    label="发票类型:">
                    <XSelect
                        :data="invoiceTypeList"
                        v-model="formModel.invoiceType"
                    />
                </FormItem>
                <template v-if="!isElectronicInvoice">
                    <FormItem
                        v-if="isEnterprise"
                        key="enterpriseInvoiceTitle"
                        prop="invoiceTitle"
                        label="发票抬头:">
                        <EnterprisePicker
                            @change="(val)=>{onEnterprisePickerChange(val)}"
                            v-model="formModel.invoiceTitle"/>
                    </FormItem>
                    <FormItem
                        v-else
                        key="personalInvoiceTitle"
                        prop="invoiceTitle"
                        label="发票抬头:">
                        <XInput v-model="formModel.invoiceTitle"/>
                    </FormItem>
                    <FormItem
                        v-if="isEnterprise"
                        prop="taxIdentificationSn"
                        label="纳税人识别号:">
                        <XInput v-model="formModel.taxIdentificationSn"/>
                    </FormItem>
                    <!--                    <FormItem
                        prop="invoiceRemarks"
                        label="备注:"
                        direction="column"
                    >
                        <XInput
                            type="textarea"
                            v-model="formModel.invoiceRemarks"
                        />
                    </FormItem>-->
                    <FormItem
                        v-if="isSendEmail"
                        prop="receiveEmail"
                        label="收票邮箱地址:">
                        <XInput v-model="formModel.receiveEmail"/>
                    </FormItem>
                    <FormItem
                        v-else
                        prop="consignee"
                        label="收件地址:"
                        direction="column"
                    >
                        <AddressPicker
                            v-model="formModel.memberDeliveryAddress"
                        />
                    </FormItem>

                    <!-- ---- -->
                    <FormItem
                        prop="bankName"
                        label="开户银行名称:"
                        direction="column"
                    >
                        <XInput
                            placeholder="请填写您开户许可证上的开户银行"
                            type="textarea"
                            v-model="formModel.bankName"
                            :min-row="1"
                            :max-row="3"
                        />
                    </FormItem>
                    <FormItem
                        prop="bankAccount"
                        label="基本开户账号:"
                        direction="column"
                    >
                        <XInput
                            placeholder="请填写您开户许可证上的开户账号"
                            type="textarea"
                            v-model="formModel.bankAccount"
                            :min-row="1"
                            :max-row="3"
                        />
                    </FormItem>
                    <FormItem
                        prop="registerAddress"
                        label="注册场所地址:"
                        direction="column"
                    >
                        <XInput
                            placeholder="请填写您营业执照上的注册地址"
                            type="textarea"
                            v-model="formModel.registerAddress"
                            :min-row="1"
                            :max-row="3"
                        />
                    </FormItem>
                    <FormItem
                        prop="registerTel"
                        label=" 注册固定电话:"
                        direction="column"
                    >
                        <XInput
                            placeholder="请填写您公司有效的联系电话"
                            type="textarea"
                            v-model="formModel.registerTel"
                            :min-row="1"
                            :max-row="3"
                        />
                    </FormItem>
                </template>
            </XForm>
        </Container>
        <PageBar
            v-if="invoiceDetail&&invoiceDetail.orderId"
            position="bottom">
            <XButton
                class="item"
                :label="isElectronicInvoice?'下一步':'提交申请'"
                theme="success"
                :disabled="!!invoiceStatus"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { state as OrderStoreState } from 'pages/Order/OrderStore';
import { createNamespacedHelpers } from 'vuex';
import { isFunction, Valid } from 'utils';
import EnterprisePicker from 'pages/Invoice/_EnterprisePicker';
import { state, getInvoiceDetail } from './InvoiceStore';

const { mapGetters: dicMapGetters } = createNamespacedHelpers('Dictionary');

export default {
    name: 'AddPage',
    components: { EnterprisePicker },
    data() {
        return {
            orderId: this.$route.params.id,
            formModel: {
                invoiceType: '',
                invoiceTitleType: '2',
                invoiceTitle: '',
                taxIdentificationSn: '',
                receiveEmail: '',
                memberDeliveryAddress: {},
                consignee: '',
                phone: '',
                regionId: '',
                address: '',
                bankName: '',
                bankAccount: '',
                registerAddress: '',
                registerTel: '',
                invoiceRemarks: '',
            },
            formRules_: {
                invoiceType: {
                    required: true,
                    message: '不能为空',
                },
                invoiceTitleType: {
                    required: true,
                    message: '不能为空',
                },
                invoiceTitle: {
                    required: true,
                    message: '不能为空',
                },
                taxIdentificationSn: {
                    required: true,
                    message: '不能为空',
                },
                receiveEmail: [
                    {
                        required: true,
                        message: '不能为空',
                    },
                    {
                        validator: Valid.email,
                        message: '邮箱格式不正确',
                    },
                ],
                consignee: {
                    required: true,
                    message: '不能为空',
                },
            },
            detail_: null,
            invoiceType: [],
        };
    },
    computed: {
        ...dicMapGetters(['invoiceTitleType']),
        invoiceDetail() {
            const { orderId } = this;
            const { invoiceDetail } = state;
            if (!invoiceDetail || `${invoiceDetail.orderId}` !== `${orderId}`) {
                getInvoiceDetail(orderId);
                return null;
            }
            return invoiceDetail;
        },
        invoiceStatus() {
            const { invoiceDetail } = this;
            return invoiceDetail && this.invoiceDetail.invoiceStatus;
        },
        isSendEmail() {
            const {
                formModel: { invoiceType },
            } = this;
            return `${invoiceType}` === '1001';
        },
        isEnterprise() {
            const {
                formModel: { invoiceTitleType },
            } = this;
            return `${invoiceTitleType}` === '1';
        },
        invoiceTypeList() {
            const {
                invoiceType,
                formModel: { invoiceTitleType },
            } = this;
            if (!invoiceTitleType || !invoiceType || !invoiceType.length) return [];
            if (`${invoiceTitleType}` === '2') {
                // 当抬头类型为个人时过滤增值税发票
                return invoiceType.filter(({ id }) => id >= 1000 && id < 2000);
            }
            return invoiceType;
        },
        formRules() {
            const {
                formModel: { invoiceTitleType, invoiceType },
                formRules_,
            } = this;
            let isRequired = +invoiceType && !(+invoiceType >= 100 && +invoiceType < 2000);
            if (+invoiceTitleType === 1) isRequired = true;
            console.log(isRequired, +invoiceType, invoiceTitleType);
            const rules = isRequired
                ? [
                      'bankName',
                      'bankAccount',
                      'registerAddress',
                      'registerTel',
                      // add
                  ].reduce((pre, cur) => {
                      pre[cur] = {
                          required: true,
                          message: '不能为空',
                      };
                      return pre;
                  }, {})
                : {};
            return { ...formRules_, ...rules };
        },
        isElectronicInvoice() {
            const {
                formModel: { invoiceType },
            } = this;
            return invoiceType === 1001;
        },
    },
    watch: {
        'formModel.memberDeliveryAddress': {
            handler(val) {
                const { formModel } = this;
                formModel.consignee = val.consignee;
                formModel.phone = val.phone;
                formModel.regionId = val.regionId;
                formModel.address = val.address;
            },
        },
        'formModel.invoiceTitleType': {
            handler() {
                this.formModel.invoiceType = '';
            },
        },
        formRules() {
            const { form } = this.$refs;
            if (!form || !isFunction(form.clearValidate)) return;
            form.clearValidate();
        },
        invoiceDetail: {
            handler(val) {
                const { formModel, orderId } = this;
                if (!val || `${val.orderId}` !== `${orderId}`) return;
                console.log(val);
                Object.keys(formModel).forEach(key => {
                    if (!this.$utils.hasOwn(val, key)) return;
                    formModel[key] = val[key] || '';
                });
                if (val.consignee) {
                    formModel.memberDeliveryAddress = {
                        consignee: val.consignee,
                        phone: val.phone,
                        regionId: val.regionId,
                        address: val.address,
                        regionNamePath: val.regionNamePath,
                    };
                }
            },
        },
    },
    methods: {
        async getInvoiceTypeList() {
            const { data } = await this.$api.Member.Sv.Servant.invoiceTypeList();
            this.invoiceType = data;
        },
        async onEnterprisePickerChange(val) {
            if (!val) return;
            const code = val.code || '';
            if (!code) return;
            const res = await this.$api.Member.Ex.OrderInvoice.enterpriseDetail({
                code,
            });
            if (!res.success) return;
            this.formModel.taxIdentificationSn = res.data || '';
        },
        async onSubmit() {
            if (this.isElectronicInvoice) {
                this.onCreateOrderInvoice();
                return;
            }
            await this.$refs.form.validate();
            const { formModel, orderId } = this;
            const { msg } = await this.$api.Member.Ex.OrderInvoice.saveOrderInvoice({
                orderId,
                ...formModel,
            });
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
            await this.$nextTick();
            OrderStoreState.needRefresh = true;
            state.invoiceDetail = null;
        },
        async onCreateOrderInvoice() {
            const { data } = await this.$api.Member.Ex.OrderInvoice.createOrderInvoice({
                orderId: this.orderId,
            });
            if (!data) return;
            window.location.href = data;
        },
    },
    mounted() {
        this.getInvoiceTypeList();
    },
};
</script>

<style lang="scss">
</style>
