<!-- Created by henian.xu on 2020/7/24. -->

<template>
    <Dialog
        ref="dialog"
        :label="`随机练习${countdown?` (剩余${countdown}秒)`:''}`"
        :show-close-btn="showCloseBtn"
        @close="onDialogClose"
    >
        <div
            class="ta-c pa-a"
        >
            <Topic
                index=""
                :data="question"
                v-model="answers"></Topic>
        </div>
        <template #footer>
            <div class="footer bc-w">
                <XButton
                    theme="success"
                    :disabled="!answers"
                    @click="onSaveAnswer">确认</XButton>
            </div>
        </template>
    </Dialog>
</template>

<script>
export default {
    name: 'CourseRecognitionQuestionDialog',
    data() {
        return {
            promise: null,
            errorMessage: '',
            questionDetail: {},
            answers: 0,
            countdown: 0,
            countdownTimer: 0,
        };
    },
    props: {
        params: {
            type: Object,
            default: () => ({}),
        },
        questionAuthTime: {
            type: Number,
            default: 0,
        },
        showCloseBtn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        certExamApplyId() {
            return this.params.certExamApplyId || 0;
        },
        logId() {
            return this.questionDetail.logId || 0;
        },
        question() {
            return this.questionDetail.question || {};
        },
    },
    methods: {
        async validate() {
            this.answers = 0;
            const { data } = await this.$api.Member.Ce.CertExamCourse.getRandomQuestion({
                ...this.params,
            });
            this.questionDetail = data || {};
            this.startCountdown();
            const promise = new Promise((resolve, reject) => {
                this.promise = {
                    resolve,
                    reject,
                };
            });
            this.$refs.dialog.open();
            return promise;
        },
        startCountdown() {
            clearInterval(this.countdownTimer);
            if (!this.questionAuthTime) return;
            this.countdown = this.questionAuthTime;
            this.countdownTimer = setInterval(() => {
                this.countdown -= 1;
                if (this.countdown <= 0) {
                    clearInterval(this.countdownTimer);
                    if (this.answers) this.onSaveAnswer();
                    else this.onDialogClose();
                }
            }, 1000);
        },
        async onSaveAnswer() {
            const { certExamApplyId, question, answers, logId } = this;
            if (!answers) return;
            const { questionAnswer, id: questionId } = question;
            console.log(questionAnswer, question, questionId, answers);
            this.$api.Member.Ce.CertExamCourse.questionRecognition({
                logId,
                selectOption: answers,
            });
            // 加入错题本
            if (questionAnswer !== answers) {
                try {
                    await this.$api.Member.Ce.CertExamPaper.addErrorBook({
                        certExamApplyId,
                        questionId,
                    });
                } catch (e) {
                    // noop
                }
                this.$messageBox.tips('答题错误已加入错题本');
            } else {
                this.$messageBox.tips('答题正确');
            }
            if (this.promise) {
                this.promise.resolve();
                this.promise = null;
            }
            this.$refs.dialog.close();
        },
        onUploadStart() {
            this.errorMessage = '';
        },
        async onUploadSuccess(data) {
            console.log('onUploadStart', data);
            if (this.promise) {
                this.promise.resolve();
                this.promise = null;
            }
            this.$refs.upload.clear();
            this.$refs.dialog.close();
        },
        onDialogClose() {
            if (this.promise) {
                this.promise.reject();
                this.promise = null;
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.countdownTimer);
        if (this.promise) {
            this.promise.reject({ destroy: true });
            this.promise = null;
        }
    },
};
</script>

<style lang="scss">
</style>
