<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <Article
                v-if="detail.id"
                :label="detail.courseName"
            >
                <template v-if="isImage">
                    <ImageList :data="courseImageList"></ImageList>
                </template>
                <XVideo
                    v-if="isVideo"
                    ref="XVideo"
                    :src="detail.courseVideoUrl"
                    :cover="detail.courseImageUrl"
                    :disabled-progress-bar="!isCompletion"
                    @pause="(e)=>{onVideoPause(e)}"
                    @ended="(e)=>{onVideoPause(e)}"
                    @play="(e)=>{onVideoPLay(e)}"
                    @ready="(e)=>{onVideoReady(e)}"
                />
            </Article>
            <CourseRecognitionDialog
                ref="recognitionDialog"
                show-close-btn
                :params="{certExamApplyId,certExamCourseDetailId:courseId}"
            ></CourseRecognitionDialog>
            <CourseRecognitionSignatureDialog
                ref="recognitionSignatureDialog"
                show-close-btn
                :params="{certExamApplyId,certExamCourseDetailId:courseId}">
            </CourseRecognitionSignatureDialog>
            <CourseRecognitionQuestionDialog
                ref="recognitionQuestionDialog"
                show-close-btn
                :question-auth-time="questionAuthTime"
                :params="{certExamApplyId,certExamCourseDetailId:courseId}"
            ></CourseRecognitionQuestionDialog>
        </Container>
        <PageBar
            v-if="listData && listData.length"
            position="bottom"
            class="pa-l pa-r"
        >
            <XButton
                label="上一课"
                theme="success"
                radius="all"
                :disabled="!currentIndex"
                @click="onPrev"
            ></XButton>
            <XButton
                label="下一课"
                theme="success"
                radius="all"
                :disabled="currentIndex+1 === courseListLength"
                @click="onNext"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins/index';
import CourseRecognitionDialog from 'pages/Course/_RecognitionDialog';
import { integrateAsync, Device } from '@/utils';
import CourseRecognitionQuestionDialog from 'pages/Course/_CourseRecognitionQuestionDialog';
import CourseRecognitionSignatureDialog from 'pages/Course/_RecognitionSignatureDialog';
import { getCourseList, state } from './courseStore';

export default {
    name: 'DetailPage',
    components: { CourseRecognitionSignatureDialog, CourseRecognitionQuestionDialog, CourseRecognitionDialog },
    mixins: [pageMixin],
    data() {
        return {
            courseId: this.$route.params.id,
            certExamApplyId: this.$route.query.certExamApplyId,
            heartbeatTimer: 0,
            detail: {},
            handleReadying: false,
        };
    },
    computed: {
        listData() {
            const { listData, certExamApplyId } = state;
            if (this.certExamApplyId && +this.certExamApplyId !== +certExamApplyId) {
                getCourseList(this.certExamApplyId);
                return [];
            }
            return listData;
        },
        currentIndex() {
            const { courseId, certExamApplyId } = this;
            if (!certExamApplyId) return '';
            const { listDataMap } = state;
            const course = listDataMap[courseId] || {};
            return course.__index || 0;
        },
        courseListLength() {
            const { certExamApplyId } = this;
            if (!certExamApplyId) return 0;
            const { courseList } = state;
            return courseList.length;
        },
        courseType() {
            return +this.detail.courseType;
        },
        isRichText() {
            return this.courseType === 1;
        },
        isImage() {
            return this.courseType === 2;
        },
        isVideo() {
            return this.courseType === 3;
        },
        isPdf() {
            return this.courseType === 4;
        },
        courseImageList() {
            return this.detail.courseImageList;
        },
        progressConfig() {
            return this.detail.progressConfig || {};
        },
        videoPlayTime() {
            const { isVideo, progressConfig } = this;
            if (!isVideo) return 0;
            return progressConfig.videoPlayTime || 0;
        },
        courseAuthType() {
            return +this.detail.courseAuthType || 0;
        },
        // 答题频率 分钟 已换算为秒
        questionAuthRate() {
            const { courseAuthType } = this;
            if (!courseAuthType || !(courseAuthType & 4)) return 0;
            return (+this.detail.questionAuthRate || 0) * 60;
            // return 10;
        },
        // 答题时长 秒
        questionAuthTime() {
            return +this.detail.questionAuthTime || 0;
        },
        isIos() {
            return Device.ios;
        },
        docHidden() {
            return Device.docHidden;
        },
        completionRatio: {
            get() {
                return (this.detail.completionRatio * 100).toFixed(0);
            },
            set(val) {
                this.detail.completionRatio = val || 0;
            },
        },
        isCompletion() {
            return this.completionRatio >= 100;
        },
    },
    watch: {
        docHidden(val) {
            console.log(val);
            if (val) {
                const {
                    $refs: { XVideo },
                } = this;
                if (XVideo) {
                    XVideo.pause();
                    XVideo.cancelFullScreen();
                }
                clearInterval(this.heartbeatTimer);
            } else if (this.certExamApplyId) {
                if (this.isVideo) {
                    this.recordProgress().finally(() => {
                        window.location.reload();
                    });
                } else {
                    this.heartbeat();
                }
            }
        },
    },
    methods: {
        recognition: integrateAsync(async function func() {
            const {
                $refs: { XVideo },
            } = this;
            if (XVideo) {
                XVideo.pause();
                XVideo.cancelFullScreen();
            }

            const res = this.$refs.recognitionDialog.validate();
            res.then(r => {
                if (XVideo) XVideo.play();
                return r;
            }).catch(e => {
                if (e && e.destroy) return e;
                this.$router.back();
                return e;
            });
            return res;
        }),
        recognitionSignature: integrateAsync(async function func() {
            const {
                $refs: { XVideo },
            } = this;
            if (XVideo) {
                XVideo.pause();
                XVideo.cancelFullScreen();
            }

            const res = this.$refs.recognitionSignatureDialog.validate();
            res.then(r => {
                if (XVideo) XVideo.play();
                return r;
            }).catch(e => {
                if (e && e.destroy) return e;
                this.$router.back();
                return e;
            });
            return res;
        }),
        recognitionQuestion: integrateAsync(async function func() {
            const {
                $refs: { XVideo },
            } = this;
            if (XVideo) {
                XVideo.pause();
                XVideo.cancelFullScreen();
            }

            const res = this.$refs.recognitionQuestionDialog.validate();
            res.then(r => {
                if (XVideo) XVideo.play();
                return r;
            }).catch(e => {
                if (e && e.destroy) return e;
                this.$router.back();
                return e;
            });
            return res;
        }),
        async getCourseDetail() {
            const { certExamApplyId, courseId: certExamCourseDetailId } = this;
            const res = await this.$api.Member.Ce.CertExamCourse.certExamCourseDetail(
                {
                    certExamApplyId,
                    certExamCourseDetailId,
                },
                {
                    isHandleError: true,
                },
            );
            const { data, success, code, msg } = res;
            if (!success) {
                if (code === 4031) {
                    // 1:人脸识别; 8:签名
                    if (data && data & 1) {
                        await this.recognition();
                    }
                    if (data && data & 8) {
                        await this.recognitionSignature();
                    }
                    await this.$nextTick();
                    return this.getCourseDetail();
                    // return null;
                }
                this.$messageBox.alert(msg);
                return Promise.reject(res);
            }
            this.detail = data;
            if (certExamApplyId && !this.isVideo) {
                this.heartbeat();
            }
            return res;
        },
        onPrev() {
            const { currentIndex, courseListLength, certExamApplyId } = this;
            if (!courseListLength || currentIndex - 1 < 0) return;
            const course = state.courseList[currentIndex - 1];
            if (!course) return;
            const { id } = course;
            this.$router.replace(`/course/detail/${id}?certExamApplyId=${certExamApplyId}`);
        },
        onNext() {
            const { currentIndex, courseListLength, certExamApplyId } = this;
            if (!courseListLength || currentIndex + 1 >= courseListLength) return;
            const course = state.courseList[currentIndex + 1];
            if (!course) return;
            const { id } = course;
            this.$router.replace(`/course/detail/${id}?certExamApplyId=${certExamApplyId}`);
        },
        heartbeat() {
            clearInterval(this.heartbeatTimer);
            const { questionAuthRate } = this;
            let heartbeatCount = 0;
            let questionAuthRateCount = 0;
            this.heartbeatTimer = setInterval(() => {
                heartbeatCount += 1;
                questionAuthRateCount += 1;

                if (heartbeatCount >= 60) {
                    heartbeatCount = 0;
                    const { certExamApplyId, courseId: certExamCourseDetailId } = this;

                    this.$api.Member.Ce.CertExamCourse.courseLearnHeartbeat({
                        certExamApplyId,
                        certExamCourseDetailId,
                    })
                        .then(({ data, code, success }) => {
                            if (!success) {
                                if (code === 4031) {
                                    if (data === 1) {
                                        this.recognition();
                                    }
                                } else if (code === 1002) {
                                    this.$router.back();
                                    this.$messageBox.alert('学习日志异常!');
                                }
                            }
                        })
                        .finally(() => {
                            this.recordProgress();
                        });
                } else if (this.isVideo && !(heartbeatCount % 20)) {
                    this.recordProgress();
                }
                if (questionAuthRate && questionAuthRateCount >= questionAuthRate) {
                    questionAuthRateCount = 0;
                    this.recognitionQuestion().finally(() => {
                        questionAuthRateCount = 0;
                    });
                }
            }, 1000);
        },
        async onVideoReady(event) {
            const { target } = event;
            if (!target) return;
            const { isPlaying } = target;
            const { videoPlayTime } = this;
            if (isPlaying || !videoPlayTime || this.handleReadying) return;
            this.handleReadying = true;
            const d = this.$moment.duration(videoPlayTime, 'seconds');
            const time = `${Math.floor(d.asMinutes())}分${d.seconds()}秒`;
            await this.$messageBox.alert(`上次播放到 ${time}<br>要继续播放吗？`, '温馨提示', {
                confirmBtnText: '是的',
            });
            target.currentTime = ~~videoPlayTime;
            await this.$nextTick();
            target.play();
            this.handleReadying = false;
        },
        onVideoPause() {
            this.recordProgress();
            clearInterval(this.heartbeatTimer);
        },
        onVideoPLay() {
            this.heartbeat();
        },
        async recordProgress() {
            const {
                $refs: { XVideo },
            } = this;
            if (XVideo) {
                const { currentTime } = XVideo;
                const { certExamApplyId, courseId: certExamCourseDetailId } = this;
                const { data } = await this.$api.Member.Ce.CertExamCourse.courseProgressConfig({
                    certExamApplyId,
                    certExamCourseDetailId,
                    progressConfig: JSON.stringify({
                        videoPlayTime: currentTime,
                    }),
                });
                this.completionRatio = data.completionRatio || 0;
            }
        },
    },
    created() {
        this.getCourseDetail();
    },
    beforeDestroy() {
        this.recordProgress();
        clearInterval(this.heartbeatTimer);
    },
};
</script>

<style lang="scss">
</style>
