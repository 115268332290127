<!-- Created by henian.xu on 2023/12/6. -->

<template>
    <Page><Container></Container></Page>
</template>

<script>
export default {
    name: 'OrderCreatePage',
    data() {
        return {
            goodsType: this.$route.params.goodsType,
            certExamType: this.$route.params.certExamType,
            certCatId: this.$route.params.certCatId,
        };
    },
    methods: {
        async getSearchSku() {
            const { goodsType, certExamType, certCatId } = this;
            console.log('getSearchSku', goodsType, certExamType, certCatId);

            const { data: skuId } = await this.$api.Member.Gd.Goods.searchSku({
                goodsType,
                certExamType,
                certCatId,
            });

            const { data, code, success, msg } = await this.$api.Member.Ex.Order.buyNowConfirm(
                {
                    skuId,
                    quantity: 1,
                },
                {
                    isHandleError: true,
                },
            );
            if (!success) {
                if (code === 1200) {
                    await this.$messageBox.confirm(msg, '温馨提示', {
                        confirmBtnText: '立即实名',
                    });
                    this.$router.push(`/user/idCardAuth`);
                } else {
                    this.$messageBox.alert(msg);
                }
            }
            this.$router.replace(`/order/new/${data.uuid}`);
        },
    },
    created() {
        this.getSearchSku();
    },
};
</script>

<style lang="scss">
</style>
