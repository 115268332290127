/**
 * Created by henian.xu on 2020/9/25.
 *
 */

import Vue from 'vue';
import { Api } from 'api/index';

export const state = Vue.observable({
    detail: null,
});
export async function getEnterpriseDetail() {
    const { data } = await Api.Member.Mb.Enterprise.enterpriseDetail();
    state.detail = data;
}
export const enterpriseMixin = {
    computed: {
        enterprise() {
            const { detail } = state;
            const { userInfo } = this;
            // console.log(userInfo);
            if (!detail && userInfo.isEnterprise) getEnterpriseDetail();

            return state.detail || {};
        },
        cooperationMemberStat() {
            return this.enterprise.cooperationMemberStat || {};
        },
        enterpriseDetail() {
            return this.enterprise.enterprise || {};
        },
        applyMaterialsList() {
            return this.enterprise.applyMaterialsList || [];
        },
    },
};
