<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <OrderGoodsItem
                :data="applyDetail"
            />

            <template v-if="supplementApplyStatus===40">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c tc-danger">
                    <XLabel>不通过原因</XLabel>
                </div>
                <CellGroup border>
                    <Cell>
                        <template #label>
                            <div
                                class="tc-danger"
                                style="word-break: break-all"
                            >{{auditOpinion}}</div>
                        </template>
                    </Cell>
                </CellGroup>
            </template>

            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
                align="right"
                border
                show-feedback
                scroll-into-error
            >
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>报名材料</XLabel>
                </div>

                <MaterialsList
                    ref="materialsList"
                    :value-type="5"
                    :value-id="supplementApplyId"
                ></MaterialsList>

                <template v-if="supplementApplyStatus===10 || supplementApplyStatus===40">
                    <div
                        key="formModel.signature.div"
                        class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                        <XLabel>材料确认签名</XLabel>
                    </div>
                    <FormItem
                        key="formModel.signature"
                        prop="signature"
                        direction="column"
                    >
                        <EditSignature
                            is-batch
                            v-model="formModel.signature"
                        ></EditSignature>
                    </FormItem>
                </template>

                <div
                    v-if="applyDetail.applyTemplateDesc"
                    class="pa-a tc-g6">
                    <XLabel>填报说明:</XLabel>
                    <XLabel :label="applyDetail.applyTemplateDesc"></XLabel>
                </div>
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>报名备注</XLabel>
                </div>
                <FormItem
                    prop="memberMsg"
                    direction="column"
                >
                    <XInput
                        type="textarea"
                        :min-rows="2"
                        :max-rows="6"
                        v-model="formModel.memberMsg"
                        :disabled="!isEdit"
                    />
                </FormItem>
            </XForm>
        </Container>
        <PageBar
            position="bottom"
        >
            <XButton
                v-if="isEdit"
                class="item"
                label="提交审核"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
            <XButton
                v-else
                class="item"
                :label="applyDetail.supplementApplyStatusName"
                theme="primary"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import MaterialsList from 'pages/Materials/_MaterialsList';
import { pageMixin } from 'mixins';
import { state } from './store';

export default {
    name: 'ApplyMaterialsPage',
    components: { MaterialsList },
    mixins: [pageMixin],
    data() {
        return {
            supplementApplyId: this.$route.params.id,
            formModel: {
                memberMsg: '',
                signature: '',
            },
            formRules: {
                signature: {
                    required: true,
                    message: '不能为空',
                },
            },
            detail: {},
        };
    },
    computed: {
        applyDetail() {
            return this.detail.applyDetail || {};
        },
        auditOpinion() {
            // 10 40 可编辑
            return this.applyDetail.auditOpinion || '无';
        },
        supplementApplyStatus() {
            // 10 40 可编辑
            return this.applyDetail.supplementApplyStatus;
        },
        isEdit() {
            const { supplementApplyStatus } = this;
            return supplementApplyStatus === 10 || supplementApplyStatus === 40;
        },
    },
    watch: {
        applyDetail(val) {
            if (!val || !val.id) return;
            const { formModel } = this;
            formModel.memberMsg = val.memberMsg;
            const { form } = this.$refs;
            form.clearValidate();
        },
    },
    methods: {
        async validWorkRegionId(rule, value, callback) {
            const { companyName } = this.formModel;
            if (companyName && !value) {
                callback(new Error(rule.message));
                return;
            }
            callback();
        },
        async getApplyDetail() {
            const { data } = await this.$api.Member.Ce.SupplementApply.applyDetail(this.supplementApplyId);
            this.detail = data;
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const {
                formModel,
                supplementApplyId,
                $refs: { materialsList },
            } = this;
            const { msg } = await this.$api.Member.Ce.SupplementApply.submitAudit({
                supplementApplyId,
                ...formModel,
            });
            state.needRefresh = true;
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
            if (materialsList) {
                materialsList.refreshList();
            }
        },
        async onPreview(item) {
            const { isNeedSignature, isNeedPMImage, applyMaterialsId } = item;
            const { supplementApplyStatus, supplementApplyId } = this;
            const query = { supplementApplyStatus };
            if (isNeedSignature) query.isNeedSignature = true;
            if (isNeedPMImage) query.isNeedPMImage = true;
            this.$router.push({
                path: `/deferredReview/previewMaterials/${supplementApplyId}/${applyMaterialsId}`,
                query,
            });
        },
    },
    created() {
        this.getApplyDetail();
    },
};
</script>

<style lang="scss">
</style>
