<!-- Created by henian.xu on 2020/4/27. -->

<template>
    <Page>
        <Container>
            <DynamicForm
                ref="dynamicForm"
                :data="formData"
            />
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="提交"
                theme="main"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { isObject, isString } from 'utils/Common';

export default {
    name: 'EditMaterials',
    data() {
        return {
            applyMaterialsId: this.$route.params.applyMaterialsId,
            detail: {},
        };
    },
    computed: {
        formData() {
            /* return {
                initDataJson: {
                    address: '某某街道110号',
                    regionId: 5,
                    healthStatus: { name: '健康', id: 1 },
                    signature:
                        'https://oss.minduedu.com/upload/fileLibrary/2020/05/26/64979b1936404e498b33114e6b1ef505.png',
                    sex: { name: '男', id: '1' },
                    companyName: '人民发展有限公司',
                    idCardNo: '35030219891221063X',
                    fullName: '蔡剑清',
                    tel: '18359556626',
                    highestEducation: { name: '初中', id: 1 },
                    politicalAffiliation: { name: '群众', id: 2 },
                    photoTwoInch:
                        'https://oss.minduedu.com/upload/fileLibrary/2020/05/26/67d49a3a3b9a48d0b68ae4d06b8d4c7a.jpg',
                },
                formDataJson: {
                    name: 'tzzyczzsq',
                    attrs: null,
                    uiProps: { border: true, showFeedback: true, align: 'right' },
                    fields: [
                        {
                            name: 'signature',
                            label: '签名',
                            type: 'signature',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '添加经历',
                                tips: '',
                                required: true,
                                validates: null,
                            },
                        },
                        {
                            name: 'fullName',
                            label: '姓名',
                            type: 'text',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请输入',
                                tips: '',
                                required: true,
                                readonly: true,
                                validates: null,
                            },
                        },
                        {
                            name: 'sex',
                            label: '性别',
                            type: 'select',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请选择',
                                tips: '',
                                required: true,
                                validates: null,
                                options: [{ id: '1', name: '男' }, { id: '2', name: '女' }],
                                extraOptions: [{ id: '', name: '请选择' }],
                            },
                        },
                        {
                            name: 'regionId',
                            label: '地区',
                            type: 'region',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请选择',
                                tips: '家庭住址',
                                required: true,
                                validates: null,
                            },
                        },
                        {
                            name: 'address',
                            label: '详细地址',
                            type: 'textarea',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请输入',
                                tips: '家庭住址',
                                required: true,
                                validates: null,
                                minRows: 1,
                                maxRows: null,
                            },
                        },
                        {
                            name: 'companyName',
                            label: '工作单位',
                            type: 'text',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请输入',
                                tips: '',
                                required: true,
                                validates: null,
                            },
                        },
                        {
                            name: 'politicalAffiliation',
                            label: '政治面貌',
                            type: 'select',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请选择',
                                tips: '',
                                required: true,
                                validates: null,
                                options: '[DICTIONARY]:politicalAffiliation',
                                enableOther: 999999,
                                extraOptions: [{ id: '', name: '请选择' }],
                                optionsProps: null,
                            },
                        },
                        {
                            name: 'tel',
                            label: '联系电话',
                            type: 'text',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请输入',
                                tips: '',
                                required: true,
                                validates: [{ type: 'mobile', method: '', message: '手机号格式不正确' }],
                            },
                        },
                        {
                            name: 'healthStatus',
                            label: '健康状态',
                            type: 'select',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请选择',
                                tips: '',
                                required: true,
                                validates: null,
                                options: '[DICTIONARY]:healthStatus',
                                enableOther: 999999,
                                extraOptions: [{ id: '', name: '请选择' }],
                                optionsProps: null,
                            },
                        },
                        {
                            name: 'idCardNo',
                            label: '身份证号码',
                            type: 'text',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请输入',
                                tips: '',
                                required: true,
                                readonly: true,
                                validates: [{ type: 'iDCard', method: '', message: '身份证号格式不正确' }],
                            },
                        },
                        {
                            name: 'highestEducation',
                            label: '最高学历',
                            type: 'select',
                            properties: {
                                value: '',
                                defaultValue: '',
                                placeholder: '请选择',
                                tips: '',
                                required: true,
                                validates: null,
                                options: '[DICTIONARY]:highestEducation',
                                enableOther: 999999,
                                extraOptions: [{ id: '', name: '请选择' }],
                                optionsProps: null,
                            },
                        },
                        {
                            name: 'photoTwoInch',
                            label: '2寸照片',
                            type: 'file',
                            properties: {
                                value: [],
                                defaultValue: '',
                                placeholder: '',
                                tips: '2寸白底免冠',
                                required: true,
                                validates: null,
                                action: '/comm/rs/attachment/uploadFileLibrary',
                                accept: 'image/!*',
                                maxFiles: 1,
                                uiProps: {
                                    columns: 2.5,
                                    itemHeight: '140%',
                                    fit: 'contain',
                                    cropper: {
                                        aspectRatio: 0.71428,
                                    },
                                },
                            },
                            uiProps: { direction: 'column' },
                        },
                    ],
                },
            }; */
            return this.detail.formData || {};
        },
        formDataJson() {
            const { formData } = this;
            if (!formData) return null;
            const { formDataJson } = formData;
            if (!formDataJson) return null;
            try {
                if (isString(formDataJson)) return JSON.parse(formDataJson);
                if (isObject(formDataJson)) return formDataJson;
                return null;
            } catch (e) {
                return null;
            }
        },
        isNeedSignature() {
            const { formDataJson, submissionMode } = this;
            if (submissionMode !== 1 || !formDataJson) return false;
            const { fields } = formDataJson;
            if (!fields) return false;
            return fields.some(field => field.type === 'signature');
        },
        isNeedPMImage() {
            return this.submissionMode === 2;
        },
        submissionMode() {
            return this.detail.submissionMode;
        },
    },
    methods: {
        async onSubmit() {
            const formModel = await this.$refs.dynamicForm.submit();
            const { applyMaterialsId, isNeedSignature, isNeedPMImage } = this;
            const { msg } = await this.$api.Member.Mb.Enterprise.saveApplyMaterials({
                applyMaterialsId,
                applyMaterialsValue: JSON.stringify(formModel),
            });
            if (isNeedSignature) {
                await this.$router.replace(`/enterprise/previewMaterials/${applyMaterialsId}?isNeedSignature=true`);
            } else if (isNeedPMImage) {
                await this.$router.replace(
                    `/enterprise/previewMaterials/${applyMaterialsId}?isNeedPMImage=true&isPreviewDownload=true`,
                );
            } else {
                await this.$router.back();
            }
            if (msg) this.$messageBox.tips(msg);
        },
    },
    async created() {
        const { applyMaterialsId } = this;
        const { data } = await this.$api.Member.Mb.Enterprise.applyMaterialsDetail({
            applyMaterialsId,
        });
        this.detail = data;
        // this.$messageBox.loading();
    },
};
</script>

<style lang="scss">
</style>
