<!-- Created by henian.xu on 2020/9/25. -->

<template>
    <Page>
        <Container>
            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
                border
                show-feedback
                align="right"
            >
                <FormItem
                    label="合作名称"
                    prop="enterpriseCooperationName"
                >
                    <XInput v-model="formModel.enterpriseCooperationName"></XInput>
                </FormItem>
                <FormItem
                    label="导入学员"
                    prop="cooperationMemberListJson"
                >
                    <ImportStudent @input="onImportStudentInput"></ImportStudent>
                </FormItem>
                <template v-if="importStudentList && importStudentList.length">
                    <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                        <XLabel>费用明细</XLabel>
                    </div>
                    <CellGroup border>
                        <Cell
                            v-for="(item,index) in importStudentList"
                            :key="item.id"
                            :label="`${index+1}、${item.name}`"
                            :extra="`${item.quantity}*${item.price}元/人`"
                        ></Cell>
                    </CellGroup>
                    <div class="pa-a tc-g6 dp-fx fx-row fx-jc-fe fx-ai-c">
                        <XLabel>合计：<span class="tc-main">{{totalAmount}}</span>元</XLabel>
                    </div>
                </template>
                <FormItem
                    label="经办人备注"
                    direction="column"
                    prop="enterpriseRemarks"
                >
                    <XInput
                        type="textarea"
                        v-model="formModel.enterpriseRemarks"
                        placeholder="请优先安排复审的学员"></XInput>
                </FormItem>
            </XForm>
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                theme="main"
                label="提交审核"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import ImportStudent from 'pages/Enterprise/Cooperation/_ImportStudent';

const defaultFormModel = JSON.stringify({
    enterpriseCooperationName: '',
    cooperationMemberListJson: '',
    enterpriseRemarks: '',
});
export default {
    name: 'AddPage',
    components: { ImportStudent },
    data() {
        return {
            formModel: JSON.parse(defaultFormModel),
            formRules: {
                enterpriseCooperationName: {
                    required: true,
                    message: '不能为空',
                },
                cooperationMemberListJson: {
                    required: true,
                    message: '不能为空',
                },
            },
            importStudentList: [],
            totalAmount: 0,
        };
    },
    methods: {
        onImportStudentInput(detail) {
            console.log(detail);
            this.importStudentList = detail.detailList;
            this.totalAmount = detail.totalAmount;
            this.formModel.cooperationMemberListJson = JSON.stringify(detail.cooperationMemberList);
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const { msg } = await this.$api.Member.Mb.Enterprise.saveEnterpriseCooperation(this.formModel);
            this.formModel = JSON.parse(defaultFormModel);
            this.importStudentList = [];
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
.asdf {
    opacity: 0;
    display: none;
}
</style>
