<!-- Created by henian.xu on 2021/2/26. -->

<template>
    <Page>
        <Container>
            <!--            {{startDate|moment}}
            {{endDate|moment}}
            {{isDisabled}}-->
            <XForm
                v-if="startDate"
                ref="form"
                :model="formModel"
                :rules="formRules"
                align="right"
                border
                show-feedback
            >
                <FormItem
                    prop="statDate"
                    label="选择月份:">
                    <div
                        v-if="isDisabled"
                        class="tc-minor">当前报表未生成</div>
                    <DatePicker
                        v-else
                        :start-date="startDate"
                        :end-date="endDate"
                        v-model="formModel.statDate"
                        :disabled="isDisabled"
                        format="YYYY-MM"
                    />
                </FormItem>
                <div class="pa-a tc-minor">注:自企业入驻起，每月1号生成上一个月的培训月报表。</div>
            </XForm>
            <div
                v-if="startDate"
                class="pa-a ma-tb"
            >
                <XButton
                    class="item"
                    label="下载报表"
                    theme="success"
                    radius="all"
                    size="big"
                    fill
                    lock-text
                    :disabled="isDisabled"
                    @click="()=>{onSubmit()}"
                ></XButton>
            </div>
        </Container>
        <!--        <PageBar position="bottom">
            <XButton
                class="item"
                label="下载报表"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>-->
    </Page>
</template>

<script>
import { enterpriseMixin } from 'pages/Enterprise/enterpriseStore';
import { pageMixin } from 'mixins';
import { Device } from 'utils';
import Moment from 'dayjs';

export default {
    name: 'InvoiceInfoPage',
    mixins: [pageMixin, enterpriseMixin],
    data() {
        return {
            formModel: {
                statDate: '', // 时间
            },
            formRules: {
                statDate: {
                    required: true,
                    message: '请选择月份',
                },
            },
        };
    },
    computed: {
        // insertTime
        startDate() {
            const {
                enterpriseDetail: { insertTime },
            } = this;
            console.log(insertTime);
            return insertTime;
        },
        endDate() {
            const { startDate } = this;
            if (!startDate) return undefined;
            const moment = Moment().subtract(1, 'month');
            console.log(moment.format('YYYY-MM'));
            return moment.valueOf();
        },
        isDisabled() {
            if (!this.startDate) return false;
            const startDate = Moment(this.startDate).startOf('month');
            const endDate = Moment(this.endDate).startOf('month');
            return startDate.valueOf() > endDate.valueOf();
        },
    },
    methods: {
        async onSubmit() {
            if (Device.weixin && !Device.windowsWechat) {
                this.$messageBox.alert('手机端浏览器暂不支持操作，请在电脑浏览器操作!');
                return;
            }
            await this.$refs.form.validate();
            const { formModel } = this;
            this.$http.href('/member/mb/enterpriseExcel/exportEnterpriseMemberMonthStat', formModel);
        },
    },
};
</script>

<style lang="scss">
</style>
