<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container>
            <CellGroup border>
                <Cell
                    label="批次名称"
                    :extra="certificateReceiveBatch.batchName"></Cell>
                <Cell
                    label="批次编码"
                    :extra="certificateReceiveBatch.batchSn"></Cell>
                <Cell
                    label="领证状态"
                    :extra="certificateReceiveBatch.certificateReceiveStatusName"></Cell>
                <Cell
                    label="状态更新时间"
                    :extra="certificateReceiveBatch.statusChangeTime | moment('YYYY-MM-DD HH:mm:ss')"></Cell>
            </CellGroup>
            <div class="pa-a">发证数量</div>
            <CellGroup border>
                <Cell
                    v-for="(item,index) in certificateReceiveBatchStatList"
                    :key="index"
                    :label="item.certCatName"
                    :extra="item.certCatNum"></Cell>
                <Cell :label="`合计发证：${certificateReceiveBatch.detailNum}`">
                    <div
                        slot="extra"
                        class="tc-primary"
                        @click="onDownIssueCertificate">下载发证明细表</div>
                </Cell>
            </CellGroup>

            <XForm
                v-if="isConfirmMethod"
                class="ma-t"
                ref="form"
                :model="formModel"
                :rules="formRules"
                border
                show-feedback
                align="right"
            >
                <FormItem
                    label="领取方式:"
                    prop="shipMethod"
                    :rules="formRules.required"
                    direction="column"
                >
                    <Checkbox
                        v-for="item in shipMethodList"
                        :key="item.id"
                        class="ma-r"
                        type="radio"
                        :value="`${item.id}`"
                        v-model="formModel.shipMethod"
                    >{{item.name}}</Checkbox>
                </FormItem>
                <FormItem
                    v-if="`${formModel.shipMethod}`==='1'"
                    key="deliveryRegion"
                    label="地址:"
                    prop="deliveryRegion"
                    :rules="formRules.deliveryRegion"
                    direction="column"
                >
                    <AddressPicker v-model="formModel.deliveryRegion"/>
                </FormItem>
                <FormItem
                    v-if="`${formModel.shipMethod}`==='2'"
                    label="联系方式:"
                    direction="column"
                >
                    <div class="form-item-guise noop">
                        <div>
                            <div>客服电话: <a :href="`tel:${detail.customerTel}`">{{ detail.customerTel }}</a></div>
                            <div class="ma-ts">学校地址: {{ detail.schoolAddress }}</div>
                        </div>
                    </div>
                </FormItem>
            </XForm>

            <CellGroup
                v-else
                class="ma-t"
                border
            >
                <Cell
                    label="证书领取方式"
                    :extra="certificateReceiveBatch.shipMethodName"></Cell>
                <Cell  v-if="`${certificateReceiveBatch.shipMethod}`==='1'">
                    <AddressPicker
                        v-model="formModel.deliveryRegion"
                        disabled/>
                </Cell>
                <Cell  v-if="`${certificateReceiveBatch.shipMethod}`==='2'">
                    <div class="form-item-guise noop">
                        <div>
                            <div>客服电话: <a :href="`tel:${detail.customerTel}`">{{ detail.customerTel }}</a></div>
                            <div class="ma-ts">学校地址: {{ detail.schoolAddress }}</div>
                        </div>
                    </div>
                </Cell>
            </CellGroup>

            <MaterialsList
                ref="materialsList"
                v-if="materialsValueType"
                class="ma-t"
                :value-type="materialsValueType"
                :value-id="certificateReceiveId"
            ></MaterialsList>


            <CellGroup
                v-if="certificateReceiveStatus===100 && certificateReceiveBatch.trackingNumber"
                class="ma-t"
                border
            >
                <Cell
                    label="快递公司"
                    :extra="certificateReceiveBatch.expressName"></Cell>
                <Cell
                    label="运费付款方式"
                    :extra="certificateReceiveBatch.costBearingModeName"></Cell>
                <Cell
                    label="运单号"
                    :extra="certificateReceiveBatch.trackingNumber"></Cell>
            </CellGroup>
        </Container>
        <PageBar
            v-if="isShowFooter"
            position="bottom">
            <XButton
                v-if="isConfirmMethod"
                class="item"
                theme="success"
                @click="()=>{onSubmit()}"
            >确认领证方式</XButton>
            <XButton
                v-if="isConfirmReceive"
                class="item"
                theme="success"
                @click="()=>{onConfirmReceive()}"
            >确认已领证</XButton>
        </PageBar>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins';
import { Device, Valid } from 'utils';
import MaterialsList from 'pages/Materials/_MaterialsList';
import { state } from './store';

const defaultFormModel = JSON.stringify({
    id: '',
    shipMethod: '',
    deliveryRegion: {},
    deliveryConsignee: '',
    deliveryPhone: '',
    deliveryRegionId: '',
    deliveryAddress: '',
});

export default {
    name: 'DetailPage',
    components: { MaterialsList },
    mixins: [pageMixin],
    data() {
        return {
            certificateReceiveId: this.$route.params.id,
            detail: {},
            formModel: JSON.parse(defaultFormModel),
            formRules: {
                shipMethod: {
                    required: true,
                    message: '不能为空',
                },
                deliveryRegion: {
                    validator: Valid.strictRequired,
                    key: 'consignee',
                    message: '不能为空',
                },
            },
        };
    },
    computed: {
        materialsValueType() {
            const { formModel, certificateReceiveStatus } = this;
            if (`${formModel.shipMethod}` === '1') return 3;
            if (
                `${formModel.shipMethod}` === '2' &&
                (certificateReceiveStatus === 20 || certificateReceiveStatus === 100)
            )
                return 4;
            return 0;
        },
        certificateReceiveBatch() {
            return this.detail.certificateReceiveBatch || {};
        },
        certificateReceiveBatchStatList() {
            return this.detail.certificateReceiveBatchStatList || [];
        },
        certificateReceiveStatus() {
            return this.certificateReceiveBatch.certificateReceiveStatus || 0;
        },
        shipMethodList() {
            const { shipMethodDic, detail } = this;
            return shipMethodDic.reduce((pre, cur) => {
                if (detail.shipMethod & cur.id) {
                    pre.push(cur);
                }
                return pre;
            }, []);
        },
        isConfirmMethod() {
            return this.certificateReceiveStatus === 10;
        },
        isConfirmReceive() {
            return this.certificateReceiveStatus === 20;
        },
        isShowFooter() {
            return this.isConfirmMethod || this.isConfirmReceive;
        },
    },
    watch: {
        shipMethodList: {
            handler(val) {
                if (!val || !val.length) return;
                const { certificateReceiveBatch } = this;
                this.formModel.shipMethod = `${certificateReceiveBatch.shipMethod || ''}` || `${val[0].id || ''}`;
            },
            immediate: true,
        },
    },
    methods: {
        async getDetail() {
            const { certificateReceiveId } = this;
            if (!certificateReceiveId) return;
            const { data } = await this.$api.Member.Ce.CertificateReceiveBatch.detail({
                id: certificateReceiveId,
            });
            this.detail = data;
            const { certificateReceiveBatch } = data;
            const { formModel } = this;
            formModel.deliveryRegion = {
                consignee: certificateReceiveBatch.deliveryConsignee,
                phone: certificateReceiveBatch.deliveryPhone,
                regionNamePath: certificateReceiveBatch.deliveryRegionName,
                regionId: certificateReceiveBatch.deliveryRegionId,
                address: certificateReceiveBatch.deliveryAddress,
                isDefault: false,
            };
            console.log(data);
        },
        async onSubmit() {
            await this.$refs.form.validate();
            await this.$messageBox.confirm('是否确认操作!');
            const {
                certificateReceiveId,
                formModel,
                $refs: { materialsList },
            } = this;
            const { deliveryRegion } = formModel;
            const params = {
                ...formModel,
                id: certificateReceiveId,
                deliveryConsignee: deliveryRegion.consignee,
                deliveryPhone: deliveryRegion.phone,
                deliveryRegionId: deliveryRegion.regionId,
                deliveryAddress: deliveryRegion.address,
            };
            const { msg } = await this.$api.Member.Ce.CertificateReceiveBatch.confirmShipMethod(params);
            console.log(msg, params);
            state.needRefresh = true;
            await this.getDetail();
            if (msg) this.$messageBox.tips(msg);
            if (materialsList) {
                materialsList.refreshList();
            }
        },
        async onConfirmReceive() {
            await this.$messageBox.confirm('是否确认操作!');
            const { msg } = await this.$api.Member.Ce.CertificateReceiveBatch.schoolReceiveCert({
                id: this.certificateReceiveId,
            });
            state.needRefresh = true;
            await this.getDetail();
            if (msg) this.$messageBox.tips(msg);
            const {
                $refs: { materialsList },
            } = this;
            if (materialsList) {
                materialsList.refreshList();
            }
        },
        onDownIssueCertificate() {
            if (Device.weixin && !Device.windowsWechat) {
                this.$messageBox.alert('手机端浏览器暂不支持操作，请在电脑浏览器操作!');
                return;
            }
            this.$http.href('/member/mb/enterpriseExcel/exportCertificateReceiveBatchDetailList', {
                certificateReceiveBatchId: this.certificateReceiveId,
            });
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
</style>
