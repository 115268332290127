<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <Article
                :label="detail.courseName"
            >
                <template v-if="isImage">
                    <ImageList :data="courseImageList"></ImageList>
                </template>
                <template v-if="isVideo">
                    <XVideo
                        :src="detail.courseVideoUrl"
                        :cover="detail.courseImageUrl"
                    />
                </template>
                <template v-if="isRichText">
                    <div v-html="detail.courseContent"></div>
                </template>
            </Article>
        </Container>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins/index';

export default {
    name: 'DetailPage',
    mixins: [pageMixin],
    data() {
        return {
            courseId: this.$route.params.id,
            detail: {},
        };
    },
    computed: {
        courseType() {
            return +this.detail.courseType;
        },
        isRichText() {
            return this.courseType === 1;
        },
        isImage() {
            return this.courseType === 2;
        },
        isVideo() {
            return this.courseType === 3;
        },
        isPdf() {
            return this.courseType === 4;
        },
        courseImageList() {
            return this.detail.courseImageList;
        },
    },
    async created() {
        const { data } = await this.$api.Member.Sv.Course.courseDetail({
            id: this.courseId,
        });
        this.detail = data;
        console.log(data);
    },
};
</script>

<style lang="scss">
</style>
