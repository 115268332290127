<!-- Created by henian.xu on 2024/7/2. -->

<template>
    <Page>
        <container>
            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
                align="right"
                border
                show-feedback
                scroll-into-error>
                <FormItem label="项目代码">
                    <div class="form-item-guise right tc-minor">{{formModel.practicalProjectCode}}</div>
                </FormItem>
                <FormItem label="项目名称">
                    <div class="form-item-guise right tc-minor">{{formModel.practicalProjectName}}</div>
                </FormItem>
                <div class="ma-t"></div>
                <FormItem
                    label="考试用时"
                    prop="examTime">
                    <DatePicker
                        format="mm:ss"
                        v-model="formModel.examTime"></DatePicker>
                </FormItem>
                <FormItem
                    label="考试分数"
                    prop="examScore">
                    <InputNumber
                        :min="0"
                        :max="100"
                        v-model="formModel.examScore"></InputNumber>
                </FormItem>
            </XForm>
        </container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="重置"
                theme="second"
                @click="()=>{onReset()}"
            ></XButton>
            <XButton
                class="item"
                label="保存"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
export default {
    name: 'MockPracticalPage',
    data() {
        return {
            certExamApplyId: this.$route.params.certExamApplyId,

            formModel: {
                certExamApplyId: this.$route.params.certExamApplyId,
                practicalProjectId: this.$route.query.practicalProjectId,
                practicalProjectCode: this.$route.query.practicalProjectCode,
                practicalProjectName: this.$route.query.practicalProjectName,
                examTime: '03:00',
                examScore: '',
            },
            formRules: {
                examTime: {
                    required: true,
                    message: '不能为空',
                },
                examScore: {
                    required: true,
                    message: '不能为空',
                },
            },
        };
    },
    methods: {
        onReset() {
            this.formModel.examTime = '03:00';
            this.formModel.examScore = '';
            this.$refs.form.clearValidate();
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const { msg } = await this.$api.Member.Ce.CertExamApply.saveApplyPractical(this.formModel);
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
.apply-practical-info-wrap {
    margin-top: $margin;
    font-size: $little * 100%;
    @include bodyDl(4em);
}
</style>
