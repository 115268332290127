<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        ref="scrollbar"
        class="home-page"
    >
        <!--        <XVideo src="http://hotont.oss-cn-hangzhou.aliyuncs.com/test/29c4ecdf72d84f1aa0a649ee5e2ec395/PACKAGECONFIG_test/test.m3u8"></XVideo>-->
        <Widget no-show-complete/>
    </div>
</template>

<script>
import { scrollMemory } from 'packages/mixins';

export default {
    name: 'HomePage',
    mixins: [scrollMemory],
};
</script>

<style lang="scss">
.home-page {
    height: 100%;
    @include setScrollAxis('y');
}
</style>
