<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <Page>
        <PageBar class="pa-l pa-r">
            <span>剩余时间: {{countdownStr}}</span>
            <XButton
                label="交卷"
                theme="main"
                radius="all"
                @click="()=>{onSubmit()}"
            />
        </PageBar>
        <Container fill>
            <div class="cat-name-path">{{certCatNameAndTypePath}}</div>
            <TopicList
                ref="topicList"
                v-if="isActivated && questionList && questionList.length"
                :data="currentQuestionList"
                v-model="answer"
                :index="swiperActiveIndex"
                :start-index="currentQuestionStartIndex"
                @index-change="onTopicListIndexChange"
                @add-answer="()=>{onAddAnswer()}"
            />
            <RecognitionDialog
                v-if="isRecognition"
                ref="recognitionDialog"
                :params="{recognitionType:2,certExamExamId:certExamExam.id,certExamApplyId:certExamApply.id,certExamApplyPaperId:certExamApplyPaper.id}"
            ></RecognitionDialog>
        </Container>
        <PageBar
            position="bottom"
            class="pa-l pa-r"
        >
            <XButton
                label="上一题"
                theme="success"
                radius="all"
                :disabled="!topicListIndex"
                @click="onPrev"
            ></XButton>
            <AnswerCard
                :data="questionList"
                :value="answer"
                :index="topicListIndex"
                @index-change="onIndexChange"
                @submit="()=>{onSubmit()}"
            />
            <XButton
                label="下一题"
                theme="success"
                radius="all"
                :disabled="topicListIndex+1 === questionListLength"
                @click="onNext"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { formatDuration, isDef } from 'utils/index';
import { getMockExamPaper } from 'mixins/MockExamPaper';
import { refMixin } from 'packages/mixins';

export default {
    name: 'PaperPage',
    mixins: [refMixin],
    data() {
        return {
            paperId: this.$route.params.id,
            defaultPageSize: 10,
            topicListIndex: 0,
            swiperActiveIndex: 0,
            answer: [],
            answerChange: false,
            detail: {},
            countdownTimer: null,
            countdown: 1,
            isActivated: false,
            answeredNum: 0,
            heartbeatTimer: 0,
        };
    },
    computed: {
        certExam() {
            return this.detail.certExam || {};
        },
        certExamApplyPaper() {
            return this.detail.certExamApplyPaper || {};
        },
        certExamApply() {
            return this.detail.certExamApply || {};
        },
        overTime() {
            return this.certExamApplyPaper.overTime || 0;
        },
        questionList() {
            return this.certExamApplyPaper.questionList || [];
        },
        halfDefaultPageSize() {
            return ~~(this.defaultPageSize / 2);
        },
        currentQuestionStartIndex() {
            const { topicListIndex, halfDefaultPageSize } = this;
            return Math.max(topicListIndex - halfDefaultPageSize, 0);
        },
        currentQuestionList() {
            const { defaultPageSize, currentQuestionStartIndex } = this;
            const index = currentQuestionStartIndex;
            if (!this.questionList) return null;
            const questionList = [...this.questionList];
            return questionList.splice(index, defaultPageSize);
        },
        questionListLength() {
            const { questionList } = this;
            return (questionList && questionList.length) || 0;
        },
        questionListTem() {
            const { currentQuestionList } = this;
            if (!currentQuestionList) return [];
            return currentQuestionList.map(item => {
                if (!item) return '';
                return item.id;
            });
        },
        countdownStr() {
            return formatDuration(this.countdown);
        },
        certCatNameAndTypePath() {
            const { certCatNamePath, certExamTypeName } = this.certExam;
            if (!certCatNamePath) return '';
            return `${certCatNamePath}>${certExamTypeName}`;
        },
        certExamExam() {
            return this.detail.certExamExam || {};
        },
        recognitionQuestionNum() {
            return this.certExamExam.recognitionQuestionNum || 0;
        },
        recognitionNum: {
            get() {
                return this.detail.recognitionNum || 0;
            },
            set(val) {
                this.detail.recognitionNum = val;
            },
        },
        isRecognition() {
            // recognitionQuestionNum
            const {
                certExamExam,
                certExamApplyPaper: { paperType, examResult },
            } = this;
            if (paperType !== 1 || examResult !== 10 || !certExamExam.id) return false;
            const { recognitionType } = certExamExam;

            return !!(recognitionType & 2);
        },
    },
    watch: {
        answer() {
            this.answerChange = true;
        },
        countdown() {
            if (this.countdown === 0) {
                this.onSubmit(false);
            }
        },
        topicListIndex() {
            this.savePaper();
        },
    },
    methods: {
        async getPaperDetail() {
            const { data } = await this.$api.Member.Ce.CertExamPaper.paperDetail({
                certExamApplyPaperId: this.paperId,
            });
            const { certExamApplyPaper } = data;
            const { questionList } = certExamApplyPaper;
            this.answeredNum = 0;
            this.answer = questionList.map(item => {
                const { id, selectOption } = item;
                if (!isDef(selectOption)) return null;
                this.answeredNum += 1;
                return {
                    id,
                    selectOption: selectOption || 0,
                    isConfirm: true,
                };
            });
            this.$nextTick().then(() => {
                this.answerChange = false;
            });
            if (!data.recognitionNum) data.recognitionNum = 0;
            this.detail = data;
            this.heartbeat();
            if (this.isActivated) {
                this.startCountdown();
            }
        },
        async onIndexChange(val) {
            const topicList = await this.getRefsChildren('topicList');
            const { halfDefaultPageSize } = this;
            this.topicListIndex = val;
            if (this.topicListIndex < halfDefaultPageSize) {
                this.swiperActiveIndex = this.topicListIndex;
                topicList.slideTo(this.swiperActiveIndex, 0, false);
            } else {
                this.swiperActiveIndex = halfDefaultPageSize;
                topicList.slideTo(halfDefaultPageSize, 0, false);
            }
        },
        onPrev() {
            if (!this.questionListLength) return;
            this.topicListIndex -= this.topicListIndex > 0 ? 1 : 0;
            this.onIndexChange(this.topicListIndex);
        },
        onNext() {
            if (!this.questionListLength) return;
            this.topicListIndex += this.topicListIndex < this.questionListLength - 1 ? 1 : 0;
            this.onIndexChange(this.topicListIndex);
        },
        async onTopicListIndexChange(activeIndex) {
            await this.$nextTick();
            const {
                swiperActiveIndex,
                topicListIndex,
                halfDefaultPageSize,
                questionListLength,
                // add
            } = this;
            const offsetIndex = activeIndex - swiperActiveIndex;
            this.topicListIndex = Math.min(Math.max(topicListIndex + offsetIndex, 0), questionListLength);
            if (this.topicListIndex < halfDefaultPageSize) {
                this.swiperActiveIndex = this.topicListIndex;
            } else {
                this.swiperActiveIndex = halfDefaultPageSize;
                const topicList = await this.getRefsChildren('topicList');
                topicList.slideTo(halfDefaultPageSize);
            }
        },
        startCountdown() {
            clearInterval(this.countdownTimer);
            const now = new Date().getTime();
            const { overTime } = this;
            if (!overTime) return;
            this.countdown = Math.max(overTime - now, 0);
            this.countdownTimer = setInterval(() => {
                this.countdown = Math.max(this.countdown - 1000, 0);
            }, 1000);
        },
        async onSubmit(validate = true) {
            const answer = this.answer.filter(Boolean);
            const { paperId, questionListLength, certExamApply, certExamApplyPaper } = this;
            if (validate) {
                const unanswered =
                    questionListLength -
                    answer.reduce((pre, cur) => {
                        return pre + (+cur.selectOption ? 1 : 0);
                    }, 0);
                if (unanswered) {
                    await this.$messageBox.confirm(`您还有 ${unanswered} 题未答是否确认交卷`);
                } else {
                    await this.$messageBox.confirm(`您是否确认交卷`);
                }
            }
            const {
                data: { isPass, score },
            } = await this.$api.Member.Ce.CertExamPaper.completePaper({
                certExamApplyPaperId: paperId,
                questionJson: JSON.stringify(answer),
            });
            if (certExamApplyPaper.paperType === 2) {
                await getMockExamPaper(certExamApply.id);
            }

            await this.$messageBox.alert(
                isPass ? `恭喜您考试通过，您的成绩为${score}分` : `很遗憾考试未通过，您的分数为${score}分`,
            );

            this.$router.back();
        },
        savePaper() {
            if (this.answerChange) {
                this.$api.Member.Ce.CertExamPaper.savePaper({
                    certExamApplyPaperId: this.paperId,
                    questionJson: JSON.stringify(this.answer.filter(Boolean)),
                }).finally(() => {
                    this.answerChange = false;
                });
            }
        },
        async onAddAnswer() {
            this.answeredNum += 1;
            const { isRecognition, recognitionQuestionNum, recognitionNum, answeredNum } = this;
            if (!isRecognition) return;
            const currentRecognitionNum = Math.floor(answeredNum / recognitionQuestionNum);
            this.savePaper();
            console.log(currentRecognitionNum, answeredNum, recognitionQuestionNum, recognitionNum);
            if (currentRecognitionNum && currentRecognitionNum > recognitionNum) {
                await this.$refs.recognitionDialog.validate();
                this.recognitionNum += 1;
            }
        },
        practiceHeartbeat() {
            const { certExamApply } = this;
            console.log(certExamApply);
            this.$api.Member.Ce.CertExamPaper.practiceHeartbeat({
                certExamApplyId: certExamApply.id,
                practiceType: 100,
            });
        },
        heartbeat() {
            clearInterval(this.heartbeatTimer);
            let heartbeatCount = 0;
            this.practiceHeartbeat();
            this.heartbeatTimer = setInterval(() => {
                heartbeatCount += 1;
                if (heartbeatCount >= 60) {
                    heartbeatCount = 0;
                    this.practiceHeartbeat();
                }
            }, 1000);
        },
    },
    async created() {
        this.getPaperDetail();
    },
    beforeDestroy() {
        clearInterval(this.countdownTimer);
        clearInterval(this.heartbeatTimer);
    },
    activated() {
        setTimeout(() => {
            this.isActivated = true;
            this.startCountdown();
        }, 500);
    },
};
</script>

<style lang="scss">
</style>
