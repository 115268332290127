/**
 * Created by henian.xu on 2020/5/12.
 *
 */

import Vue from 'vue';
import { Api } from 'api';

export const state = Vue.observable({
    orderList: [],
    pagination: {},
    needRefresh: false,
});
export async function getOrderListData(params) {
    const res = await Api.Member.Ex.Order.orderList(params);
    state.needRefresh = false;
    state.orderList.push(...res.data);
    return res;
}
