<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <Page>
        <PageBar>
            <CertCat
                v-model="certCatId"
                @input="onCertCatInput"
            />
        </PageBar>
        <Container>
            <List
                margin
            >
                <CourseItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    :to="`/course/public/detail/${item.id}`"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins/index';

export default {
    name: 'ListPage',
    mixins: [paginationMixin],
    data() {
        return {
            certCatId: '',
        };
    },
    methods: {
        async getListData(params) {
            const { certCatId } = this;
            console.log(certCatId);
            const res = await this.$api.Member.Sv.Course.courseList({
                certCatId,
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        onCertCatInput() {
            this.refreshList();
        },
    },
};
</script>

<style lang="scss">
</style>
