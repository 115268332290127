<!-- Created by henian.xu on 2022/10/26. -->

<template>
    <div class="survey-topic">
        <div class="header">
            <span class="label">
                <template v-if="index">{{index}}.</template>
                {{ dataset.title }}
            </span>
        </div>
        <div class="body">
            <div
                v-for="item in optionList"
                :key="item.id"
                class="item"
            >
                <ExamCheckbox
                    :type="formType"
                    :disabled="disabled"
                    :value="item.evaluateDetailId"
                    v-model="model"
                >
                    {{item.option}}
                    <div
                        v-if="item.image && item.image.length"
                        class="imgs">
                        <XImage
                            v-for="image in item.image"
                            :key="image.name"
                            block
                            :src="image.address"></XImage>
                    </div>
                </ExamCheckbox>
            </div>
        </div>
    </div>
</template>

<script>
import { isArray } from 'utils';

export default {
    name: 'SurveyTopic',
    data() {
        return {};
    },
    props: {
        value: {
            type: Number,
            default: null,
        },
        dataset: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: [String, Number],
            default: '1',
        },
        disabled: {
            type: Boolean,
            default: null,
        },
    },
    computed: {
        model: {
            get() {
                const { formType, value, optionList } = this;
                if (formType !== 'checkbox') return value;
                return optionList.reduce((pre, cur) => {
                    const { option } = cur;

                    if (option & value) pre.push(option);
                    return pre;
                }, []);
            },
            set(val) {
                const { formType } = this;
                let value = val;
                if (formType === 'checkbox' && isArray(val)) {
                    value = val.reduce((pre, cur) => pre | cur, 0);
                }
                this.$emit('input', value);
            },
        },
        optionList() {
            return this.dataset.optionList || [];
        },
        questionType() {
            return this.dataset.questionType || 1;
        },
        formType() {
            const { questionType } = this;
            if (questionType === 1) return 'radio';
            if (questionType === 2) return 'radio';
            if (questionType === 3) return 'checkbox';
            if (questionType === 4) return 'radio';
            return '';
        },
    },
};
</script>

<style lang="scss">
.survey-topic {
    font-size: 36px;
    line-height: 1.5;
    background-color: $color-component-bgc;
    overflow: hidden;
    > .header,
    > .body,
    > .footer {
        margin: $padding;
    }

    > .header {
        > .label {
            font-weight: bold;
            margin-top: $margin;
            font-size: 110%;
        }
    }
    > .body {
        > .item {
            //margin: $margin 0;
            + .item {
                margin-top: -1px;
                border-top: 1px solid $color-border;
            }
        }
    }
    > .footer {
        > .result {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        > .analysis {
            margin-top: $margin;
        }
    }
}
</style>
