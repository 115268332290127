<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container>
            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
                border>
                <FormItem
                    label="收件姓名:"
                    prop="consignee"
                >
                    <XInput v-model="formModel.consignee"/>
                </FormItem>
                <FormItem
                    label="手机号码:"
                    prop="mobile"
                >
                    <XInput v-model="formModel.mobile"/>
                </FormItem>
                <FormItem
                    label="区　　域:"
                    prop="regionId"
                >
                    <RegionPicker v-model="formModel.regionId"/>
                </FormItem>
                <FormItem
                    label="详细地址:"
                    prop="address"
                >
                    <XInput
                        type="textarea"
                        :min-rows="2"
                        :max-rows="6"
                        v-model="formModel.address"
                    />
                </FormItem>
                <!--<FormItem label="标　　签:">
                    <XSelect
                        tiling
                        :tiling-column="4"
                        :data="[{id:'1',name:'家'},{id:'2',name:'公司'}]"
                        v-model="formModel.tag"
                    />
                </FormItem>-->
                <FormItem prop="isDefault">
                    <Checkbox v-model="formModel.isDefault">设为默认</Checkbox>
                </FormItem>
            </XForm>
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="重置"
                theme="second"
                @click="()=>{onReset()}"
            ></XButton>
            <XButton
                class="item"
                label="保存"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { hasOwn } from 'utils/index';
import { state, saveAddress, resetEditFormModel, handlerChoose } from './store';

export default {
    name: 'EditPage',
    data() {
        return {
            isPicker: hasOwn(this.$route.query, 'isPicker'),
        };
    },
    async beforeRouteEnter(to, form, next) {
        const { id } = to.params;
        if (id) {
            await resetEditFormModel(id);
        }
        next();
    },
    computed: {
        formModel: {
            get() {
                return state.editFormModel;
            },
            set(val) {
                state.editFormModel = val;
            },
        },
        formRules: {
            get() {
                return state.editFormRules;
            },
            set(val) {
                state.editFormRules = val;
            },
        },
    },
    methods: {
        async onReset() {
            await resetEditFormModel();
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const { msg, data } = await saveAddress(this.formModel);
            if (this.isPicker) handlerChoose(data);
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
    },
    beforeDestroy() {
        if (this.isPicker) handlerChoose();
    },
};
</script>

<style lang="scss">
</style>
