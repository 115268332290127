<!-- Created by henian.xu on 2020/4/21. -->

<template>
    <Page>
        <Container>
            <XForm
                label-width="5em"
                border
                show-feedback
                ref="form"
                :model="formModel"
                :rules="formRules"
            >
                <FormItem
                    label="反馈类型"
                    prop="feedbackType"
                >
                    <XSelect
                        :data="feedbackTypeList"
                        v-model="formModel.feedbackType"></XSelect>
                </FormItem>
                <FormItem
                    direction="column"
                    label="反馈内容"
                    prop="content"
                >
                    <XInput
                        type="textarea"
                        auto-height
                        v-model="formModel.content"></XInput>
                </FormItem>
                <FormItem
                    direction="column"
                    label="反馈图片"
                    prop="feedbackImage"
                >
                    <Upload
                        class="npa-a"
                        action="/comm/rs/attachment/uploadFileLibrary"
                        accept="image/*"
                        :columns="3"
                        :max-files="9"
                        v-model="formModel.feedbackImage"
                    />
                </FormItem>
            </XForm>
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="提交"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'FeedbackPage',
    data() {
        return {
            formModel: {
                feedbackType: '',
                content: '',
                feedbackImage: [],
            },
            formRules: {
                feedbackType: {
                    required: true,
                    message: '不能为空',
                },
                content: {
                    required: true,
                    message: '不能为空',
                },
                feedbackImage: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '不能为空',
                },
            },
        };
    },
    computed: {
        ...mapGetters('Dictionary', ['feedbackTypeList']),
    },
    methods: {
        async onSubmit() {
            await this.$refs.form.validate();
            const params = {
                ...this.formModel,
                feedbackImage: this.formModel.feedbackImage.map(item => item.name).join(','),
            };
            const { msg } = await this.$api.Member.Mb.Member.createFeedback(params);
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
</style>
