<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        class="certificate-receive-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div class="header">
            <XIcon
                content="f092"
                theme="main"
            />
            <XLabel>{{dataset.batchName}}</XLabel>
        </div>
        <div class="body">
            <div class="inner">
                <dl>
                    <dt>发放证书:</dt>
                    <dd>{{dataset.detailNum}}</dd>
                </dl>
                <dl>
                    <dt>领证状态:</dt>
                    <dd>{{dataset.certificateReceiveStatusName}}</dd>
                </dl>
                <dl>
                    <dt>状态更新:</dt>
                    <dd>{{dataset.statusChangeTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                </dl>
                <dl v-if="dataset.shipMethodName">
                    <dt>领证方式:</dt>
                    <dd>{{dataset.shipMethodName}}</dd>
                </dl>
            </div>
            <XIcon
                content="f012"
                size="small"
                theme="g5"
            />
        </div>
        <!--        <div class="footer">
            <XButton
                :label="dataset.expireStatusName"
                :theme="!dataset.expireStatus?'danger':''"
                link
            />
        </div>-->
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'CertificateReceiveItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        dataset: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {},
};
</script>

<style lang="scss">
.certificate-receive-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
    > .footer {
        margin: $padding 0;
        text-align: right;
    }
}
</style>
