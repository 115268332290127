<!-- Created by henian.xu on 2020/6/26. -->

<template>
    <Page>
        <PageBar>
            23234
        </PageBar>
        <Container>
            test
        </Container>
        <PageBar>
            23234
        </PageBar>
    </Page>
</template>

<script>
export default {
    name: 'TestPage',
    data() {
        return {};
    },
};
</script>

<style lang="scss">
</style>
