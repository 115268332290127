<!-- Created by henian.xu on 2020/3/27. -->

<template>
    <Page>
        <Container>
            <XImage
                :src="goods.mainImageUrl"
                :aspect-ratio="1"
                fit="cover"
            />
            <div class="goods-info">
                <div class="name">{{goods.goodsName}}</div>

                <XSelect
                    tiling
                    :tiling-column="4"
                    :data="skuList"
                    v-model="skuId"
                />
            </div>

            <Tab
                class="ma-t"
                @change="onTabChange"
            >
                <TabPanel
                    label="证考说明"
                    sign="examDesc"
                >
                    <div
                        class="pa-a bc-w"
                        v-html="certExamDesc"></div>
                </TabPanel>
                <TabPanel
                    v-if="currentSku&&currentSku.certExamCourseId"
                    label="课程学习"
                    sign="course"
                >

                    <div v-if="courseList && courseList.length">
                        <div
                            v-for="courseCat in courseList"
                            :key="courseCat.courseCatType"
                            :label="courseCat.courseCatTypeName"
                        >
                            <div class="pa-l pa-r"><Divider :label="courseCat.courseCatTypeName"></Divider></div>
                            <Collapse
                                v-if="courseCat.certExamCourseCatList && courseCat.certExamCourseCatList.length"
                                border>
                                <CollapseItem
                                    v-for="(cat,i) in courseCat.certExamCourseCatList"
                                    :key="cat.id"
                                    :fold="!!i"
                                    :label="cat.courseCatName"
                                >
                                    <CellGroup
                                        v-if="cat.courseDetailList && cat.courseDetailList.length"
                                        border
                                    >
                                        <Cell
                                            v-for="(item,index) in cat.courseDetailList"
                                            :key="item.id"
                                            style="background-color: transparent;"
                                            :to="item.isTrySee?`/course/detail/${item.id}`:undefined"
                                        >
                                            <template #label>
                                                <div class="label">
                                                    <Badge
                                                        theme="g7"
                                                        plain
                                                    >{{item.courseTypeName}}</Badge>
                                                    {{index+1}}.{{item.courseName}}
                                                </div>
                                            </template>
                                            <template
                                                v-if="item.isTrySee"
                                                #extra
                                            >
                                                <div class="extra tc-success">试看</div>
                                            </template>
                                        </Cell>
                                    </CellGroup>
                                </CollapseItem>
                            </Collapse>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel
                    label="学校服务"
                    sign="schoolServices"
                >
                    <ImageList :data="goodsDetailImageList"></ImageList>
                </TabPanel>
            </Tab>

            <Dialog
                ref="customerServiceDialog"
                label="长按添加客服微信"
            >
                <div class="customer-service-qrcode">
                    <XImage
                        :aspect-ratio="1"
                        :src="customerImage"
                        fit="cover"
                    />
                </div>
            </Dialog>
        </Container>
        <PageBar
            class="pa-l pa-r"
            position="bottom"
        >
            <XButton
                v-if="meiQiaAllSet"
                theme="success"
                direction="column"
                link
                @click="onCustomerServiceDialog"
            >
                <XIcon
                    content="f09b"
                    size="big"
                />
                <div class="fs-small ma-ts">联系客服</div>
            </XButton>
            <div class="item page-bar-price">
                <span class="price">{{currentPrice}}</span>
            </div>
            <XButton
                label="立即报名"
                theme="success"
                radius="all"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import MeiQiaControl from 'store/meiQia/MeiQiaControl';
import Divider from 'components/widget/decoration/Divider.vue';
import { meiQiaMixin, pageMixin } from 'mixins';

export default {
    name: 'DetailPage',
    mixins: [pageMixin, meiQiaMixin],
    components: { Divider },
    data() {
        return {
            goodsId: this.$route.params.id,
            detail: {},
            courseListCache: {},
            tabSign: 'examDesc',
            skuId: '',
        };
    },
    computed: {
        ...mapGetters('PortalInfo', ['customerImage']),
        goods() {
            return this.detail.goods || {};
        },
        goodsDetailImageList() {
            return this.goods.goodsDetailImageList || [];
        },
        skuList() {
            const { skuMap = {} } = this.goods;
            return Object.entries(skuMap)
                .map(([, sku]) => {
                    return {
                        ...sku,
                        id: sku.skuId,
                        name: sku.specDesc.split(':').pop(),
                        // add
                    };
                })
                .sort((a, b) => (a.orderNum || 0) - (b.orderNum || 0));
        },
        skuListMap() {
            return this.skuList.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        currentSku() {
            const { skuListMap, skuId } = this;
            return skuListMap[skuId] || {};
        },
        certExamDesc() {
            return this.currentSku.certExamDesc || '';
        },
        currentPrice() {
            const { currentSku, goods } = this;
            return currentSku.price || goods.price || '';
        },
        courseList() {
            const { courseListCache, currentSku } = this;
            const { certExamCourseId } = currentSku;
            if (!certExamCourseId) return [];
            const list = courseListCache[certExamCourseId];
            if (!list) this.getCourseList(certExamCourseId);
            return courseListCache[certExamCourseId];
        },
    },
    watch: {
        // 默认选择sku
        skuList: {
            handler(val) {
                if (!val || !val.length) {
                    this.skuId = '';
                    return;
                }
                this.skuId = val[0].id;
            },
            immediate: true,
        },
    },
    methods: {
        async getCourseList(certExamCourseId) {
            const { data } = await this.$api.Member.Ce.CertExamCourse.certExamCourseList({
                certExamCourseId,
            });
            const { courseListCache } = this;
            this.$set(courseListCache, certExamCourseId, data);
        },
        onTabChange({ sign }, index) {
            console.log(sign, index);
            this.tabSign = sign;
        },
        onCustomerServiceDialog() {
            MeiQiaControl.showPanel();
            /* const { customerServiceDialog } = this.$refs;
            if (customerServiceDialog) {
                customerServiceDialog.open();
            } */
        },
        async onSubmit() {
            const { skuId } = this;
            const { data, code, success, msg } = await this.$api.Member.Ex.Order.buyNowConfirm(
                {
                    skuId,
                    quantity: 1,
                },
                {
                    isHandleError: true,
                },
            );
            if (!success) {
                if (code === 1200) {
                    await this.$messageBox.confirm(msg, '温馨提示', {
                        confirmBtnText: '立即实名',
                    });
                    this.$router.push(`/user/idCardAuth`);
                } else {
                    this.$messageBox.alert(msg);
                }
                return;
            }
            this.$router.push(`/order/new/${data.uuid}`);
            // if (msg) this.$messageBox.tips(msg);
        },
    },
    async created() {
        const { data } = await this.$api.Member.Gd.Goods.goodsDetail(this.goodsId);
        this.detail = data;
        await this.$api.Member.Mb.MemberVisitLog.memberVisitLog({
            requestObjectType: 1,
            requestObjectId: this.goodsId,
        });
    },
};
</script>

<style lang="scss" scoped>
.page-bar-price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: $padding;

    > .price {
        font-size: $more * 100%;
        font-weight: bold;
    }
}
.customer-service-qrcode {
    padding: $padding;
}
</style>
<style lang="scss">
.goods-info {
    background-color: #fff;
    padding: $padding;
    > .name {
        font-weight: bold;
        margin-bottom: $margin;
    }
}
</style>
