<!-- Created by henian.xu on 2020/4/30. -->

<template>
    <Page>
        <Container>
            <div
                v-if="isNeedSignature && signatureList && signatureList.length"
                class="signature-wrap">
                <XButton
                    style="min-width: 6em;"
                    v-for="field in signatureList"
                    :key="field.name"
                    theme="main"
                    radius
                    @click="()=>{onSignature(field.name)}"
                >{{field.label}}</XButton>
            </div>
            <div
                v-if="isNeedPMImage"
                class="signature-wrap"
            >
                <template v-if="isPreviewDownload">
                    <XButton
                        style="min-width: 6em;"
                        theme="main"
                        radius
                        is-a
                        target="_blank"
                        :to="downUrl"
                    >下载PDF原件</XButton>
                    <div class="desc tc-danger">或长按图片并保存图片</div>
                </template>
                <template v-else>
                    <XButton
                        v-for="(item,index) in materialsNum"
                        :key="item"
                        style="min-width: 6em;"
                        theme="main"
                        radius
                        @click="()=>{selectFile(index)}"
                    >上传原件<template v-if="materialsNum>1">(第{{index + 1}}页)</template></XButton>
                </template>
            </div>
            <template v-for="(item,index) in materialsNum">
                <XImage
                    v-if="previewUrl"
                    :key="item"
                    width="100%"
                    :src="`${previewUrl}&index=${index}`"
                ></XImage>
            </template>
            <EditSignature
                v-show="false"
                ref="editSignature"
                @input="(val)=>{onEditSignatureInput(val)}"
            ></EditSignature>
            <Upload
                v-show="false"
                cropper
                ref="upload"
                accept="image/*"
                action="/comm/rs/attachment/uploadFileLibrary"
            ></Upload>
        </Container>
        <PageBar position="bottom">
            <XButton
                icon="f011"
                class="item fx-jc-fs"
                link
                @click="$router.back()"
            ></XButton>
            <!--<XButton
                v-if="isNeedSignature"
                class="item"
                label="签名"
                theme="main"
                @click="()=>{onSignature()}"
            ></XButton>-->
        </PageBar>
    </Page>
</template>

<script>
import { isObject, isString, hasOwn } from 'utils/Common';

export default {
    name: 'PreviewMaterialsPage',
    data() {
        return {
            continuingEducationApplyId: this.$route.params.continuingEducationApplyId,
            applyMaterialsId: this.$route.params.applyMaterialsId,
            // isNeedSignature: hasOwn(this.$route.query, 'isNeedSignature'),
            // isNeedPMImage: hasOwn(this.$route.query, 'isNeedPMImage'),
            isPreviewDownload: hasOwn(this.$route.query, 'isPreviewDownload'),
            continuingEducationApplyStatus: +this.$route.query.continuingEducationApplyStatus || 0,
            detail: {},
            currentSignatureField: '',
            now: Date.now(),
        };
    },
    head() {
        const { isNeedSignature } = this;
        let res = null;
        if (isNeedSignature) {
            res = {
                title: '材料签名',
            };
        }
        return res;
    },
    computed: {
        materialsNum() {
            return this.detail.materialsNum || 0;
        },
        previewUrl() {
            const { continuingEducationApplyId, applyMaterialsId, now, isPreviewDownload } = this;
            const fn = isPreviewDownload
                ? 'previewContinuingEducationPaperMaterials'
                : 'previewContinuingEducationMaterials';
            return `${
                this.$globalVar.isDev ? this.$globalVar.appConfig.AXIOS_DEFAULT_CONFIG.baseURL : '/rpc'
            }/comm/rs/jasper/${fn}?continuingEducationApplyId=${continuingEducationApplyId}&applyMaterialsId=${applyMaterialsId}&_T=${now}`;
        },
        downUrl() {
            const { continuingEducationApplyId, applyMaterialsId, now } = this;
            return `${
                this.$globalVar.isDev ? this.$globalVar.appConfig.AXIOS_DEFAULT_CONFIG.baseURL : '/rpc'
            }/comm/rs/jasper/downContinuingEducationPaperMaterials?continuingEducationApplyId=${continuingEducationApplyId}&applyMaterialsId=${applyMaterialsId}&_T=${now}`;
        },
        formData() {
            return this.detail.formData || {};
        },
        formDataJson() {
            const { formData } = this;
            if (!formData) return null;
            const { formDataJson } = formData;
            if (!formDataJson) return null;
            try {
                if (isString(formDataJson)) return JSON.parse(formDataJson);
                if (isObject(formDataJson)) return formDataJson;
                return null;
            } catch (e) {
                return null;
            }
        },
        initDataJson() {
            const { formData } = this;
            if (!formData) return null;
            const { initDataJson } = formData;
            if (!initDataJson) return null;
            try {
                if (isString(initDataJson)) return JSON.parse(initDataJson);
                if (isObject(initDataJson)) return initDataJson;
                return null;
            } catch (e) {
                return null;
            }
        },
        signatureList() {
            const { formDataJson } = this;
            if (!formDataJson) return false;
            const { fields } = formDataJson;
            if (!fields) return false;
            return fields.filter(field => field.type === 'signature');
        },
        applyMaterialsStatus() {
            // 30 100 不可编辑
            return this.detail.applyMaterialsStatus;
        },
        isNeedSignature() {
            const {
                $route: { query },
                applyMaterialsStatus,
                continuingEducationApplyStatus,
            } = this;
            const isNeedSignature = hasOwn(query, 'isNeedSignature');
            return (
                isNeedSignature &&
                applyMaterialsStatus !== 30 &&
                applyMaterialsStatus !== 100 &&
                continuingEducationApplyStatus !== 10 &&
                continuingEducationApplyStatus !== 20 &&
                continuingEducationApplyStatus !== 40
            );
        },
        isNeedPMImage() {
            const {
                $route: { query },
                applyMaterialsStatus,
            } = this;
            const isNeedPMImage = hasOwn(query, 'isNeedPMImage');
            return isNeedPMImage && applyMaterialsStatus !== 30 && applyMaterialsStatus !== 100;
        },
    },
    methods: {
        onSignature(fieldName) {
            this.currentSignatureField = fieldName;
            this.$refs.editSignature.open();
        },
        async onEditSignatureInput(val) {
            console.log(val);
            const { continuingEducationApplyId, applyMaterialsId, currentSignatureField } = this;
            const { msg } = await this.$api.Member.Ce.ContinuingEducationApply.saveApplyMaterials({
                continuingEducationApplyId,
                applyMaterialsId,
                applyMaterialsValue: JSON.stringify({ [currentSignatureField]: val }),
            });
            this.now = new Date().getTime();
            if (msg) this.$messageBox.tips(msg);
        },
        async saveApplyMaterials(paperMaterialsImage) {
            const { continuingEducationApplyId, applyMaterialsId, initDataJson } = this;
            const { msg } = await this.$api.Member.Ce.ContinuingEducationApply.saveApplyMaterials({
                continuingEducationApplyId,
                applyMaterialsId,
                applyMaterialsValue: JSON.stringify({ paperMaterialsImage }),
            });
            initDataJson.paperMaterialsImage = paperMaterialsImage;
            this.now = new Date().getTime();
            if (msg) this.$messageBox.tips(msg);
        },
        async selectFile(index) {
            const { address, name } = await this.$refs.upload.selectFile();
            if (!address || !name) {
                this.$messageBox.alert('上传失败!');
                return;
            }
            const { materialsNum, initDataJson } = this;
            let paperMaterialsImage = address;
            if (materialsNum > 1) {
                const oldPaperMaterialsImage = ((initDataJson || {}).paperMaterialsImage || '').split(',');
                console.log(initDataJson);
                const arr = Array(materialsNum)
                    .fill('')
                    .reduce((pre, cur, i) => {
                        pre.push(oldPaperMaterialsImage[i] || '');
                        return pre;
                    }, []);
                arr.splice(index, 1, address);
                paperMaterialsImage = arr.join(',');
            }
            this.saveApplyMaterials(paperMaterialsImage);
        },
    },
    async created() {
        const { continuingEducationApplyId, applyMaterialsId, continuingEducationApplyStatus } = this;
        if (continuingEducationApplyStatus === 10 || continuingEducationApplyStatus === 40) {
            await this.$api.Member.Ce.ContinuingEducationApply.saveApplyMaterials({
                continuingEducationApplyId,
                applyMaterialsId,
            });
        }
        const { data } = await this.$api.Member.Ce.ContinuingEducationApply.applyMaterialsDetail({
            continuingEducationApplyId,
            applyMaterialsId,
        });
        this.detail = data;
    },
};
</script>

<style lang="scss">
.signature-wrap {
    padding: $padding;
    > .x-button {
        + .x-button {
            margin-left: $margin-small;
        }
    }
    .desc {
        font-size: $little * 100%;
        color: $color-text-minor;
        margin-top: $margin-small;
    }
}
</style>
