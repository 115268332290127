<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <PageBar direction="column">
            <Tab v-model="tabIndex">
                <TabPanel
                    v-for="item in tabList"
                    :key="item.key"
                    :label="item.label"
                />
            </Tab>
        </PageBar>
        <Container>
            <!--{{tabIndex}}
            {{tabShowed}}-->
            <List
                padding
                margin
            >
                <OrderItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    :to="`/order/detail/${item.id}`"
                    @change="onOrderItem"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins/index';
import { state, getOrderListData } from './OrderStore';

export default {
    name: 'ListPage',
    mixins: [paginationMixin],
    data() {
        return {
            paginationDisabled: true,
            tabList: [
                {
                    invoiceStatus: '',
                    label: '全部',
                },
                {
                    invoiceStatus: '1',
                    label: '未开票',
                },
                {
                    invoiceStatus: '100',
                    label: '已开票',
                },
                {
                    orderStatus: '99',
                    label: '已取消',
                },
            ],
            tabShowed: {},
            invoiceStatus_: this.$route.params.invoiceStatus,
        };
    },
    computed: {
        listData: {
            get() {
                return state.orderList;
            },
            set(val) {
                state.orderList = val;
            },
        },
        pagination: {
            get() {
                return state.pagination;
            },
            set(val) {
                state.pagination = val;
            },
        },

        tabListMap() {
            return this.tabList.reduce((pre, cur, __index) => {
                const { invoiceStatus, orderStatus } = cur;
                pre[`${invoiceStatus || ''}-${orderStatus || ''}`] = {
                    ...cur,
                    __index,
                };
                return pre;
            }, {});
        },
        invoiceStatus() {
            const { tabList, tabListMap, invoiceStatus_ } = this;
            const tab = tabListMap[invoiceStatus_] || tabList[0];
            const { invoiceStatus, orderStatus } = tab;
            return `${invoiceStatus || ''}-${orderStatus || ''}`;
        },
        tabIndex: {
            get() {
                const { invoiceStatus, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = `${item.invoiceStatus || ''}-${item.orderStatus || ''}` === invoiceStatus;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                const { invoiceStatus, orderStatus } = tab;
                this.invoiceStatus_ = `${invoiceStatus || ''}-${orderStatus || ''}`;
                this.$router.replace({
                    ...this.$route,
                    params: {
                        ...this.$route.params,
                        invoiceStatus: this.invoiceStatus_ || undefined,
                    },
                });
                this.refreshList();
            },
        },
        currentTab() {
            const { tabIndex, tabList } = this;
            return tabList[tabIndex] || tabList[0];
        },
    },
    watch: {
        tabIndex: {
            handler(val) {
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
    methods: {
        getListData(params) {
            const { currentTab } = this;
            return getOrderListData({
                ...currentTab,
                ...params,
            });
        },
        onOrderItem(data) {
            if (data === null) {
                this.refreshList();
            }
        },
    },
    created() {
        this.refreshList();
    },
    activated() {
        if (state.needRefresh) {
            this.refreshList();
        }
    },
};
</script>

<style lang="scss">
</style>
