<!-- Created by henian.xu on 2022/10/26. -->

<template>
    <Page>
        <Container>
            <Article
                :label="survey.templateName"
                :time="survey.surveyTime"
            >
                <div
                    class="pa-l pa-r pa-b"
                    v-html="survey.templateStart"
                ></div>
            </Article>
            <SurveyTopicList
                :dataset="evaluateList"
                :disabled="surveyItemStatus!==10"
                v-model="formModel.answer"></SurveyTopicList>
            <XForm
                class="ma-t"
                ref="form"
                :model="formModel"
                :rules="formRules"
                border
                show-feedback
                scroll-into-error
                align="right"
                :disabled="surveyItemStatus!==10"
            >
                <FormItem
                    label="您的建议:"
                    prop="suggest"
                    direction="column"
                >
                    <XInput
                        type="textarea"
                        auto-height
                        v-model="formModel.suggest"
                    ></XInput>
                </FormItem>
            </XForm>
        </Container>
        <PageBar
            v-if="surveyItemStatus===10"
            position="bottom"
        >
            <XButton
                class="item"
                theme="success"
                @click="()=>{onSubmit()}"
            >提交</XButton>
        </PageBar>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins';
import SurveyTopicList from 'pages/Survey/_SurveyTopicList';
import { isDef } from 'utils';

export default {
    name: 'SurveyDetailPage',
    components: { SurveyTopicList },
    mixins: [pageMixin],
    data() {
        return {
            surveyId: this.$route.params.id,
            detail: {},
            formModel: {
                answer: [],
                suggest: '',
            },
            formRules: {
                /* suggest: {
                    required: true,
                    message: '不能为空',
                }, */
                answer: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '不能为空',
                },
            },
        };
    },
    computed: {
        survey() {
            return this.detail.survey || {};
        },
        surveyItem() {
            return this.detail.surveyItem || {};
        },
        evaluateList() {
            return this.detail.evaluateList || [];
        },
        evaluateListLength() {
            return this.evaluateList.length || 0;
        },
        surveyItemStatus() {
            return this.surveyItem.surveyItemStatus || 0;
        },
    },
    methods: {
        async getDetail() {
            const { data } = await this.$api.Member.Ce.SurveyItem.surveyItemDetail(this.surveyId);
            this.formModel.suggest = data.surveyItem.suggest || '';
            this.formModel.answer = data.evaluateList.map(item => {
                const { evaluateId, selectEvaluateDetailId } = item;
                if (!isDef(selectEvaluateDetailId)) return null;
                this.answeredNum += 1;
                return {
                    evaluateId,
                    selectEvaluateDetailId: selectEvaluateDetailId || 0,
                    isConfirm: true,
                };
            });
            console.log(this.formModel.answer);
            this.detail = data;
        },
        async onSubmit(validate = true) {
            const { surveyId, formModel } = this;
            const answer = formModel.answer.filter(Boolean);
            const { evaluateListLength } = this;
            if (validate) {
                const unanswered =
                    evaluateListLength -
                    answer.reduce((pre, cur) => {
                        return pre + (+cur.selectEvaluateDetailId ? 1 : 0);
                    }, 0);
                if (unanswered) {
                    await this.$messageBox.alert(`您还有 ${unanswered} 题未答!`);
                    return;
                }
                // await this.$messageBox.confirm(`您是否确认交卷`);
            }
            await this.$refs.form.validate();
            const params = {
                id: surveyId,
                ...formModel,
                evaluateListJson: JSON.stringify(formModel.answer),
            };
            delete params.answer;
            console.log(params);
            const { msg } = await this.$api.Member.Ce.SurveyItem.saveSurveyItem(params);
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
</style>
