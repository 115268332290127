<!-- Created by henian.xu on 2020/4/21. -->

<template>
    <Page>
        <PageBar>
            <Tab
                average
                v-model="tabIndex"
            >
                <TabPanel
                    v-for="item in tabList"
                    :key="item.key"
                    :label="item.label"
                />
            </Tab>
        </PageBar>
        <Container>
            <CellGroup border>
                <Cell
                    v-for="(item,index) in listData"
                    :key="index"
                    :label="item.questionTypeName||item.name"
                    :extra="`${item.questionNum}题`"
                    link
                    @click="onItem(item,index)"
                ></Cell>
            </CellGroup>
        </Container>
    </Page>
</template>

<script>
import { state, getPracticeDetail } from './practiceStore';

export default {
    name: 'CategoryPage',
    data() {
        return {
            certExamApplyId: this.$route.params.id,
            testType: this.$route.query.testType || '2',
            tabList: [
                {
                    key: '2',
                    label: '章节练习',
                },
                {
                    key: '3',
                    label: '专项练习',
                },
            ],
        };
    },
    computed: {
        tabIndex: {
            get() {
                const { testType, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = `${item.key}` === `${testType}`;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                this.testType = tab.key;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        testType: tab.key,
                    },
                });
                // this.refreshList();
            },
        },
        practiceDetail() {
            const { certExamApplyId } = this;
            const { detail } = state;
            if (detail === null || +detail.certExamApplyId !== +certExamApplyId) {
                getPracticeDetail(certExamApplyId);
                return {};
            }
            return detail;
        },
        listData() {
            const { practiceDetail, testType } = this;
            if (!practiceDetail.certExamApplyId) return [];
            if (`${testType}` === '2') {
                return practiceDetail.questionCatList;
            }
            if (`${testType}` === '3') {
                return practiceDetail.questionTypeList;
            }
            return [];
        },
    },
    methods: {
        onItem(item) {
            const { certExamApplyId, testType } = this;
            if (`${testType}` === '2') {
                this.$router.push(`/exam/practice/${testType}/${certExamApplyId}?questionCatId=${item.id}`);
            } else if (`${testType}` === '3') {
                this.$router.push(`/exam/practice/${testType}/${certExamApplyId}?questionType=${item.questionType}`);
            }
        },
    },
};
</script>

<style lang="scss">
</style>
