<!-- Created by henian.xu on 2020/3/25. -->

<template>
    <PageBar
        v-if="data&&data.length"
        position="bottom"
        class="main-tab-bar"
    >
        <div
            v-for="(item,index) in data"
            :key="item.key"
            :class="['item',{active:model===index}]"
            @click="onItem(item,index)"
        >
            <XIcon
                :content="item.icon"
                :badge="(item.key==='my'&&userInfo.userMessageNum)?' ':''"
            />
            <div class="label">
                {{item.label}}
            </div>
        </div>
    </PageBar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MainTabBar',
    data() {
        return {};
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters('User', ['userInfo']),
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        onItem(item, index) {
            this.model = index;
        },
    },
};
</script>

<style lang="scss">
.main-tab-bar {
    background-color: #fff;
    border-top: 0 !important;
    > .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $gray6;
        > .x-icon {
            font-size: 40px;
        }
        > .label {
            //font-size: 0.6 * 100%;
            //margin-top: $margin-small;
        }
        &.active {
            color: $color-main;
        }
    }
}
</style>
