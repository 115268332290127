/**
 * Created by henian.xu on 2020/4/21.
 *
 */

import Vue from 'vue';
import { Api } from 'api/index';

export const state = Vue.observable({
    certExamApplyId: 0,
    listData: [],
    courseList: [],
    listDataMap: {},
});

export async function getCourseList(certExamApplyId) {
    const { data } = await Api.Member.Ce.CertExamCourse.certExamCourseList({ certExamApplyId });
    state.certExamApplyId = certExamApplyId;
    state.listData = data;
    state.courseList = [];
    const map = {};
    data.forEach((cat, index) => {
        if (!cat.courseDetailList || !cat.courseDetailList.length) return;
        state.courseList.push(...cat.courseDetailList);
        cat.courseDetailList.reduce((pre, cur, __index) => {
            pre[cur.id] = {
                ...cur,
                __index: `${index}.${__index}`,
            };
            return pre;
        }, map);
    });
    state.courseList.reduce((pre, cur, __index) => {
        pre[cur.id] = {
            ...cur,
            __index,
        };
        return pre;
    }, map);
    state.listDataMap = map;
}
