<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div class="course-page">
        <PageBar direction="column">
            <Tab v-model="tabIndex">
                <TabPanel
                    v-for="item in tabList"
                    :key="item.key"
                    :label="item.label"
                ></TabPanel>
            </Tab>
        </PageBar>
        <div
            ref="scrollbar"
            class="course-page-content"
        >
            <template v-for="(item,key) in tabShowed">
                <ApplyList
                    v-show="`${applyStatus}`===`${key}`"
                    :activated="activated && `${applyStatus}`===`${key}`"
                    :key="key"
                    :apply-status="key"
                ></ApplyList>
            </template>
        </div>
    </div>
</template>

<script>
import ApplyList from 'pages/index/_ApplyList';
import { scrollMemory } from 'packages/mixins';

export default {
    name: 'CoursePage',
    mixins: [scrollMemory],
    components: { ApplyList },
    data() {
        return {
            applyStatus_: this.$route.query.applyStatus,
            tabList: [
                {
                    applyStatus: '10,20,30,40,50,60,61,62,65',
                    label: '学习中',
                },
                {
                    applyStatus: '70,100',
                    label: '考核通过',
                },
                {
                    applyStatus: '98,99',
                    label: '考核不通过',
                },
                {
                    applyStatus: '97',
                    label: '复审过期',
                },
                {
                    applyStatus: '29',
                    label: '退款处理中',
                },
            ],
            tabShowed: {},
        };
    },
    props: {
        activated: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        applyStatus() {
            const { tabList, applyStatus_ } = this;
            return applyStatus_ || tabList[0].applyStatus;
        },
        tabIndex: {
            get() {
                const { applyStatus, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = item.applyStatus === applyStatus;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                this.applyStatus_ = tab.applyStatus;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        applyStatus: tab.applyStatus,
                    },
                });
                // this.refreshList();
            },
        },
    },
    watch: {
        applyStatus: {
            handler(val) {
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
    methods: {},
};
</script>

<style lang="scss">
.course-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    &-content {
        flex: 1;
        @include setScrollAxis('y');
    }
}
</style>
