<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <OrderGoodsItem
                :data="applyDetail"
            />

            <template v-if="continuingEducationApplyStatus===40">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c tc-danger">
                    <XLabel>不通过原因</XLabel>
                </div>
                <CellGroup border>
                    <Cell>
                        <template #label>
                            <div
                                class="tc-danger"
                                style="word-break: break-all"
                            >{{auditOpinion}}</div>
                        </template>
                    </Cell>
                </CellGroup>
            </template>

            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
                align="right"
                border
                show-feedback
                scroll-into-error
            >
                <!--<div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>工作信息</XLabel>
                </div>
                <FormItem
                    prop="companyName"
                    direction="column"
                    label="现就职单位全称"
                >
                    <XInput
                        placeholder="未就业可放空"
                        :disabled="!isEdit"
                        v-model="formModel.companyName"
                    />
                </FormItem>
                <FormItem
                    prop="workRegionId"
                    direction="column"
                    label="工作所在地区"
                >
                    <RegionPicker
                        v-model="formModel.workRegionId"
                        :disabled="!isEdit"
                    />
                </FormItem>-->
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>报名材料</XLabel>
                </div>
                <FormItem
                    v-if="subsidyBankCardList && subsidyBankCardList.length"
                    key="subsidyBankCardList"
                    prop="subsidyBankCard"
                    direction="column"
                    label="申领方式"
                >
                    <XSelect
                        tiling
                        :data="subsidyBankCardList"
                        v-model="formModel.subsidyBankCard"
                        :disabled="!isEdit"
                    />
                </FormItem>
                <!--                <template v-if="formModel.subsidyBankCard==='4'">
                    <FormItem
                        prop="bankAccount"
                        key="bankAccount"
                        label="卡号"
                    >
                        <XInput
                            v-model="formModel.bankAccount"
                            :disabled="!isEdit"
                        />
                    </FormItem>
                    <FormItem
                        prop="openingBank"
                        key="openingBank"
                        label="开户行"
                    >
                        <XInput
                            v-model="formModel.openingBank"
                            :disabled="!isEdit"
                        />
                    </FormItem>
                </template>-->
                <CellGroup
                    class="bor-t"
                    border
                >
                    <Cell
                        v-for="(item,index) in applyMaterialsList"
                        :key="index"
                        :label="`${index+1}.${item.applyMaterialsName}`"
                        :to="(isEdit||item._expired) && item.isEditMaterials?`/continuingEducation/editMaterials/${continuingEducationApplyId}/${item.applyMaterialsId}`:''"
                    >
                        <template #extra>
                            <div class="extra">
                                <template v-if="(item.applyMaterialsStatus && item.applyMaterialsStatus !== 10) || !item.isEditMaterials">
                                    <XButton
                                        v-if="item.isNeedSignature && isEdit && continuingEducationApplyStatus!==10 && continuingEducationApplyStatus!==40"
                                        theme="primary"
                                        radius="all"
                                        size="small"
                                        lock-text
                                        @click.prevent.stop="()=>{onPreview(item)}"
                                    >{{item.isSignature?'已':'未'}}签名</XButton>
                                    <XButton
                                        v-else-if="item.isNeedPMImage && isEdit"
                                        theme="primary"
                                        radius="all"
                                        size="small"
                                        lock-text
                                        @click.prevent.stop="()=>{onPreview(item)}"
                                    >{{item.isUploadPMImage?'已':'未'}}上传</XButton>
                                    <XButton
                                        v-else
                                        theme="success"
                                        radius="all"
                                        size="small"
                                        lock-text
                                        @click.prevent.stop="()=>{onPreview(item)}"
                                    >预览</XButton>
                                </template>
                                <span :class="[{'tc-danger':item._expired}]">{{item.applyMaterialsStatusName}}</span>
                            </div>
                        </template>
                    </Cell>
                </CellGroup>

                <template v-if="continuingEducationApplyStatus===10 || continuingEducationApplyStatus===40">
                    <div
                        key="formModel.signature.div"
                        class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                        <XLabel>材料确认签名</XLabel>
                    </div>
                    <FormItem
                        key="formModel.signature"
                        prop="signature"
                        direction="column"
                    >
                        <EditSignature
                            is-batch
                            v-model="formModel.signature"
                        ></EditSignature>
                    </FormItem>
                </template>

                <div
                    v-if="applyDetail.applyTemplateDesc"
                    class="pa-a tc-g6">
                    <XLabel>填报说明:</XLabel>
                    <XLabel :label="applyDetail.applyTemplateDesc"></XLabel>
                </div>
                <!--<div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>报名备注</XLabel>
                </div>
                <FormItem
                    prop="memberMsg"
                    direction="column"
                >
                    <XInput
                        type="textarea"
                        :min-rows="2"
                        :max-rows="6"
                        v-model="formModel.memberMsg"
                        placeholder="如您想什么时候安排考试？有没有什么特别说明."
                        :disabled="!isEdit"
                    />
                </FormItem>
                <FormItem
                    label="报名类型:"
                    prop="subsidyApplyType"
                >
                    <XSelect
                        :data="subsidyApplyType"
                        v-model="formModel.subsidyApplyType"
                        :disabled="!isEdit"
                    />
                </FormItem>-->
            </XForm>
        </Container>
        <PageBar
            position="bottom"
        >
            <XButton
                v-if="isEdit"
                class="item"
                label="提交审核"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
            <XButton
                v-else
                class="item"
                :label="applyDetail.continuingEducationApplyStatusName"
                theme="primary"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import { localStorage } from 'utils/Storage';
import { state } from '../index/applyListStore';

const hideDeferredReviewInstructionsKey = '__HIDE_DEFERRED_REVIEW_INSTRUCTIONS_KEY__';
export default {
    name: 'ApplyMaterialsPage',
    data() {
        return {
            continuingEducationApplyId: this.$route.params.id,
            formModel: {
                memberMsg: '',
                subsidyApplyType: '',
                companyName: '',
                workRegionId: '',
                signature: '',
                subsidyBankCard: '',
                bankAccount: '',
                openingBank: '',
            },
            formRules: {
                subsidyApplyType: {
                    required: true,
                    message: '不能为空',
                },
                workRegionId: {
                    validator: this.validWorkRegionId,
                    message: '不能为空',
                },
                signature: {
                    required: true,
                    message: '不能为空',
                },
                subsidyBankCard: {
                    required: true,
                    message: '不能为空',
                },
                bankAccount: {
                    required: true,
                    message: '不能为空',
                },
                openingBank: {
                    required: true,
                    message: '不能为空',
                },
            },
            detail: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        const { name } = from;
        console.log(name);
        next(vm => {
            // 是编辑材料返回时刷新数据
            if (
                name === 'ContinuingEducationApplyEditMaterials' ||
                name === 'ContinuingEducationApplyPreviewMaterials'
            ) {
                vm.getMaterialsDetail();
            }
        });
    },
    computed: {
        ...mapGetters('Dictionary', ['subsidyApplyType', 'subsidyApplyTypeMap']),
        ...mapGetters('PortalInfo', ['continuingEducationInstructions', 'depositCardOfICBC']),
        applyDetail() {
            return this.detail.applyDetail || {};
        },
        applyMaterialsList() {
            const {
                detail,
                formModel: { subsidyBankCard },
            } = this;
            return (detail.applyMaterialsList || []).reduce((pre, cur) => {
                if (
                    (subsidyBankCard === '1' &&
                        (cur.applyMaterialsCode === 'bankCardPowerOfAttorney' ||
                            cur.applyMaterialsCode === 'depositCardOfICBC')) ||
                    (subsidyBankCard === '2' &&
                        (cur.applyMaterialsCode === 'bankCard' || cur.applyMaterialsCode === 'depositCardOfICBC')) ||
                    (subsidyBankCard === '4' &&
                        (cur.applyMaterialsCode === 'bankCardPowerOfAttorney' || cur.applyMaterialsCode === 'bankCard'))
                ) {
                    return pre;
                }
                pre.push({
                    ...cur,
                    _expired: [97, 98, 99, 10].includes(cur.applyMaterialsStatus) || !cur.applyMaterialsStatus,
                });
                return pre;
            }, []);
        },
        auditOpinion() {
            // 10 40 可编辑
            return this.applyDetail.auditOpinion || '无';
        },
        continuingEducationApplyStatus() {
            // 10 40 可编辑
            return this.applyDetail.continuingEducationApplyStatus;
        },
        isEdit() {
            const { continuingEducationApplyStatus } = this;
            return continuingEducationApplyStatus === 10 || continuingEducationApplyStatus === 40;
        },
        subsidyBankCardList() {
            const {
                applyDetail: { servantSubsidyBankCard },
            } = this;
            return [
                { id: '1', name: '自有卡上传' },
                { id: '2', name: '代办开卡' },
                { id: '4', name: '工行存钱宝卡' },
            ].reduce((pre, cur) => {
                if (servantSubsidyBankCard & +cur.id) {
                    pre.push(cur);
                }
                return pre;
            }, []);
        },
        depositCardOfICBCShow() {
            const {
                isEdit,
                depositCardOfICBC,
                formModel: { subsidyBankCard },
            } = this;
            console.log(isEdit, depositCardOfICBC, subsidyBankCard);
            if (!isEdit || +subsidyBankCard !== 4) return '';
            return depositCardOfICBC || '';
        },
    },
    watch: {
        applyDetail(val) {
            if (!val || !val.id) return;
            const {
                formModel,
                applyDetail: { servantSubsidyBankCard },
            } = this;
            formModel.memberMsg = val.memberMsg;
            formModel.subsidyApplyType = val.subsidyApplyType;
            if (!formModel.bankAccount) formModel.bankAccount = val.bankAccount;
            if (!formModel.openingBank) formModel.openingBank = val.openingBank;
            if (!formModel.subsidyBankCard) {
                formModel.subsidyBankCard = `${val.subsidyBankCard || servantSubsidyBankCard & 4 || 2 || 1}`;
            }
            const { form } = this.$refs;
            form.clearValidate();
        },
        continuingEducationInstructions: {
            handler(val) {
                const isHide = localStorage.get(hideDeferredReviewInstructionsKey);
                if (!val || isHide) return;
                const xImage = this.$createElement('XImage', {
                    props: {
                        src: val,
                    },
                });
                this.$messageBox
                    .confirm(xImage, '延期复审须知', {
                        cancelBtnText: '下次不再提醒',
                    })
                    .catch(e => {
                        console.log(e);
                        localStorage.set(hideDeferredReviewInstructionsKey, true);
                    });
            },
            immediate: true,
        },
        depositCardOfICBCShow: {
            handler(val) {
                if (!val) return;
                const xImage = this.$createElement('div', [
                    this.$createElement(
                        'div',
                        {
                            class: 'tc-minor ma-b',
                            props: {},
                        },
                        ['长按保存看大图'],
                    ),
                    this.$createElement('XImage', {
                        props: {
                            src: val,
                        },
                    }),
                ]);
                this.$messageBox.alert(xImage, '工行存钱宝卡须知');
            },
            immediate: true,
        },
    },
    methods: {
        async validWorkRegionId(rule, value, callback) {
            const { companyName } = this.formModel;
            if (companyName && !value) {
                callback(new Error(rule.message));
                return;
            }
            callback();
        },
        async getMaterialsDetail() {
            const { data } = await this.$api.Member.Ce.ContinuingEducationApply.applyDetail(
                this.continuingEducationApplyId,
            );
            this.detail = data;
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const { formModel, continuingEducationApplyId } = this;
            const { msg } = await this.$api.Member.Ce.ContinuingEducationApply.submitAudit({
                continuingEducationApplyId,
                ...formModel,
            });
            state.needRefresh = true;
            this.$router.back();
            this.getMaterialsDetail();
            if (msg) this.$messageBox.tips(msg);
        },
        async onPreview(item) {
            const { isNeedSignature, isNeedPMImage, applyMaterialsId } = item;
            const { continuingEducationApplyStatus, continuingEducationApplyId } = this;
            const query = { continuingEducationApplyStatus };
            if (isNeedSignature) query.isNeedSignature = true;
            if (isNeedPMImage) query.isNeedPMImage = true;
            this.$router.push({
                path: `/continuingEducation/previewMaterials/${continuingEducationApplyId}/${applyMaterialsId}`,
                query,
            });
        },
    },
    created() {
        this.getMaterialsDetail();
    },
};
</script>

<style lang="scss">
</style>
