<!-- Created by henian.xu on 2020/5/26. -->

<template>
    <Page>
        <PageBar direction="column">
            <Tab v-model="tabIndex">
                <TabPanel
                    v-for="item in tabList"
                    :key="item.id"
                    :sign="item.id"
                    :label="item.name"
                />
            </Tab>
        </PageBar>
        <ScheduleList
            v-for="(item,key,index) in tabShowed"
            :key="`${key}:${index}`"
            v-show="tabKey === key"
            :status="key"
        />
    </Page>
</template>

<script>
import ScheduleList from './_ScheduleList';

export default {
    name: 'SchedulePage',
    components: { ScheduleList },
    data() {
        return {
            tabList: [
                {
                    id: '20-1',
                    name: '待参加考试',
                },
                {
                    id: '20',
                    name: '待公布成绩',
                },
                {
                    id: '100',
                    name: '成绩已公布',
                },
            ],
            tabKey_: this.$route.query.tabKey,
            tabShowed: {},
        };
    },
    computed: {
        tabKey() {
            const { tabList, tabKey_ } = this;
            return `${tabKey_ || ''}` || `${(tabList[0] || {}).id || ''}`;
        },
        tabIndex: {
            get() {
                const { tabKey, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = `${item.id}` === tabKey;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                this.tabKey_ = tab.id;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        tabKey: this.tabKey_,
                    },
                });
            },
        },
    },
    watch: {
        tabKey: {
            handler(val) {
                if (!this.$utils.isDef(val)) return;
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
</style>
