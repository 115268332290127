<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <OrderGoodsItem
                v-for="item in orderDetailList"
                :key="item.skuId"
                :data="item"
            />
            <template v-if="orderType===2">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>补考科目与费用</XLabel>
                </div>
                <CellGroup
                    v-for="orderDetail in orderDetailList"
                    :key="orderDetail.skuId">
                    <Cell
                        v-for="(item,index) in JSON.parse(orderDetail.makeUpPayConfig)"
                        :key="index"
                        :label="item.appraisalSubjectName"
                    >
                        <template #extra>
                            <div class="extra tc-base">
                                {{item.amount}}元
                            </div>
                        </template>
                    </Cell>
                </CellGroup>
            </template>
            <template v-else-if="orderType===1">
                <CellGroup class="ma-t">
                    <Cell
                        v-if="memberDiscountCode"
                        key="goodsType:1"
                        label="学习码:"
                        :extra="discountCode.codeSn||'请输入学习码如果有'"
                        link
                        @click="()=>{onPromoCode()}"
                    />
                    <div
                        v-if="discountCode.id"
                        class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c"
                    >
                        <XLabel :label="discountCode.discountName"></XLabel>
                        <div class="tc-price">优惠金额(元)：<span class="price">-{{discountCode.discountAmount}}</span></div>
                    </div>
                    <Cell
                        v-if="detail.shipMethod && !(goodsType===1||goodsType===4)"
                        key="shipMethod"
                        :label="`${goodsType===1?'证书':''}领取方式:`"
                        direction="column"
                    >
                        <template #extra>
                            <div class="extra ma-t tc-base">
                                <div class="ma-b">
                                    <Checkbox
                                        v-for="item in shipMethodList"
                                        :key="item.id"
                                        class="ma-r"
                                        type="radio"
                                        :disabled="!!orderIds"
                                        :value="`${item.id}`"
                                        v-model="formModel.shipMethod"
                                    >{{item.name}}</Checkbox>
                                </div>
                                <template v-if="`${formModel.shipMethod}`==='1'">
                                    <AddressPicker
                                        :value="memberDeliveryAddress"
                                        :disabled="!!orderIds"
                                        @input="onAddressPickerInput"/>
                                </template>
                            </div>
                        </template>
                    </Cell>

                    <template  v-if="goodsType===1||goodsType===16||goodsType===32">
                        <!--                    <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                        <XLabel>注:成绩公布后，7-15个工作日之内可发证</XLabel>
                    </div>-->

                        <Cell
                            label="排班备注:"
                            direction="column"
                        >
                            <template #extra>
                                <div class="extra tc-base">
                                    <XInput
                                        type="textarea"
                                        :min-rows="2"
                                        :max-rows="6"
                                        :disabled="!!orderIds"
                                        v-model="formModel.memberMsg"
                                        placeholder="如：您想什么时候编班考试？您期望编班是否错开工作日？"
                                    />
                                </div>
                            </template>
                        </Cell>
                    </template>
                </CellGroup>


                <DynamicForm
                    ref="dynamicForm"
                    :disabled="!!orderIds"
                    :data="extendFieldInfo"
                />

                <CellGroup class="ma-t">
                    <template  v-if="goodsType===8">
                        <Cell
                            label="备注:"
                            direction="column"
                        >
                            <template #extra>
                                <div class="extra tc-base">
                                    <XInput
                                        type="textarea"
                                        :min-rows="2"
                                        :max-rows="6"
                                        :disabled="!!orderIds"
                                        v-model="formModel.memberMsg"
                                    />
                                </div>
                            </template>
                        </Cell>
                    </template>
                </CellGroup>
            </template>
            <PayPopup
                ref="payPopup"
                :module="1"
                is-lazy
                :order-ids="orderIds"
            />
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                :label="`付款(${totalAmount}元)`"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins/index';
import { mapGetters } from 'vuex';
import { localStorage } from 'utils';

const hideInstructionsImageKey = '__HIDE_INSTRUCTIONS_IMAGE_KEY__';

export default {
    name: 'NewPage',
    mixins: [pageMixin],
    data() {
        return {
            uuid: this.$route.params.uuid,
            detail: {},
            formModel: {
                shipMethod: '',
                usePoint: '',
                deliveryConsignee: '',
                deliveryPhone: '',
                deliveryRegionId: '',
                deliveryAddress: '',
                memberMsg: '',
                // extendFieldInfo: '', // 迁移至动态表单
                uuid: this.$route.params.uuid,
            },
            discountCode: {},
            orderIds: '',
        };
    },
    computed: {
        ...mapGetters('PortalInfo', ['instructionsImage']),
        shipMethodList() {
            const { shipMethodDic, detail } = this;
            return shipMethodDic.reduce((pre, cur) => {
                if (detail.shipMethod & cur.id) {
                    pre.push(cur);
                }
                return pre;
            }, []);
        },
        orderDetailList() {
            return this.detail.orderDetailList || [];
        },
        orderType() {
            return +this.detail.orderType || 1;
        },
        applyNoticeUrl() {
            if (this.orderType !== 1) return '';
            return this.detail.applyNoticeUrl || '';
        },
        goodsAmount() {
            return this.orderDetailList.reduce((pre, cur) => pre + cur.finalPrice, 0);
        },
        totalAmount() {
            const {
                goodsAmount,
                discountCode: { discountAmount = 0 },
            } = this;
            return goodsAmount - discountAmount;
        },
        memberDeliveryAddress() {
            return this.detail.memberDeliveryAddress || {};
        },
        memberDiscountCode() {
            return this.detail.memberDiscountCode || null;
        },
        goodsType() {
            return this.detail.goodsType;
        },
        extendFieldInfo() {
            if (this.orderType !== 1) return null;
            return this.detail.extendFieldInfo;
        },
    },
    watch: {
        memberDeliveryAddress: {
            handler(val) {
                if (!val || !val.consignee) return;
                const { formModel } = this;
                formModel.deliveryConsignee = val.consignee;
                formModel.deliveryPhone = val.phone;
                formModel.deliveryRegionId = val.regionId;
                formModel.deliveryAddress = val.address;
            },
            immediate: true,
        },
        memberDiscountCode: {
            handler(val) {
                if (!val) return;
                this.discountCode = val;
            },
            immediate: true,
        },
        instructionsImage: {
            handler(val) {
                const isHide = localStorage.get(hideInstructionsImageKey);
                if (!val || isHide || this.goodsType !== 1) return;
                const xImage = this.$createElement('XImage', {
                    props: {
                        src: val,
                    },
                });
                this.$messageBox
                    .confirm(xImage, '报名流程须知', {
                        cancelBtnText: '下次不再提醒',
                    })
                    .catch(e => {
                        console.log(e);
                        localStorage.set(hideInstructionsImageKey, true);
                    });
            },
            immediate: true,
        },
        shipMethodList: {
            handler(val) {
                if (!val || !val.length) return;
                if (this.goodsType === 1 || this.goodsType === 4) {
                    this.formModel.shipMethod = `2`;
                } else {
                    this.formModel.shipMethod = `${val[0].id || ''}`;
                }
            },
            immediate: true,
        },
        applyNoticeUrl: {
            handler(val) {
                if (!val) return;
                const imgEl = this.$createElement('img', {
                    attrs: {
                        src: val,
                        width: '100%',
                    },
                });
                this.$messageBox.alert(imgEl, '温馨提示', {
                    delayConfirm: 3,
                    isClickOtherClose: false,
                    confirmBtnText: '关闭',
                });
            },
            immediate: true,
        },
    },
    methods: {
        async onPromoCode() {
            if (this.orderIds) {
                this.$messageBox.tips('订单已生成,无法填写学习码,请重新下单。');
                return;
            }
            const discountCodeSn = await this.$messageBox.prompt('', '请输入学习码或企业学习码', {
                placeholder: '请输入学习码',
                validCallback: async (action, value) => {
                    if (action !== 'confirm') return true;
                    if (!value) return Promise.reject(new Error('不能为空！'));
                    return true;
                },
            });
            const { uuid } = this;
            const { data } = await this.$api.Member.Mb.DiscountCode.checkDiscountCode({
                uuid,
                discountCodeSn,
            });
            this.discountCode = data;
            console.log(discountCodeSn, data);
        },
        onAddressPickerInput(data) {
            this.detail.memberDeliveryAddress = data;
        },
        async onSubmit() {
            if (this.orderIds) {
                this.$refs.payPopup.open();
                return;
            }
            const { formModel, discountCode, extendFieldInfo } = this;
            const params = {
                ...formModel,
                discountCodeId: discountCode.id || '',
            };
            if (extendFieldInfo) {
                const dynamicFormModel = await this.$refs.dynamicForm.submit();
                console.log(dynamicFormModel);
                params.extendFieldInfo = JSON.stringify(dynamicFormModel);
            }
            const { data, msg } = await this.$api.Member.Ex.Order.createOrder(params);
            const { order, certExamApplyId, deferredReviewApplyId, subsidyApplyId } = data;
            if (order.orderStatus === 10) {
                this.orderIds = order.id;
                if (msg) this.$messageBox.tips(msg);
                this.$refs.payPopup.open();
            } else if (order.goodsType === 1) {
                this.$router.replace(`/course/applyMaterials/${certExamApplyId}`);
                // this.$navigation.reLaunch(`/index/apply`);
            } else if (order.goodsType === 2) {
                this.$router.replace(`/subsidyApply/applyMaterials/${subsidyApplyId}`);
                // this.$navigation.reLaunch(`/index/apply`);
            } else if (order.goodsType === 8) {
                this.$router.replace(`/deferredReview/applyMaterials/${deferredReviewApplyId}`);
                // this.$navigation.reLaunch(`/index/apply`);
            }
        },
    },
    async created() {
        const { data } = await this.$api.Member.Ex.Order.buyNowConfirm({
            uuid: this.uuid,
        });
        this.detail = data;
    },
};
</script>

<style lang="scss">
</style>
