<!-- Created by henian.xu on 2021/2/26. -->

<template>
    <Page>
        <Container>
            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
                align="right"
                border
                show-feedback
            >
                <FormItem
                    key="billCompanyName"
                    prop="billCompanyName"
                    label="公司名称:">
                    <XInput v-model="formModel.billCompanyName"/>
                </FormItem>
                <FormItem
                    prop="billCompanyCode"
                    label="组织架构代码:">
                    <XInput v-model="formModel.billCompanyCode"/>
                </FormItem>
                <FormItem
                    prop="billCompanyAddress"
                    label="公司地址:"
                    direction="column"
                >
                    <XInput
                        placeholder="请填写您营业执照上的注册地址"
                        type="textarea"
                        v-model="formModel.billCompanyAddress"
                        :min-row="1"
                        :max-row="3"
                    />
                </FormItem>
                <FormItem
                    prop="billCompanyTel"
                    label=" 公司电话:"
                    direction="column"
                >
                    <XInput
                        placeholder="请填写您公司有效的联系电话"
                        type="textarea"
                        v-model="formModel.billCompanyTel"
                        :min-row="1"
                        :max-row="3"
                    />
                </FormItem>
                <FormItem
                    prop="billBankName"
                    label="开户银行:"
                    direction="column"
                >
                    <XInput
                        placeholder="请填写您开户许可证上的开户银行"
                        type="textarea"
                        v-model="formModel.billBankName"
                        :min-row="1"
                        :max-row="3"
                    />
                </FormItem>
                <FormItem
                    prop="billBankAccount"
                    label="账户号码:"
                    direction="column"
                >
                    <XInput
                        placeholder="请填写您开户许可证上的开户账号"
                        type="textarea"
                        v-model="formModel.billBankAccount"
                        :min-row="1"
                        :max-row="3"
                    />
                </FormItem>
                <div class="pa-a tc-danger">请与贵公司财务确认开票信息真实有效!</div>
            </XForm>
            <!--            {{enterpriseDetail}}-->
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="保存"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { enterpriseMixin, getEnterpriseDetail } from 'pages/Enterprise/enterpriseStore';
import { pageMixin } from 'mixins';

export default {
    name: 'InvoiceInfoPage',
    mixins: [pageMixin, enterpriseMixin],
    data() {
        return {
            formModel: {
                billCompanyName: '', // 公司名称
                billCompanyCode: '', // 组织架构代码
                billCompanyAddress: '', // 公司地址
                billCompanyTel: '', // 公司电话
                billBankAccount: '', // 账户号码
                billBankName: '', // 开户银行
            },
            formRules: {
                billCompanyName: {
                    required: true,
                    message: '不能为空',
                },
                billCompanyCode: {
                    required: true,
                    message: '不能为空',
                },
                billCompanyAddress: {
                    required: true,
                    message: '不能为空',
                },
                billCompanyTel: {
                    required: true,
                    message: '不能为空',
                },
                billBankAccount: {
                    required: true,
                    message: '不能为空',
                },
                billBankName: {
                    required: true,
                    message: '不能为空',
                },
            },
        };
    },
    watch: {
        enterpriseDetail: {
            handler(val) {
                if (!val || !val.id) return;
                const { formModel } = this;
                Object.keys(formModel).forEach(key => {
                    formModel[key] = val[key] || '';
                });
            },
            immediate: true,
        },
    },
    methods: {
        async onSubmit() {
            await this.$refs.form.validate();
            const params = {
                ...this.formModel,
            };
            const { msg } = await this.$api.Member.Mb.Enterprise.saveEnterpriseBillInfo(params);
            await getEnterpriseDetail();
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
</style>
