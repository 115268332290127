<template>
    <Page>
        <Container fill>
            <swiper
                ref="mainTabPanel"
                class="main-tab-panel"
                :options="swiperOption"
                @slideChange="onSlideChange"
            >
                <swiper-slide
                    v-for="(item,index) in tabList"
                    :key="item.key"
                >
                    <template v-if="tabShowed[index]">
                        <component
                            :is="item.component"
                            :activated="isActivated && `${tabKey}`===`${item.key}`"
                        />
                    </template>
                </swiper-slide>
            </swiper>
        </Container>
        <MainTabBar
            :data="tabList"
            v-model="currentTabIndex"
        />
    </Page>
</template>

<script>
import MainTabBar from 'pages/_MainTabBar';
import HomePage from 'pages/index/_Home';
import ServePage from 'pages/index/_Serve';
import ApplyPage from 'pages/index/_Apply';
import MyHomePage from 'pages/index/_MyHome';

export default {
    name: 'IndexPage',
    components: { HomePage, ServePage, ApplyPage, MyHomePage, MainTabBar },
    data() {
        return {
            isMounted: false,
            tabList: [
                {
                    key: 'home',
                    icon: 'f045',
                    label: '首页',
                    component: 'HomePage',
                },
                {
                    key: 'serve',
                    icon: 'f088',
                    label: '报名',
                    component: 'ServePage',
                },
                {
                    key: 'apply',
                    icon: 'f089',
                    label: '学习',
                    component: 'ApplyPage',
                },
                {
                    key: 'my',
                    icon: 'f003',
                    label: '我的',
                    component: 'MyHomePage',
                },
            ],
            tabShowed: {},
            tabKey_: this.$route.params.tabKey,
            isActivated: false,
        };
    },
    computed: {
        $$swiper() {
            const { isMounted, $refs } = this;
            if (!isMounted) return null;
            console.log($refs.mainTabPanel);
            return ($refs.mainTabPanel || {}).$swiper || null;
        },
        swiperOption() {
            const { currentTabIndex } = this;
            return {
                // autoHeight: true,
                touchStartPreventDefault: false,
                touchStartForcePreventDefault: false,
                touchMoveStopPropagation: false,
                noSwiping: true,
                noSwipingClass: 'tab',
                initialSlide: currentTabIndex,
            };
        },
        tabListMap() {
            return this.tabList.reduce((pre, cur, __index) => {
                // eslint-disable-next-line no-param-reassign
                pre[cur.key] = {
                    ...cur,
                    __index,
                };
                return pre;
            }, {});
        },
        tabKey() {
            const { tabList, tabKey_ } = this;
            return tabKey_ || tabList[0].key;
        },
        currentTabIndex: {
            get() {
                const { tabKey, tabListMap } = this;
                const tab = tabListMap[tabKey];
                if (!tab) return 0;
                return tab.__index;
            },
            set(val) {
                const { tabList, $$swiper } = this;
                const index = tabList[val] ? val : 0;
                const tab = tabList[val] || tabList[0];
                this.tabKey_ = tab.key;
                this.$router.replace({
                    ...this.$route,
                    params: {
                        tabKey: tab.key,
                    },
                });
                if ($$swiper) {
                    $$swiper.slideTo(index);
                }
            },
        },
    },
    watch: {
        currentTabIndex: {
            handler(val) {
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
    methods: {
        onSlideChange() {
            this.currentTabIndex = this.$$swiper.activeIndex;
        },
    },
    mounted() {
        this.isMounted = true;
    },
    activated() {
        this.isActivated = true;
    },
    deactivated() {
        this.isActivated = false;
    },
};
</script>

<style lang="scss">
.main-tab-panel {
    height: 100%;
    > .swiper-wrapper {
        > .swiper-slide {
            //overflow-y: auto;
        }
    }
}
</style>
