<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        ref="scrollbar"
        class="my-home-page"
    >
        <UserBox></UserBox>

        <!-- 订单 -->
        <CellGroup
            class="ma-t"
            border
        >
            <Cell
                label="我的订单"
                icon="f090"
                icon-theme="main"
                icon-size="big"
                link
                to="/order/list"
            >
                <template #extra>
                    <div class="extra fs-little tc-g5">查看全部订单</div>
                </template>
            </Cell>
        </CellGroup>
        <!--<GridGroup
            class="bor-t"
            :column="4"
        >
            <Grid
                icon="f050"
                icon-theme="main"
                icon-size="0.6rem"
            >
                <template #label>
                    <div class="label fs-little">
                        待付款
                    </div>
                </template>
            </Grid>
            <Grid
                icon="f051"
                icon-theme="main"
                icon-size="0.6rem"
            >
                <template #label>
                    <div class="label fs-little">
                        待发货
                    </div>
                </template>
            </Grid>
            <Grid
                icon="f052"
                icon-theme="main"
                icon-size="0.6rem"
            >
                <template #label>
                    <div class="label fs-little">
                        待收货
                    </div>
                </template>
            </Grid>
            <Grid
                icon="f056"
                icon-theme="main"
                icon-size="0.6rem"
            >
                <template #label>
                    <div class="label fs-little">
                        退款/售后
                    </div>
                </template>
            </Grid>
        </GridGroup>-->

        <!-- 服务 -->
        <CellGroup
            class="ma-t"
            border
        >
            <Cell
                label="服务"
                icon-theme="main"
                icon-size="big"
                icon="f099"
            />
        </CellGroup>
        <GridGroup
            class="bor-t"
            :column="3"
        >
            <Grid
                icon-theme="main"
                icon="f092"
                to="/credentials/list"
            >
                <!--                @click="onBuild"-->
                <template #label>
                    <div class="label fs-little">
                        我的证件
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f06d"
                to="/schedule"
            >
                <template #label>
                    <div class="label fs-little">
                        我的考试
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f097"
                to="/refund/list"
            >
                <template #label>
                    <div class="label fs-little">
                        我的服务
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f08b"
                to="/admitCard"
            >
                <template #label>
                    <div class="label fs-little">
                        准考证下载
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f092"
                @click="onCertificateReceive"
            >
                <template #label>
                    <div class="label fs-little">
                        我的领证
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f093"
                to="/invoice/list"
            >
                <!--@click="onBuild"-->
                <template #label>
                    <div class="label fs-little">
                        我的发票
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f00a"
                to="/deliveryAddress/list"
            >
                <template #label>
                    <div class="label fs-little">
                        收件地址
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f096"
                @click="onEnterprise"
            >
                <template #label>
                    <div class="label fs-little">
                        企业合作
                    </div>
                </template>
            </Grid>
            <!--            <Grid
                icon-theme="main"
                icon="f0a0"
                @click="onSubsidyApply"
            >
                <template #label>
                    <div class="label fs-little">
                        补贴申请
                    </div>
                </template>
            </Grid>-->
            <Grid
                icon-theme="main"
                icon="f092"
                @click="onSupplementApply"
            >
                <template #label>
                    <div class="label fs-little">
                        补证申请
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f0a1"
                @click="onDeferredReview"
            >
                <template #label>
                    <div class="label fs-little">
                        延期复审
                    </div>
                </template>
            </Grid>

            <Grid
                icon-theme="main"
                icon="f0a3"
                @click="onOnlyTraining"
            >
                <template #label>
                    <div class="label fs-little">
                        线下培训
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f0a5"
                to="/trainingLive/list"
            >
                <template #label>
                    <div class="label fs-little">
                        远程教学
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f0a4"
                @click="onContinuingEducation"
            >
                <template #label>
                    <div class="label fs-little">
                        继续教育
                    </div>
                </template>
            </Grid>

            <Grid
                icon-theme="main"
                icon="f094"
                to="/system/notice"
                :badge="userInfo.userMessageNum?' ':''"
            >
                <template #label>
                    <div class="label fs-little">
                        通知
                    </div>
                </template>
            </Grid>
            <Grid
                v-if="meiQiaAllSet"
                key="meiqia"
                icon-theme="main"
                icon="f024"
                :badge="unreadMsgCount?unreadMsgCount:''"
                @click="onService"
            >
                <template #label>
                    <div class="label fs-little">
                        在线客服
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f095"
                to="/system/feedback"
            >
                <template #label>
                    <div class="label fs-little">
                        意见反馈
                    </div>
                </template>
            </Grid>
            <Grid
                icon-theme="main"
                icon="f08b"
                to="/survey"
            >
                <template #label>
                    <div class="label fs-little">
                        满意度调查
                    </div>
                </template>
            </Grid>
        </GridGroup>

        <div class="pa-a ma-tb ta-c tc-g5 fs-small">@福建闽都教育科技有限公司</div>
    </div>
</template>

<script>
import UserBox from 'pages/index/_UserBox';
import { scrollMemory } from 'packages/mixins';
import { meiQiaMixin, pageMixin } from 'mixins/index';
import MeiQiaControl from '@/store/meiQia/MeiQiaControl';

export default {
    name: 'MyHomePage',
    mixins: [pageMixin, scrollMemory, meiQiaMixin],
    components: { UserBox },
    data() {
        return {};
    },
    methods: {
        onBuild() {
            this.$messageBox.alert('功能开发中，敬请期待。');
        },
        onEnterprise() {
            if (!this.userInfo.isRealNameAuth) {
                this.$router.push('/user/idCardAuth?backUrl=false');
            } else {
                this.$router.push('/enterprise');
            }
        },
        onSubsidyApply() {
            const { subsidyApply, isRealNameAuth } = this.userInfo;
            if (!isRealNameAuth) {
                this.$router.push('/user/idCardAuth?backUrl=false');
            } else if (subsidyApply) {
                this.$router.push(`/subsidyApply/applyMaterials/${subsidyApply.id}`);
            } else {
                this.$router.push('/subsidyApply/list');
            }
        },
        onSupplementApply() {
            const { isRealNameAuth } = this.userInfo;
            if (!isRealNameAuth) {
                this.$router.push('/user/idCardAuth?backUrl=false');
            } else {
                this.$router.push('/supplementApply/list');
            }
        },
        onDeferredReview() {
            if (!this.userInfo.isRealNameAuth) {
                this.$router.push('/user/idCardAuth?backUrl=false');
            } else {
                this.$router.push('/deferredReview/list');
            }
        },
        onOnlyTraining() {
            if (!this.userInfo.isRealNameAuth) {
                this.$router.push('/user/idCardAuth?backUrl=false');
            } else {
                this.$router.push('/onlyTraining/list');
            }
        },
        onContinuingEducation() {
            if (!this.userInfo.isRealNameAuth) {
                this.$router.push('/user/idCardAuth?backUrl=false');
            } else {
                this.$router.push('/continuingEducation/list');
            }
        },
        onCertificateReceive() {
            if (!this.userInfo.isRealNameAuth) {
                this.$router.push('/user/idCardAuth?backUrl=false');
            } else {
                this.$router.push('/certificateReceive/list');
            }
        },
        onService() {
            /* const { userInfo } = this;
            if (!userInfo || !userInfo.id) {
                this.$router.replace('/login');
                return;
            } */
            MeiQiaControl.showPanel();
        },
    },
    created() {
        // 只为了触发401
        this.$api.Member.Mb.DeliveryAddress.list({});
    },
};
</script>

<style lang="scss">
.my-home-page {
    height: 100%;
    @include setScrollAxis('y');
}
</style>
