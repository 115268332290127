<!-- Created by henian.xu on 2020/9/25. -->

<template>
    <div class="import-student">
        <div
            :class="['out-show',{noop:!outShow}]"
            @click="onSwitch"
        >
            <div class="label">{{ outShow || placeholder }}</div>
            <XIcon
                v-if="!disabled_"
                content="f012"
                theme="g5"
                size="small" />
        </div>
        <!-- popup -->
        <transition
            v-if="!lazy"
            :name="aniName"
        >
            <Page
                v-show="popupShow"
                class="import-student-popup"
                ref="popup"
            >
                <PageNav label="导入报名学员"></PageNav>
                <div class="download-template-box">
                    <div class="inner">
                        <XIcon
                            class="ma-rs"
                            content="f041"
                            theme="primary"
                        ></XIcon>
                        <span class="label">每次导入，将替换之前的名单，</span>
                        <a
                            href="https://relcdn.oss-cn-hangzhou.aliyuncs.com/project/mdap/template/%E5%AF%BC%E5%85%A5%E5%AD%A6%E5%91%98%E6%A8%A1%E6%9D%BF.xlsx"
                            download="导入学员模板.xlsx"
                            class="tc-primary"
                        >下载导入模板</a>
                    </div>
                </div>
                <Container>
                    <List
                        v-if="cooperationMemberList && cooperationMemberList.length"
                        padding
                        margin
                    >
                        <StudentItem
                            v-for="item in cooperationMemberList"
                            :key="item.id"
                            :data="item"
                        ></StudentItem>
                    </List>
                    <Upload
                        v-show="!cooperationMemberList || !cooperationMemberList.length"
                        class="fx-jc-c"
                        placeholder="导入学员名单"
                        :columns="2.5"
                        ref="upload"
                        accept=".xls,.xlsx,.csv"
                        action="/member/mb/enterprise/uploadExcel"
                        :max-files="1"
                        @success="(val)=>{onUploadSuccess(val)}"
                        @error="(val)=>{onUploadError(val)}"
                    ></Upload>
                </Container>
                <PageBar position="bottom">
                    <XButton
                        v-if="cooperationMemberList && cooperationMemberList.length"
                        class="item"
                        theme="danger"
                        label="清空"
                        @click="()=>{clearUpload()}"
                    ></XButton>
                    <!--<XButton
                        class="item"
                        theme="primary"
                        label="导入学员名单"
                        @click="()=>{selectFile()}"
                    ></XButton>-->
                    <XButton
                        class="item"
                        label="确认完成"
                        theme="main"
                        @click="()=>{onComplete()}"
                    ></XButton>
                </PageBar>
            </Page>
        </transition>
    </div>
</template>

<script>
import { popupMixin } from 'packages/mixins';

export default {
    name: 'ImportStudent',
    mixins: [popupMixin],
    data() {
        return {
            detail: {},
            value_: {},
        };
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    computed: {
        model: {
            get() {
                const { value, value_ } = this;
                if (value === null) return value_;
                return value;
            },
            set(val) {
                const { value } = this;
                if (value === null) this.value_ = val;
                this.$emit('input', val);
            },
        },
        outShow() {
            const { model } = this;
            const { cooperationMemberList } = model || {};
            if (!cooperationMemberList || !cooperationMemberList.length) return '';
            return `${cooperationMemberList.length} 人`;
        },
        cooperationMemberList() {
            return this.detail.cooperationMemberList || [];
        },
        detailList() {
            return this.detail.detailList || [];
        },
        totalAmount() {
            return this.detail.totalAmount || 0;
        },
    },
    methods: {
        onUploadSuccess(detail) {
            if (!detail) return;
            this.detail = detail;
        },
        onUploadError(err) {
            this.$messageBox.alert(err.message || '上传失败!');
        },
        clearUpload() {
            this.$refs.upload.clear();
            this.detail = {};
        },
        /* async selectFile() {
            try {
                this.detail = await this.$refs.upload.selectFile();
            } catch (e) {
                this.$messageBox.alert(e.message || '上传失败!');
            }
        }, */
        async onComplete() {
            this.model = this.detail;
            await this.close();
        },
    },
};
</script>

<style lang="scss">
.import-student {
    flex: 1 1 1%;
    > .out-show {
        line-height: $formItemHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .label {
            flex: 1;
            text-align: right;
        }
        > .x-icon {
            flex: 0 0 auto;
        }

        &.noop {
            color: $gray6;
        }
    }
}
.import-student-popup {
    .download-template-box {
        padding: $padding-small $padding;
        > .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: $little * 100%;
            background-color: mix(#fff, $color-primary, 80%);
            padding: $padding-small $padding;
            border: 1px solid $color-primary;
            border-radius: $padding-small;
        }
    }
}
</style>
