<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <PageBar direction="column">
            <Tab v-model="tabIndex">
                <TabPanel
                    v-for="item in tabList"
                    :key="item.key"
                    :label="item.label"
                />
            </Tab>
        </PageBar>
        <Container>
            <!--{{tabIndex}}
            {{tabShowed}}-->
            <div class="ma-t"></div>
            <List
                padding
                margin
            >
                <RefundItem
                    v-for="(item,index) in listData"
                    :key="item.id"
                    :data="item"
                    :to="`/refund/detail?applyId=${item.id}`"
                    @change="(e)=>{onRefundItemChange(e,item,index)}"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
        <!--<PageBar position="bottom">
            <XButton
                class="item"
                label="添加证件"
                theme="success"
                to="/credentials/add"
            ></XButton>
        </PageBar>-->
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins/index';
import RefundItem from 'pages/Refund/_RefundItem';
import { mapGetters } from 'vuex';

export default {
    name: 'InvoiceListPage',
    components: { RefundItem },
    mixins: [paginationMixin],
    data() {
        return {
            courseTabKey_: this.$route.query.courseTabKey,
            tabShowed: {},
        };
    },
    computed: {
        ...mapGetters('Dictionary', ['refundApplyStatusList', 'refundApplyStatusMap']),
        tabList() {
            const { refundApplyStatusList, refundApplyStatusMap } = this;
            if (!refundApplyStatusList || !refundApplyStatusList.length) return [];
            return [10, 100, 99, 98].reduce(
                (pre, cur) => {
                    const item = refundApplyStatusMap[cur];
                    if (item) {
                        pre.push({
                            ...item,
                            key: `${item.id || 0}`,
                            label: item.name,
                        });
                    }
                    return pre;
                },
                [
                    {
                        key: '',
                        label: '全部',
                    },
                ],
            );
        },
        courseTabKey() {
            const { tabList, courseTabKey_ } = this;
            return courseTabKey_ || (tabList[0] || {}).key || '';
        },
        tabIndex: {
            get() {
                const { courseTabKey, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = item.key === courseTabKey;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                this.courseTabKey_ = tab.key;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        courseTabKey: tab.key,
                    },
                });
                this.refreshList();
            },
        },
    },
    watch: {
        tabIndex: {
            handler(val) {
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Ex.RefundApply.list({
                refundApplyStatus: this.courseTabKey,
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        onRefundItemChange(data, item, index) {
            const { courseTabKey } = this;
            console.log(courseTabKey);
            if (!data || courseTabKey) {
                this.listData.splice(index, 1);
            } else if (!courseTabKey) {
                this.listData.splice(index, 1, data);
            }
            if (!this.listData.length) this.refreshList();

            console.log(data, item, index);
        },
    },
};
</script>

<style lang="scss">
</style>
