/**
 * Created by henian.xu on 2021/10/26.
 *
 */

import Vue from 'vue';
import { integrateAsync, isObject, isString } from 'utils';
import { Api } from 'api';

export const state = Vue.observable({
    materialsMap: [],
    materialsDetailMap: [],
});

export const getMaterialsValueList = (() => {
    const fn = integrateAsync(async function func({ valueType, valueId }, { groupKey }) {
        const res = await Api.Member.Sv.MaterialsValue.materialsValueList({
            valueType,
            valueId,
        });
        Vue.set(state.materialsMap, groupKey, res.data);
        return res;
    });
    return function func({ valueType, valueId }) {
        return fn.call(this, { valueType, valueId }, { groupKey: `${valueType}_${valueId}` });
    };
})();
export const getMaterialsDetail = (() => {
    const fn = integrateAsync(async function func({ valueType, valueId, materialsId }, { groupKey }) {
        const res = await Api.Member.Sv.MaterialsValue.materialsDetail({
            valueType,
            valueId,
            materialsId,
        });
        Vue.set(state.materialsDetailMap, groupKey, res.data);
        return res;
    });
    return function func({ valueType, valueId, materialsId }) {
        return fn.call(
            this,
            { valueType, valueId, materialsId },
            { groupKey: `${valueType}_${valueId}_${materialsId}` },
        );
    };
})();

export const MaterialsListMixin = {
    computed: {
        // 子类必须实现 materialsValueType
        /* materialsValueType() {
            return this.$route.query.valueType || '';
        }, */
        // 子类必须实现 materialsValueId
        /* materialsValueId() {
            return this.$route.query.valueId || '';
        }, */
        materialsListKey() {
            const { materialsValueType, materialsValueId } = this;
            if (!materialsValueType || !materialsValueId) return '';
            return `${materialsValueType}_${materialsValueId}`;
        },
        materialsData() {
            const { materialsListKey, materialsValueType, materialsValueId } = this;
            const { materialsMap } = state;
            if (!materialsListKey) return {};
            const data = materialsMap[materialsListKey];
            if (!data) {
                getMaterialsValueList({ valueType: materialsValueType, valueId: materialsValueId });
            }
            return data || {};
        },
        materialsList() {
            const { materialsData } = this;
            return (materialsData.materialsList || []).map(item => {
                return {
                    ...item,
                    // isEditMaterials: false, // 只是移动端不用编辑材料固写死 false
                    _expired: [97, 98, 99, 10].includes(item.materialsStatus) || !item.materialsStatus,
                };
            });
        },
        valueStatus() {
            const { materialsData } = this;
            return materialsData.valueStatus;
        },
        isEdit() {
            const { materialsData } = this;
            return materialsData.isEdit;
        },
    },
    beforeDestroy() {
        const { materialsListKey } = this;
        const { materialsMap } = state;
        delete materialsMap[materialsListKey];
    },
};

export const MaterialsDetailMixin = {
    computed: {
        materialsValueType() {
            return this.$route.query.valueType || '';
        },
        materialsValueId() {
            return this.$route.query.valueId || '';
        },
        materialsId() {
            return this.$route.params.materialsId || '';
        },
        materialsDetailKey() {
            const { materialsValueType, materialsValueId, materialsId } = this;
            if (!materialsValueType || !materialsValueId) return '';
            return `${materialsValueType}_${materialsValueId}_${materialsId}`;
        },
        materialsDetail() {
            const { materialsDetailKey, materialsValueType, materialsValueId, materialsId } = this;
            const { materialsDetailMap } = state;
            if (!materialsDetailKey) return {};
            const data = materialsDetailMap[materialsDetailKey];
            if (!data) {
                getMaterialsDetail({
                    valueType: materialsValueType,
                    valueId: materialsValueId,
                    materialsId,
                });
            }
            return data || {};
        },

        materialsNum() {
            return this.materialsDetail.materialsNum || 0;
        },
        materialsStatus() {
            // 30 100 不可编辑
            return this.materialsDetail.materialsStatus;
        },
        submissionMode() {
            return this.materialsDetail.submissionMode || 0;
        },
        valueStatus() {
            const { materialsDetail } = this;
            return materialsDetail.valueStatus;
        },

        formData() {
            return this.materialsDetail.formData || {};
        },
        formDataJson() {
            const { formData } = this;
            if (!formData) return null;
            const { formDataJson } = formData;
            if (!formDataJson) return null;
            try {
                if (isString(formDataJson)) return JSON.parse(formDataJson);
                if (isObject(formDataJson)) return formDataJson;
                return null;
            } catch (e) {
                return null;
            }
        },
        initDataJson() {
            const { formData } = this;
            if (!formData) return null;
            const { initDataJson } = formData;
            if (!initDataJson) return null;
            try {
                if (isString(initDataJson)) return JSON.parse(initDataJson);
                if (isObject(initDataJson)) return initDataJson;
                return null;
            } catch (e) {
                return null;
            }
        },

        isEdit() {
            const { materialsDetail } = this;
            return materialsDetail.isEdit;
        },
        signatureList() {
            const { formDataJson, isEdit } = this;
            if (!formDataJson || !isEdit) return false;
            const { fields } = formDataJson;
            if (!fields) return false;
            return fields.filter(field => field.type === 'signature');
        },
    },
    beforeDestroy() {
        const { materialsDetailKey } = this;
        const { materialsDetailMap } = state;
        delete materialsDetailMap[materialsDetailKey];
    },
};
