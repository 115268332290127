<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container>
            <List
                :padding="!isPicker"
                :margin="!isPicker"
                :border="isPicker"
            >
                <DeliveryAddressItem
                    v-for="(item,index) in listData"
                    :key="item.id"
                    :data="item"
                    :is-select="isPicker"
                    @delete="()=>{onDelItem(item,index)}"
                    @edit="()=>{onEdit(item,index)}"
                    @click="()=>{onEdit(item,index)}"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="添加地址"
                theme="success"
                to="/deliveryAddress/edit"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { hasOwn } from 'utils';
import { paginationMixin } from 'mixins/index';

import { state, getListData, deleteAddress, handlerChoose } from './store';

export default {
    name: 'ListPage',
    mixins: [paginationMixin],
    data() {
        return {
            isPicker: hasOwn(this.$route.query, 'isPicker'),
        };
    },
    computed: {
        listData() {
            return state.listData;
        },
        pagination: {
            get() {
                return state.pagination;
            },
            set(val) {
                state.pagination = val;
            },
        },
    },
    methods: {
        async getListData(query) {
            return getListData(query);
        },
        async onDelItem(item, index) {
            await this.$messageBox.confirm('是否确定要删除此项');
            console.log(item, index);
            const { msg } = await deleteAddress(item.id);
            if (msg) this.$messageBox.tips(msg);
        },
        async onEdit(item) {
            if (!this.isPicker) {
                this.$router.push(`/deliveryAddress/edit/${item.id}`);
                return;
            }
            console.log(item.consignee);
            handlerChoose(item);
            this.$router.back();
        },
    },
    beforeDestroy() {
        if (this.isPicker) handlerChoose();
    },
};
</script>

<style lang="scss">
</style>
