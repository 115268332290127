<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container v-if="order.id">
            <OrderItem
                :data="order"
                only-show
                style="box-shadow:none;border:none"
            ></OrderItem>
            <div class="ma-t"></div>
            <CellGroup border>
                <Cell label="试算可退款">
                    <template #extra>
                        <div class="price tc-success">{{detail.estimateRefundAmount}}</div>
                    </template>
                </Cell>
                <Cell
                    label="退款事由"
                    direction="column"
                >
                    <template #extra>
                        <XInput
                            type="textarea"
                            v-model="refundReason"
                            :min-row="1"
                            :max-row="3"
                        ></XInput>
                        <div
                            v-if="refundReasonError"
                            class="tc-danger">{{refundReasonError}}</div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                试算明细
            </div>
            <CellGroup border>
                <Cell label="订单可扣款金额">
                    <template #extra>
                        <div class="price">{{(detail.totalAmount - detail.refundedAmount)||0 | price}}</div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                可扣款金额默认为订单实付金额，若发送过退款，则可退款金额=订单实付金额-已退款金额。
            </div>
            <CellGroup border>
                <Cell label="税点扣款">
                    <template #extra>
                        <div class="tc-price">-<span class="price">{{detail.taxAmount}}</span></div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                若订单已开票，申请退款则需要扣除税点
            </div>
            <CellGroup border>
                <Cell label="证考服务扣款">
                    <template #extra>
                        <div class="tc-price">-<span class="price">{{detail.certExamServiceAmount}}</span></div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                包括报名材料审核、排班等服务
            </div>
            <CellGroup border>
                <Cell label="课时学习扣款">
                    <template #extra>
                        <div class="tc-price">-<span class="price">{{detail.courseServiceAmount}}</span></div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                已点播学习课程{{courseServiceList.length}}节
            </div>
            <CellGroup border>
                <Cell label="练习扣款">
                    <template #extra>
                        <div class="tc-price">-<span class="price">{{detail.practiceServiceAmount}}</span></div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <template v-for="item in practiceServiceList">
                    {{item.practiceTypeName}} {{item.useTimeMin}} 分钟，
                </template>合计试题练习 {{detail.practiceTotalUseTime}} 分钟。
            </div>
        </Container>
        <PageBar
            v-if="order.id"
            position="bottom">
            <XButton
                class="item"
                label="提交退款申请"
                theme="success"
                :disabled="!detail.estimateRefundAmount"
                @click="()=>{onApply()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
export default {
    name: 'DetailPage',
    data() {
        return {
            orderId: this.$route.query.orderId,
            detail: {},
            refundReason: '',
            refundReasonError: '',
        };
    },
    computed: {
        order() {
            return this.detail.order || {};
        },
        courseServiceList() {
            return this.detail.courseServiceList || [];
        },
        practiceServiceList() {
            return this.detail.practiceServiceList || [];
        },
        orderDetailList() {
            return this.order.orderDetailList || [];
        },
        orderType() {
            return +this.order.orderType || 1;
        },
        orderDetail() {
            return this.orderDetailList[0] || {};
        },
        specDesc() {
            return (this.orderDetail.specDesc || '').split(':').pop();
        },
        isSendEmail() {
            const { detail } = this;
            if (!detail) return false;
            const { invoiceType } = detail;
            return `${invoiceType}` === '1' || `${invoiceType}` === '3';
        },
        isEnterprise() {
            const { detail } = this;
            if (!detail) return false;
            const { invoiceTitleType } = detail;
            return `${invoiceTitleType}` === '1';
        },
    },
    methods: {
        async getDetail() {
            const { orderId } = this;
            const { data } = await this.$api.Member.Ex.RefundApply.refundApply({
                orderId,
            });
            this.detail = data;
        },
        async onApply() {
            const { orderId, refundReason } = this;
            if (!refundReason) {
                this.refundReasonError = '请输入退款事由';
                return;
            }
            await this.$messageBox.confirm('申请退款后，将无法继续进行证考学习，确认申请退款？');
            const { msg, data } = await this.$api.Member.Ex.RefundApply.saveRefundApply({
                orderId,
                refundReason,
            });
            this.$router.replace(`/refund/detail?applyId=${data}`);
            if (msg) this.$messageBox.tips(msg);
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
</style>
