<!-- Created by henian.xu on 2020/9/25. -->

<template>
    <div class="enterprise-box">
        <!--<XImage
            width="100"
            :aspect-ratio="1"
            radius="all"
        />-->
        <XIcon
            content="f096"
            size="90"
            theme="main"
        ></XIcon>
        <div class="name">{{data.enterpriseName}}</div>
        <div class="sn">{{data.enterpriseSn}}</div>
    </div>
</template>

<script>
export default {
    name: 'EnterpriseBox',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.enterprise-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: $padding-big * 2 $padding;
    background-color: $color-component-bgc;

    > .name {
        margin-top: $margin-big;
        font-weight: bold;
        font-size: $big * 100%;
    }
    > .sn {
        margin-top: $margin-small;
    }
}
</style>
