<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <PageBar direction="column">
            <Tab v-model="tabIndex">
                <TabPanel
                    v-for="item in tabList"
                    :key="item.key"
                    :label="item.label"
                />
            </Tab>
        </PageBar>
        <Container>
            <!--{{tabIndex}}
            {{tabShowed}}-->
            <List
                padding
                margin
            >
                <InvoiceItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    :to="`/invoice/detail/${item.orderId}`"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
        <!--<PageBar position="bottom">
            <XButton
                class="item"
                label="添加证件"
                theme="success"
                to="/credentials/add"
            ></XButton>
        </PageBar>-->
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins/index';

export default {
    name: 'InvoiceListPage',
    mixins: [paginationMixin],
    data() {
        return {
            courseTabKey_: this.$route.query.courseTabKey,
            tabList: [
                {
                    key: '',
                    label: '全部',
                },
                {
                    key: '10',
                    label: '待出票',
                },
                {
                    key: '100',
                    label: '已出票',
                },
            ],
            tabShowed: {},
        };
    },
    computed: {
        courseTabKey() {
            const { tabList, courseTabKey_ } = this;
            return courseTabKey_ || tabList[0].key;
        },
        tabIndex: {
            get() {
                const { courseTabKey, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = item.key === courseTabKey;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                this.courseTabKey_ = tab.key;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        courseTabKey: tab.key,
                    },
                });
                this.refreshList();
            },
        },
    },
    watch: {
        tabIndex: {
            handler(val) {
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Ex.OrderInvoice.invoiceList({
                invoiceStatus: this.courseTabKey,
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
    },
};
</script>

<style lang="scss">
</style>
