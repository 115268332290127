<!-- Created by henian.xu on 2020/4/9. -->

<template>
    <Page>
        <Container
            fill
            class="login-container"
        >
            <div class="banner">
                <XImage
                    class="logo"
                    width="150"
                    :src="$globalVar.servantLogoUrl||$logoImg"
                    radius="all"
                />
                <div class="label">{{ $globalVar.servantName||'闽都教育' }}</div>
                <!--<div class="desc">
                    停车管理
                </div>-->
            </div>
            <div class="pa-ab">
                <div class="bc-w">
                    <!--<Tab
                        button
                        average
                        v-model="tabIndex"
                    >
                        <TabPanel label="手机号密码登录" />
                        <TabPanel label="手机号验证码登录" />
                    </Tab>-->
                    <div class="pa-l pa-r">
                        <XForm
                            ref="form"
                            border
                            :model="formModel"
                            :rules="formRules"
                        >
                            <FormItem
                                label="电　话:"
                                prop="account"
                                ref="account"
                            >
                                <XInput v-model="formModel.account" />
                                <template v-if="tabIndex">
                                    <XButton
                                        v-if="!countDown"
                                        class="bor-l"
                                        size="small"
                                        lock-text
                                        label="获取验证码"
                                        @click="onValidateCode"
                                    />
                                    <XButton
                                        v-else
                                        class="bor-l"
                                        size="small"
                                        lock-text
                                        theme="g4"
                                        plain
                                        :label="`重新获取(${countDown}秒)`"
                                    />
                                </template>
                            </FormItem>
                            <FormItem
                                v-if="tabIndex"
                                label="验证码:"
                                key="验证码"
                                prop="password"
                            >
                                <XInput
                                    v-model="formModel.verificationCode"
                                    @keydown.enter="()=>{onSubmit()}"
                                />
                            </FormItem>
                            <FormItem
                                v-else
                                label="密　码:"
                                key="密码"
                                prop="password"
                            >
                                <XInput
                                    type="password"
                                    v-model="formModel.password"
                                    @keydown.enter="()=>{onSubmit()}"
                                />
                            </FormItem>
                        </XForm>
                    </div>
                </div>

                <XButton
                    class="ma-tb"
                    theme="main"
                    radius="all"
                    size="big"
                    fill
                    lock-text
                    @click="()=>{onSubmit()}"
                >
                    登录
                </XButton>

                <div class="footer-ctrl">
                    <Linker
                        replace
                        :to="`/register?backUrl=${rawBackUrl}`"
                    >
                        立即注册
                    </Linker>
                    <Linker
                        replace
                        :to="`/forgotPassword?backUrl=${rawBackUrl}`"
                    >
                        忘记密码?
                    </Linker>
                </div>
            </div>
            <div class="copyright">
                @福建闽都教育科技有限公司
            </div>
        </Container>
    </Page>
</template>

<script>
import { pageMixin, backUrlMixin } from 'mixins/index';

export default {
    name: 'LoginPage',
    mixins: [pageMixin, backUrlMixin],
    data() {
        return {
            countDown: 0,
            countDownTimer: 0,
            tabIndex: 0,
            formModel: {
                account: this.$globalVar.isDev ? '18060082532' : '',
                password: this.$globalVar.isDev ? '123456' : '',
                verificationCode: '',
            },
            formRules: {
                account: [
                    {
                        required: true,
                        message: '手机号不能为空',
                    },
                    {
                        validator: this.$utils.Valid.mobile,
                        message: '手机号格式不正确',
                    },
                    {
                        validator: this.checkAccount,
                        // 远程验证 message 远程返回
                    },
                ],
                password: {
                    required: true,
                    message: '密码不能为空',
                },
                verificationCode: {
                    required: true,
                    message: '验证码不能为空',
                },
            },
        };
    },
    methods: {
        async checkAccount(rule, value, callback) {
            const res = await this.$api.Comm.Rs.User.checkAccount({
                countryCodeId: this.formModel.countryCodeId,
                account: this.formModel.account,
            });
            if (res.success) {
                callback(new Error('账号未注册'));
            } else {
                callback();
            }
        },
        startCountDown(val) {
            this.countDown = val;
            clearInterval(this.countDownTimer);
            this.countDownTimer = setInterval(() => {
                this.countDown -= 1;
                if (this.countDown <= 0) {
                    this.onAuthCodeChange();
                    clearInterval(this.countDownTimer);
                    this.countDownTimer = 0;
                }
            }, 1000);
        },
        onValidateCode() {
            this.$refs.form.clearValidate();
            Promise.all([this.$refs.account.validate()]).then(() => {
                this.$api.Comm.Rs.Sms.sendVerificationCode(this.formModel).then(() => {
                    this.startCountDown(60);
                });
            });
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const { msg } = await this.$api.Comm.Rs.User.login(this.formModel);
            this.$messageBox.tips(msg);
            // window.location.href = window.location.href.split('#')[0];
            this.$globalVar.routerEdReplace = true;
            await this.$router.replace({
                path: `${this.backUrl || '/'}`,
            });
        },
    },
};
</script>

<style lang="scss">
.login-container {
    > .scrollbar {
        > .inner {
            > .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.banner {
    flex: 0 0 auto;
    text-align: center;
    padding: $padding-big;
    > .label {
        text-align: center;
        font-size: $super * 100%;
        margin-top: $margin-big;
    }
    > .desc {
        margin-top: $margin-small;
    }
}

.footer-ctrl {
    padding: $padding $padding-big;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $color-primary;
    //font-size: $small * 100%;
}

.copyright {
    position: absolute;
    left: 0;
    right: 0;
    bottom: $padding-big;
    text-align: center;
    font-size: $small * 100%;
    opacity: 0.3;
}
</style>
