<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <EnterpriseBox :data="enterpriseDetail"/>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>相关材料</XLabel>
            </div>
            <CellGroup border>
                <Cell
                    v-for="(item,index) in applyMaterialsList"
                    :key="index"
                    :label="`${index+1}.${item.applyMaterialsName}`"
                    :to="(isEdit||item._expired) && item.isEditMaterials?`/enterprise/editMaterials/${item.applyMaterialsId}`:''"
                >
                    <template #extra>
                        <div class="extra">
                            <template v-if="(item.applyMaterialsStatus && item.applyMaterialsStatus !== 10) || !item.isEditMaterials">
                                <XButton
                                    v-if="item.isNeedSignature && isEdit"
                                    theme="primary"
                                    radius="all"
                                    size="small"
                                    lock-text
                                    @click.prevent.stop="()=>{onPreview(item)}"
                                >{{item.isSignature?'已':'未'}}签名</XButton>
                                <XButton
                                    v-else-if="item.isNeedPMImage && isEdit"
                                    theme="primary"
                                    radius="all"
                                    size="small"
                                    lock-text
                                    @click.prevent.stop="()=>{onPreview(item)}"
                                >{{item.isUploadPMImage?'已':'未'}}上传</XButton>
                                <XButton
                                    v-else
                                    theme="success"
                                    radius="all"
                                    size="small"
                                    lock-text
                                    @click.prevent.stop="()=>{onPreview(item)}"
                                >预览</XButton>
                            </template>
                            <span :class="[{'tc-danger':item._expired}]">{{item.applyMaterialsStatusName}}</span>
                        </div>
                    </template>
                </Cell>
            </CellGroup>

            <template v-if="enterpriseStatus===99">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>不通过原因</XLabel>
                </div>
                <CellGroup border>
                    <Cell>
                        <template #label>
                            <div
                                class="tc-danger"
                                style="word-break: break-all"
                            >{{auditOpinion}}</div>
                        </template>
                    </Cell>
                </CellGroup>
            </template>
        </Container>
        <PageBar
            position="bottom"
        >
            <XButton
                v-if="isEdit"
                class="item"
                label="提交审核"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
            <XButton
                v-else
                class="item"
                :label="enterpriseDetail.enterpriseStatusName"
                theme="primary"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import EnterpriseBox from 'pages/Enterprise/_EnterpriseBox';
import { enterpriseMixin, getEnterpriseDetail } from 'pages/Enterprise/enterpriseStore';
import { pageMixin } from 'mixins/index';

export default {
    name: 'ApplyMaterialsPage',
    components: { EnterpriseBox },
    mixins: [pageMixin, enterpriseMixin],
    data() {
        return {};
    },
    beforeRouteEnter(to, from, next) {
        const { name } = from;
        console.log(name);
        next(() => {
            // 是编辑材料返回时刷新数据
            if (name === 'EnterpriseEditMaterials' || name === 'EnterprisePreviewMaterials') {
                getEnterpriseDetail();
            }
        });
    },
    computed: {
        ...mapGetters('Dictionary', ['certExamApplyType', 'certExamApplyTypeMap']),
        applyMaterialsList() {
            return (this.enterprise.applyMaterialsList || []).map(item => {
                return {
                    ...item,
                    _expired: [97, 98, 99, 10].includes(item.applyMaterialsStatus) || !item.applyMaterialsStatus,
                };
            });
        },
        auditOpinion() {
            // 10 99 可编辑
            return this.enterpriseDetail.auditOpinion || '无';
        },
        enterpriseStatus() {
            // 10 99 可编辑
            return this.enterpriseDetail.enterpriseStatus;
        },
        isEdit() {
            const { enterpriseStatus } = this;
            return enterpriseStatus === 10 || enterpriseStatus === 99;
        },
    },
    methods: {
        async onSubmit() {
            const { msg } = await this.$api.Member.Mb.Enterprise.submitAudit();
            await getEnterpriseDetail();
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
        async onPreview(item) {
            const { isNeedSignature, isNeedPMImage, applyMaterialsId } = item;
            const query = {};
            if (isNeedSignature) query.isNeedSignature = true;
            if (isNeedPMImage) query.isNeedPMImage = true;
            this.$router.push({
                path: `/enterprise/previewMaterials/${applyMaterialsId}`,
                query,
            });
        },
    },
    created() {},
};
</script>

<style lang="scss">
</style>
