<!-- Created by henian.xu on 2020/4/7. -->

<template>
    <Page>
        <Container>
            <div class="status">
                <XIcon
                    :content="success?'f019':'f037'"
                    :theme="success?'success':'danger'"
                    size="60"
                ></XIcon>
                <div class="label">{{ success?'支付成功':'支付中...' }}</div>
            </div>
            <CellGroup border>
                <Cell
                    label="付款单号"
                    :extra="paymentSn"
                />
                <Cell label="支付金额">
                    <template #extra>
                        <div class="extra">
                            <span class="price">{{paymentAmount}}</span>
                        </div>
                    </template>
                </Cell>
            </CellGroup>

            <div class="pa-a ma-t">
                <!--<XButton
                    theme="success"
                    fill
                    size="more"
                    lock-text
                    radius="all"
                    replace
                    :to="nextUrl"
                >完成</XButton>-->
                <template v-if="orderType===1">
                    <XButton
                        v-if="goodsType===1"
                        theme="success"
                        fill
                        size="more"
                        lock-text
                        radius="all"
                        replace
                        :to="`/course/applyMaterials/${certExamApplyId}`"
                    >提交报名材料</XButton>
                    <XButton
                        v-else-if="goodsType===2"
                        theme="success"
                        fill
                        size="more"
                        lock-text
                        radius="all"
                        replace
                        :to="`/subsidyApply/applyMaterials/${subsidyApplyId}`"
                    >提交补贴申请材料</XButton>
                    <XButton
                        v-else-if="goodsType===4"
                        theme="success"
                        fill
                        size="more"
                        lock-text
                        radius="all"
                        replace
                        :to="`/supplementApply/applyMaterials/${supplementApplyId}`"
                    >提交补证申请材料</XButton>
                    <XButton
                        v-else-if="goodsType===8"
                        theme="success"
                        fill
                        size="more"
                        lock-text
                        radius="all"
                        replace
                        :to="`/deferredReview/applyMaterials/${deferredReviewApplyId}`"
                    >提交延期复审材料</XButton>
                    <XButton
                        v-else-if="goodsType===16"
                        theme="success"
                        fill
                        size="more"
                        lock-text
                        radius="all"
                        replace
                        :to="`/onlyTraining/applyMaterials/${onlyTrainingApplyId}`"
                    >提交线下培训材料</XButton>
                    <XButton
                        v-else-if="goodsType===32"
                        theme="success"
                        fill
                        size="more"
                        lock-text
                        radius="all"
                        replace
                        :to="`/continuingEducation/applyMaterials/${continuingEducationApplyId}`"
                    >提交继续教育材料</XButton>
                </template>
                <XButton
                    class="ma-t"
                    theme="primary"
                    fill
                    size="more"
                    lock-text
                    radius="all"
                    replace
                    to="/order/list"
                >查看订单</XButton>
            </div>
        </Container>
    </Page>
</template>

<script>
import { state } from 'pages/index/applyListStore';
import { pageMixin } from 'mixins/index';

export default {
    name: 'ResultPage',
    mixins: [pageMixin],
    data() {
        return {
            module: this.$route.params.module,
            paymentSn: this.$route.params.paymentSn,
            payment: {},
        };
    },
    computed: {
        success() {
            return !!this.payment.nextUrl;
        },
        nextUrl() {
            return this.payment.nextUrl;
        },
        paymentAmount() {
            return this.payment.paymentAmount;
        },
        certExamApplyId() {
            return this.payment.certExamApplyId;
        },
        subsidyApplyId() {
            return this.payment.subsidyApplyId;
        },
        supplementApplyId() {
            return this.payment.supplementApplyId;
        },
        deferredReviewApplyId() {
            return this.payment.deferredReviewApplyId;
        },
        onlyTrainingApplyId() {
            return this.payment.onlyTrainingApplyId;
        },
        continuingEducationApplyId() {
            return this.payment.continuingEducationApplyId;
        },
        goodsType() {
            return this.payment.goodsType;
        },
        orderType() {
            return this.payment.orderType;
        },
    },
    watch: {
        goodsType: {
            handler(val) {
                if (val !== 2) return;
                this.userInfoUpdated();
            },
            immediate: true,
        },
    },
    async created() {
        const { module, paymentSn } = this;
        try {
            const { data } = await this.$api.Member.Ex.Pay.checkPayment({ module, paymentSn });
            if (data.nextUrl) state.needRefresh = true;
            this.payment = data;
        } catch (e) {
            this.$router.replace('/order/list');
        }
    },
};
</script>

<style lang="scss" scoped>
.status {
    text-align: center;
    background-color: $color-component-bgc;
    padding: $padding-big * 3 $padding-big;
    margin-bottom: $margin;
    > .label {
        margin-top: $margin-big;
    }
}
</style>
