<!-- Created by henian.xu on 2020/9/25. -->

<template>
    <Page>
        <Container>
            <StudentItem :data="detail"/>
            <template v-if="verticalGroupingList && verticalGroupingList.length">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>编班考试信息</XLabel>
                </div>
                <List margin>
                    <ScheduleItem
                        v-for="item in verticalGroupingList"
                        :key="item.id"
                        :data="item"
                        :to="`/enterprise/student/schedule/detail/${item.id}?certExamApplyId=${certExamApplyId}`"
                    ></ScheduleItem>
                </List>
            </template>
        </Container>
    </Page>
</template>

<script>
import ScheduleItem from './_ScheduleItem';

export default {
    name: 'DetailPage',
    components: { ScheduleItem },
    data() {
        return {
            memberDetailId: this.$route.params.id,
            memberDetail: {},
        };
    },
    computed: {
        detail() {
            return this.memberDetail.detail || {};
        },
        verticalGroupingList() {
            return this.memberDetail.verticalGroupingList || [];
        },
        certExamApplyId() {
            return this.detail.certExamApplyId;
        },
    },
    async created() {
        const { data } = await this.$api.Member.Mb.Enterprise.cooperationMemberDetail({
            id: this.memberDetailId,
        });
        this.memberDetail = data;
    },
};
</script>

<style lang="scss">
</style>
