<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <PageBar
            v-if="cmsCatalogList&&cmsCatalogList.length"
            direction="column">
            <CatalogPicker
                :data="cmsCatalogList"
                v-model="cmsCatId"/>
            <!--<Tab v-model="tabIndex">
                <TabPanel
                    v-for="item in tabList"
                    :key="item.key"
                    :label="item.label"
                />
            </Tab>-->
        </PageBar>
        <Container>
            <template v-for="(item,key,index) in tabShowed">
                <CmsList
                    v-show="`${cmsCatId}`===`${key}`"
                    :key="key||`index:${index}`"
                    :cat-id="key"
                ></CmsList>
            </template>
        </Container>
    </Page>
</template>

<script>
import { state, getCmsCatalogList } from 'pages/Cms/Store';
import CmsList from 'pages/Cms/_CmsList';

export default {
    name: 'ListPage',
    components: { CmsList },
    data() {
        return {
            // paginationDisabled: true,
            tabShowed: {},
            cmsCatId_: this.$route.params.id,
        };
    },
    computed: {
        cmsCatalogList() {
            const { cmsCatalogList } = state;
            if (cmsCatalogList === null) getCmsCatalogList();
            return cmsCatalogList || [];
        },
        cmsCatId: {
            get() {
                const { cmsCatId_ } = this;
                return cmsCatId_ || '';
            },
            set(val) {
                this.cmsCatId_ = val;
                this.$router.replace({
                    ...this.$route,
                    params: {
                        ...this.$route.params,
                        id: val,
                    },
                });
            },
        },
    },
    watch: {
        cmsCatId: {
            handler(val) {
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
</style>
