<!-- Created by henian.xu on 2020/3/18. -->

<template>
    <Page>
        <Container>
            <CellGroup border>
                <Cell
                    label="学员账号"
                    :extra="userInfo.userName"
                ></Cell>
                <Cell
                    label="绑定手机号"
                    :extra="userInfo.mobile"
                ></Cell>
            </CellGroup>
            <CellGroup
                v-if="userInfo.isRealNameAuth"
                border
                class="ma-t"
            >
                <Cell
                    label="证件类型"
                    :extra="userInfo.idCardTypeName"
                ></Cell>
                <Cell
                    label="真实姓名"
                    :extra="userInfo.fullName"
                ></Cell>
                <Cell
                    :label="idCardLabel"
                    :extra="userInfo.idCard"
                ></Cell>
            </CellGroup>
            <div
                v-else
                class="pa-a ma-t"
            >
                <XButton
                    label="身份实名"
                    theme="success"
                    fill
                    size="big"
                    lock-text
                    radius
                    to="/user/idCardAuth?backUrl=false"
                ></XButton>
            </div>
            <div class="pa-a ma-t">
                <XButton
                    label="修改密码"
                    theme="success"
                    fill
                    size="big"
                    lock-text
                    radius
                    to="/changePassword"
                ></XButton>
                <XButton
                    class="ma-t"
                    label="退出登录"
                    theme="danger"
                    fill
                    size="big"
                    lock-text
                    radius
                    @click="()=>{onLogout()}"
                ></XButton>
            </div>
        </Container>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'UserInfoPage',
    data() {
        return {};
    },
    computed: {
        ...mapGetters('User', ['userInfo']),
        idCardLabel() {
            const { userInfo } = this;
            const map = {
                1: '身份证号',
                2: '护 照 号',
                4: '证 件 号',
                8: '证 件 号',
            };
            return map[userInfo.idCardType] || '证 件 号';
        },
    },
    methods: {
        async onLogout() {
            await this.$messageBox.confirm('是否要退出登录！');
            await this.$api.Comm.Rs.User.logout();
            this.$router.replace('/login');
        },
    },
};
</script>

<style lang="scss">
</style>
