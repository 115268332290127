<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container>
            <div class="ma-t"></div>
            <List
                padding
                margin
            >
                <TrainingLiveItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    :to="`/trainingLive/detail/${item.id}`"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins/index';

export default {
    name: 'TrainingLiveListPage',
    mixins: [paginationMixin],
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Ce.TrainingLive.list({
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
    },
};
</script>

<style lang="scss">
</style>
