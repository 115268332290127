<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container v-if="detail">
            <CellGroup border>
                <Cell
                    label="关联订单"
                    :extra="detail.orderSn"
                />
                <Cell
                    label="开票金额(元)"
                >
                    <template #extra>
                        <div class="price">{{detail.invoiceAmount}}</div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>申请时间: {{detail.invoiceTime | moment('YYYY-MM-DD HH:mm:ss')}}</XLabel>
                <div class="extra fs-little">{{detail.invoiceStatusName}}</div>
            </div>
            <CellGroup border>
                <Cell
                    label="发票类型"
                    :extra="detail.invoiceTypeName"
                />
                <Cell
                    label="抬头类型"
                    :extra="detail.invoiceTitleTypeName"
                />
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>发票抬头</XLabel>
            </div>
            <CellGroup border>
                <Cell :label="detail.invoiceTitle"/>
            </CellGroup>
            <template v-if="isEnterprise">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>纳税人识别号</XLabel>
                </div>
                <CellGroup border>
                    <Cell :label="detail.taxIdentificationSn"/>
                </CellGroup>
            </template>
            <template v-if="isSendEmail">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>收票邮箱地址</XLabel>
                </div>
                <CellGroup border>
                    <Cell :label="detail.receiveEmail"/>
                </CellGroup>
            </template>
            <template v-else>
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>收件地址</XLabel>
                </div>
                <DeliveryAddressItem
                    :data="deliveryAddress"
                    is-select
                ></DeliveryAddressItem>
            </template>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>开户银行名称</XLabel>
            </div>
            <CellGroup border>
                <Cell :label="detail.bankName || '无'"/>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>基本开户账号</XLabel>
            </div>
            <CellGroup border>
                <Cell :label="detail.bankAccount || '无'"/>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>注册场所地址</XLabel>
            </div>
            <CellGroup border>
                <Cell :label="detail.registerAddress || '无'"/>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>注册固定电话</XLabel>
            </div>
            <CellGroup border>
                <Cell :label="detail.registerTel || '无'"/>
            </CellGroup>
        </Container>
    </Page>
</template>

<script>
import { getInvoiceDetail, state } from './InvoiceStore';

export default {
    name: 'DetailPage',
    data() {
        return {
            orderId: this.$route.params.id,
        };
    },
    computed: {
        detail() {
            const { orderId } = this;
            const { invoiceDetail } = state;
            if (!invoiceDetail || `${invoiceDetail.orderId}` !== `${orderId}`) {
                getInvoiceDetail(orderId);
                return null;
            }
            return invoiceDetail;
        },
        deliveryAddress() {
            const { detail } = this;
            if (!detail) return {};
            console.log(detail);
            return {
                consignee: detail.deliveryConsignee,
                phone: detail.deliveryPhone,
                regionId: detail.deliveryRegionId,
                address: detail.deliveryAddress,
                regionNamePath: detail.deliveryRegionNamePath,
            };
        },
        isSendEmail() {
            const { detail } = this;
            if (!detail) return false;
            const { invoiceType } = detail;
            return `${invoiceType}` === '1' || `${invoiceType}` === '3';
        },
        isEnterprise() {
            const { detail } = this;
            if (!detail) return false;
            const { invoiceTitleType } = detail;
            return `${invoiceTitleType}` === '1';
        },
    },
};
</script>

<style lang="scss">
</style>
