<!-- Created by henian.xu on 2020/3/27. -->

<template>
    <Page>
        <Container>
            <div
                v-if="detail.orderSn"
                class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c"
            >
                订单号:{{ detail.orderSn }}
            </div>
            <OrderGoodsItem
                class="ma-b"
                :data="orderDetail"
            />

            <template v-if="orderType===2">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>补考科目与费用</XLabel>
                </div>
                <CellGroup
                    v-for="orderDetail in orderDetailList"
                    :key="orderDetail.skuId"
                    border
                >
                    <Cell
                        v-for="(item,index) in JSON.parse(orderDetail.makeUpPayConfig)"
                        :key="index"
                        :label="item.appraisalSubjectName"
                    >
                        <template #extra>
                            <div class="extra tc-base">
                                {{item.amount}}元
                            </div>
                        </template>
                    </Cell>
                </CellGroup>
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    付款时间：{{detail.paymentTime || detail.orderTime | moment('YYYY-MM-DD HH:mm:ss')}}
                </div>
            </template>
            <template v-else-if="orderType===1">
                <template v-if="discountCode.id">
                    <CellGroup>
                        <Cell
                            label="学习码"
                            :extra="discountCode.codeSn"
                        />
                    </CellGroup>
                    <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                        <XLabel><template #default>{{discountCode.discountName}}</template></XLabel>
                        <div class="tc-price">优惠金额(元)：<span class="price">-{{discountCode.discountAmount}}</span></div>
                    </div>
                </template>
                <CellGroup>
                    <Cell label="订单实付(元)">
                        <template #extra>
                            <div class="extra"><span class="price">{{detail.payableAmount}}</span></div>
                        </template>
                    </Cell>
                </CellGroup>
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    付款时间：{{detail.paymentTime || detail.orderTime | moment('YYYY-MM-DD HH:mm:ss')}}
                </div>
                <CellGroup>
                    <Cell
                        label="证书领取方式"
                        :extra="detail.shipMethodName"
                    />
                    <DeliveryAddressItem
                        v-if="detail.shipMethod === 1"
                        :data="addressData"
                        is-select
                    />
                </CellGroup>
                <!--            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>注:成绩公布后，7-15个工作日之内可发证</XLabel>
            </div>-->
                <CellGroup class="ma-t">
                    <Cell
                        label="排班备注:"
                        direction="column"
                        :extra="detail.memberMsg || '无'"
                    />
                </CellGroup>
                <CellGroup
                    v-if="detail.orderStatus!==99"
                    class="ma-t"
                >
                    <Cell
                        icon="f093"
                        icon-theme="main"
                        icon-size="big"
                        label="我的发票:"
                        :extra="detail.invoiceStatusName"
                        @click.stop.prevent="onAddInvoice"
                    />
                <!--@click="onBuild"-->
                </CellGroup>
            </template>
        </Container>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins/index';

export default {
    name: 'DetailPage',
    mixins: [pageMixin],
    data() {
        return {
            orderId: this.$route.params.id,
            detail: {},
        };
    },
    computed: {
        orderDetailList() {
            return this.detail.orderDetailList || [];
        },
        orderType() {
            return +this.detail.orderType || 1;
        },
        discountCode() {
            return this.detail.discountCode || {};
        },
        orderDetail() {
            return this.orderDetailList[0] || {};
        },
        addressData() {
            const { detail } = this;
            if (!detail.id) return {};
            return {
                consignee: detail.deliveryConsignee,
                phone: detail.deliveryPhone,
                regionNamePath: detail.deliveryRegionName,
                address: detail.deliveryAddress,
            };
        },
    },
    methods: {
        onBuild() {
            this.$messageBox.alert('功能开发中，敬请期待。');
        },
        onAddInvoice() {
            const { detail, userInfo } = this;
            if (detail.isCanInvoice) {
                if (!userInfo.isAllowOrderInvoice) {
                    this.$messageBox.alert(userInfo.orderInvoiceTips);
                    return;
                }
                // this.$router.push(`/invoice/add/${detail.id}`);
                this.onCreateOrderInvoice();
            } else if (detail.invoiceStatus) {
                this.$router.push(`/invoice/detail/${detail.id}`);
            }
        },
        async onCreateOrderInvoice() {
            const { data } = await this.$api.Member.Ex.OrderInvoice.createOrderInvoice({
                orderId: this.orderId,
                invoiceType: this.$route.query.invoiceType || '',
            });
            if (!this.$route.query.invoiceType) {
                await this.$messageBox.alert(
                    '线上平台仅能开具电子普通发票，如需开具纸质发票，需前往学校进行现场开票。',
                );
            }
            if (!data) return;
            window.location.href = data;
        },
    },
    async created() {
        const { data } = await this.$api.Member.Ex.Order.orderDetail(this.orderId);
        this.detail = data;
    },
};
</script>

<style lang="scss">
</style>
