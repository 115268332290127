<!-- Created by henian.xu on 2020/11/20. -->

<template>
    <Page>
        <Container>
            <List
                v-if="listData && listData.length"
                class="ma-t"
                padding
                margin
            >
                <DeferredReviewItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    @click="()=>{onItem(item)}"
                ></DeferredReviewItem>
            </List>

            <div class="pa-a tc-minor">
                <div>延期复审申请要求:</div>
                <ol style="padding-left: 1em;">
                    <li>初领证时间，至今达10年以上，含10年。</li>
                    <li>需在证件复审有效期提前二个月申请，一个有效周期内仅可申请一次。</li>
                </ol>
            </div>

            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />

            <CertCat
                v-show="false"
                :p-id="userInfo.servantId===2?'98':'1'"
                ref="certCatPicker"
                choose-last-item
                @input="onCertCatInput"
            ></CertCat>
        </Container>

        <PageBar
            position="bottom"
        >
            <XButton
                class="item"
                label="新增延期复审"
                theme="success"
                @click="onAdd"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { pageMixin, paginationMixin } from 'mixins';
import DeferredReviewItem from './_DeferredReviewItem';

export default {
    name: 'ListPage',
    components: { DeferredReviewItem },
    mixins: [pageMixin, paginationMixin],
    data() {
        return {
            certCatId: '',
        };
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Ce.DeferredReviewApply.applyList(params);
            console.log(res.data);
            this.listData.push(...res.data);
            return res;
        },
        onAdd() {
            const { certCatPicker } = this.$refs;
            if (!certCatPicker) return;
            certCatPicker.open();
        },
        async onCertCatInput(val) {
            const { data: skuId } = await this.$api.Member.Gd.Goods.searchSku({
                goodsType: '8',
                certExamType: '5',
                certCatId: val,
            });

            const { data, code, success, msg } = await this.$api.Member.Ex.Order.buyNowConfirm(
                {
                    skuId,
                    quantity: 1,
                },
                {
                    isHandleError: true,
                },
            );
            if (!success) {
                if (code === 1200) {
                    await this.$messageBox.confirm(msg, '温馨提示', {
                        confirmBtnText: '立即实名',
                    });
                    this.$router.push(`/user/idCardAuth`);
                } else {
                    this.$messageBox.alert(msg);
                }
                return;
            }
            this.$router.push(`/order/new/${data.uuid}`);
            console.log('onCertCatInput', val, skuId);
        },
        async onItem(item) {
            this.$router.replace(`/deferredReview/applyMaterials/${item.id}`);
        },
    },
};
</script>

<style lang="scss">
</style>
