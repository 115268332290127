<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        class="refund-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div
            v-if="!onlyShow"
            class="header">
            <XIcon
                content="f093"
                theme="main"
            />
            <XLabel>申请退款</XLabel>
        </div>
        <div class="body">
            <div class="inner">
                <dl>
                    <dt>状　　态:</dt>
                    <dd>{{data.refundApplyStatusName}}</dd>
                </dl>
                <dl>
                    <dt>申请时间:</dt>
                    <dd>{{data.applyTime | moment}}</dd>
                </dl>
                <dl>
                    <dt>订 单 号:</dt>
                    <dd>{{data.orderSn}}</dd>
                </dl>
                <dl>
                    <dt>付款时间:</dt>
                    <dd>{{data.paymentTime | moment}}</dd>
                </dl>
                <dl>
                    <dt>订单实付:</dt>
                    <dd class="price">{{data.payableAmount}}</dd>
                </dl>
                <dl>
                    <dt>证　　考:</dt>
                    <dd>【{{data.certExamTypeName}}】{{data.certCatPathName}}</dd>
                </dl>
            </div>
            <XIcon
                v-if="!onlyShow"
                content="f012"
                size="small"
                theme="g5"
            />
        </div>
        <div
            v-if="!onlyShow && refundApplyStatus===10"
            class="footer">
            <XButton
                label="取消申请"
                theme="danger"
                link
                @click.stop.prevent="()=>{onCancelApply()}"
            />
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'RefundItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        onlyShow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        orderDetailList() {
            return this.data.orderDetailList || [];
        },
        refundApplyStatus() {
            return +this.data.refundApplyStatus || 0;
        },
        orderDetail() {
            return this.orderDetailList[0] || {};
        },
        specDesc() {
            return (this.orderDetail.specDesc || '').split(':').pop();
        },
    },
    methods: {
        async onCancelApply() {
            await this.$messageBox.confirm('是否确认取消申请退款？');
            const { msg } = await this.$api.Member.Ex.RefundApply.cancelApply({
                id: this.data.id,
            });
            if (msg) this.$messageBox.tips(msg);
            this.$emit('change', {
                ...this.data,
                refundApplyStatus: 98,
                refundApplyStatusName: '已取消',
            });
        },
    },
};
</script>

<style lang="scss">
.refund-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
    > .footer {
        margin: $padding 0;
        text-align: right;
    }
}
</style>
