<!-- Created by henian.xu on 2020/4/17. -->

<template>
    <Page>
        <PageBar>
            <Tab
                average
                v-model="tabIndex"
            >
                <TabPanel
                    v-for="item in tabList"
                    :key="item.key"
                    :label="item.label"
                />
            </Tab>
        </PageBar>
        <Container fill>
            <!--<div>mode:{{mode}}</div>
            <div>answer{{answer}}</div>
            <div>topicListIndex:{{topicListIndex}}</div>
            <div>swiperActiveIndex:{{swiperActiveIndex}}</div>
            <div>halfDefaultPageSize:{{halfDefaultPageSize}}</div>
            <div>{{questionListTem}}</div>-->
            <div class="cat-name-path">{{certCatNameAndTypePath}}</div>
            <TopicList
                ref="topicList"
                v-if="isActivated && questionList && questionList.length"
                :data="currentQuestionList"
                v-model="answer"
                :index="swiperActiveIndex"
                :start-index="currentQuestionStartIndex"
                :is-practice="+mode"
                @index-change="onTopicListIndexChange"
                @confirm-answer="onSaveAnswer"
            />
            <div
                v-else-if="!isActivated"
                class="loading ta-c pa-a ma-tb"
            >
                <XImage
                    :src="$loadingIcon"
                    width="20%"
                ></XImage>
            </div>
            <div
                v-else
                class="pa-a ta-c tc-g6"
            >表现不错，还没有错题哦，继续努力！</div>
        </Container>
        <PageBar
            position="bottom"
            class="pa-l pa-r"
        >
            <XButton
                label="上一题"
                theme="success"
                radius="all"
                :disabled="!topicListIndex"
                @click="onPrev"
            ></XButton>
            <AnswerCard
                v-if="questionList"
                :data="questionList"
                :value="answer"
                :index="topicListIndex"
                @index-change="onIndexChange"
            />
            <XButton
                label="下一题"
                theme="success"
                radius="all"
                :disabled="topicListIndex+1 === questionListLength"
                @click="onNext"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { MockExamPaper } from 'mixins/index';
import { refMixin } from 'packages/mixins';
import { state, getPracticeDetail } from './practiceStore';

export default {
    name: 'PracticePage',
    mixins: [refMixin, MockExamPaper],
    data() {
        return {
            testType: this.$route.params.type,
            certExamApplyId: this.$route.params.id,
            mode: this.$route.query.mode || '1',
            questionCatId: this.$route.query.questionCatId,
            questionType: this.$route.query.questionType,
            questionAnswer: this.$route.query.questionAnswer,
            keywords: this.$route.query.keywords,
            rand: Math.round(Math.random() * 100),
            isActivated: false,
            tabList: [
                {
                    key: '1',
                    label: '答题模式',
                },
                {
                    key: '2',
                    label: '记题模式',
                },
            ],
            defaultPageSize: 10,
            topicListIndex: 0,
            swiperActiveIndex: 0,
            currentPageCache: {},
            questionList: null,
            answer: [],
            heartbeatTimer: 0,
        };
    },
    head() {
        const { testType } = this;
        const map = {
            1: '顺序练习',
            2: '章节练习',
            3: '专项练习',
            4: '随机答题',
            5: '错题本',
        };
        return {
            title: map[testType] || '练习',
        };
    },
    computed: {
        tabIndex: {
            get() {
                const { mode, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = `${item.key}` === `${mode}`;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                this.mode = tab.key;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        mode: tab.key,
                    },
                });
                // this.refreshList();
            },
        },
        practiceDetail() {
            const { certExamApplyId } = this;
            const { detail } = state;
            if (detail === null || +detail.certExamApplyId !== +certExamApplyId) {
                getPracticeDetail(certExamApplyId);
                return {};
            }
            return detail;
        },
        halfDefaultPageSize() {
            return ~~(this.defaultPageSize / 2);
        },
        currentQuestionStartIndex() {
            const { topicListIndex, halfDefaultPageSize } = this;
            return Math.max(topicListIndex - halfDefaultPageSize, 0);
        },
        currentQuestionList() {
            const { defaultPageSize, currentQuestionStartIndex } = this;
            const index = currentQuestionStartIndex;
            if (!this.questionList) return null;
            const questionList = [...this.questionList];
            return questionList.splice(index, defaultPageSize);
        },
        questionListLength() {
            const { questionList } = this;
            return (questionList && questionList.length) || 0;
        },
        questionListTem() {
            const { currentQuestionList } = this;
            if (!currentQuestionList) return [];
            return currentQuestionList.map(item => {
                if (!item) return '';
                return item.id;
            });
        },
    },
    watch: {
        currentQuestionList: {
            handler(val) {
                if (val && !val.length) return;
                let currentPage = 1;
                if (val) {
                    let index = -1;
                    val.some((item, i) => {
                        if (!item) index = i;
                        return !item;
                    });
                    if (index === -1) return;
                    const { defaultPageSize, currentQuestionStartIndex } = this;

                    currentPage = ~~((currentQuestionStartIndex + index) / defaultPageSize) + 1;
                }
                this.getQuestionListData({ currentPage });
            },
            immediate: true,
        },
        topicListIndex(val) {
            console.log(val);
            const {
                certExamApplyId,
                testType,
                questionCatId,
                questionType,
                questionAnswer,
                // add
            } = this;
            if (`${testType}` === '5') return;
            this.$api.Member.Ce.CertExamPaper.savePracticeProgress({
                certExamApplyId,
                testType,
                questionCatId,
                questionType,
                questionAnswer,
                progress: val,
            });
        },
        practiceDetail: {
            handler(val) {
                if (!val || !val.certExamApplyId) return;
                this.heartbeat();
                const { practiceProgress } = val;
                if (!practiceProgress) return;
                const {
                    topicListIndex,
                    testType,
                    questionCatId,
                    questionType,
                    questionAnswer,
                    // add
                } = this;
                let progress = practiceProgress[`testType${testType}`] || 0;
                if (
                    this.$utils.isObject(progress) &&
                    (`${testType}` === '2' || `${testType}` === '3' || `${testType}` === '6')
                ) {
                    let key = '';
                    switch (`${testType}`) {
                        case '2': {
                            key = questionCatId;
                            break;
                        }
                        case '3': {
                            key = questionType;
                            break;
                        }
                        case '6': {
                            key = `${questionType}-${questionAnswer}`;
                            break;
                        }
                        default:
                            break;
                    }
                    // const key = `${testType}` === '2' ? questionCatId : `${testType}` === '3' ? questionType : '';
                    progress = progress[key] || 0;
                }
                if (topicListIndex === progress) return;
                this.onIndexChange(progress);
            },
            immediate: true,
        },
    },
    methods: {
        async getQuestionListData(params) {
            const {
                certExamApplyId,
                testType,
                questionCatId,
                questionType,
                questionAnswer,
                keywords,
                rand,
                defaultPageSize,
                questionList,
                questionListLength,
                currentPageCache,
            } = this;
            if (currentPageCache[Math.max(params.currentPage || 0, 1)]) return null;
            const res = await this.$api.Member.Ce.CertExamPaper.questionList({
                certExamApplyId,
                testType,
                questionCatId,
                questionType,
                questionAnswer,
                keywords,
                rand,
                pageSize: `${testType}` === '5' ? 99999 : defaultPageSize,
                ...params,
            });
            const {
                data,
                pagination: { currentPage, pageSize, totalRows },
            } = res;
            currentPageCache[currentPage] = true;
            if (questionListLength < totalRows || !totalRows) {
                const temArr = Array(totalRows - questionListLength).fill(null);
                if (!questionList) {
                    this.questionList = temArr;
                    this.answer = [...temArr];
                } else {
                    this.questionList = questionList.concat(temArr);
                    this.answer = questionList.concat([...temArr]);
                }
            }
            this.questionList.splice((currentPage - 1) * pageSize, pageSize, ...data);
            /* this.answer.splice(
                (currentPage - 1) * pageSize,
                pageSize,
                ...data.map(item => {
                    const { id, selectOption } = item;
                    if (!selectOption) return null;
                    return {
                        id,
                        selectOption,
                        // selectOption: 0,
                        isConfirm: true,
                    };
                }),
            ); */
            return res;
        },
        async onIndexChange(val) {
            const topicList = await this.getRefsChildren('topicList');
            const { halfDefaultPageSize } = this;
            this.topicListIndex = val;
            if (this.topicListIndex < halfDefaultPageSize) {
                this.swiperActiveIndex = this.topicListIndex;
                topicList.slideTo(this.swiperActiveIndex, 0, false);
            } else {
                this.swiperActiveIndex = halfDefaultPageSize;
                topicList.slideTo(halfDefaultPageSize, 0, false);
            }
        },
        onPrev() {
            if (!this.questionListLength) return;
            this.topicListIndex -= this.topicListIndex > 0 ? 1 : 0;
            this.onIndexChange(this.topicListIndex);
        },
        onNext() {
            if (!this.questionListLength) return;
            this.topicListIndex += this.topicListIndex < this.questionListLength - 1 ? 1 : 0;
            this.onIndexChange(this.topicListIndex);
        },
        async onTopicListIndexChange(activeIndex) {
            console.log('onTopicListIndexChange');
            await this.$nextTick();
            const {
                swiperActiveIndex,
                topicListIndex,
                halfDefaultPageSize,
                questionListLength,
                // add
            } = this;
            const offsetIndex = activeIndex - swiperActiveIndex;
            this.topicListIndex = Math.min(Math.max(topicListIndex + offsetIndex, 0), questionListLength);
            if (this.topicListIndex < halfDefaultPageSize) {
                this.swiperActiveIndex = this.topicListIndex;
            } else {
                this.swiperActiveIndex = halfDefaultPageSize;
                const topicList = await this.getRefsChildren('topicList');
                topicList.slideTo(halfDefaultPageSize);
            }
        },
        async onSaveAnswer({ question, answer, resolve, reject }) {
            const { certExamApplyId, testType } = this;
            const { id: questionId, selectOption } = answer;
            const { questionAnswer } = question;
            /* console.log({
                questionAnswer,
                certExamApplyId,
                testType,
                questionId,
                selectOption,
            }); */
            /* await this.$api.Member.Ce.CertExamPaper.savePracticeSelect({
                certExamApplyId,
                testType,
                questionId,
                selectOption,
            }); */
            // 加入错题本
            if (`${testType}` !== '5' && questionAnswer !== selectOption) {
                try {
                    const { data } = await this.$api.Member.Ce.CertExamPaper.addErrorBook({
                        certExamApplyId,
                        questionId,
                    });
                    resolve(data);
                } catch (e) {
                    reject(e);
                }
            } else if (`${testType}` === '5' && questionAnswer === selectOption) {
                try {
                    const { data } = await this.$api.Member.Ce.CertExamPaper.delErrorBook({
                        certExamApplyId,
                        questionId,
                    });
                    resolve(data);
                } catch (e) {
                    reject(e);
                }
            }
            resolve();
        },

        practiceHeartbeat() {
            const { certExamApplyId, testType } = this;
            this.$api.Member.Ce.CertExamPaper.practiceHeartbeat({
                certExamApplyId,
                practiceType: testType,
            });
        },
        heartbeat() {
            clearInterval(this.heartbeatTimer);
            let heartbeatCount = 0;
            this.practiceHeartbeat();
            this.heartbeatTimer = setInterval(() => {
                heartbeatCount += 1;
                if (heartbeatCount >= 60) {
                    heartbeatCount = 0;
                    this.practiceHeartbeat();
                }
            }, 1000);
        },
    },
    activated() {
        setTimeout(() => {
            this.isActivated = true;
        }, 500);
    },
    beforeDestroy() {
        state.detail = {};
        clearInterval(this.heartbeatTimer);
    },
};
</script>

<style lang="scss">
.practice-topic-list {
    &.swiper-container {
        height: 100%;
        background-color: $color-component-bgc;
        > .swiper-wrapper {
            > .swiper-slide {
                overflow-y: hidden;
                @include setScrollAxis('y');
            }
        }
    }
}
</style>
