/**
 * Created by henian.xu on 2020/5/22.
 *
 */

import Vue from 'vue';

export const state = Vue.observable({
    needRefresh: false,
});
