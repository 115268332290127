<!-- Created by henian.xu on 2021/4/27. -->

<template>
    <Page>
        <Container>

            <List
                v-if="listData && listData.length"
                padding
                margin
                class="ma-t"
            >
                <div
                    class="statement-item"
                    v-for="item in listData"
                    :key="item.id">
                    <div class="header">
                        <div class="label">{{item.statementName}}</div>
                    </div>
                    <div class="body">
                        <dl>
                            <dt><div>结算单号</div></dt>
                            <dd>{{item.statementCode}}</dd>
                        </dl>
                        <dl>
                            <dt><div>创建时间</div></dt>
                            <dd>{{item.insertTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                        </dl>
                        <dl>
                            <dt><div>结算状态</div></dt>
                            <dd>{{item.enterpriseStatementStatusName}}</dd>
                        </dl>
                        <dl>
                            <dt><div>结算金额</div></dt>
                            <dd>{{item.statementAmount}}</dd>
                        </dl>
                    </div>
                    <div class="footer">
                        <XButton
                            theme="success"
                            link
                            @click="onDownload(item)"
                        >下载文件</XButton>
                    </div>
                </div>
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins';
import { Device } from 'utils';

export default {
    name: 'StatementPage',
    mixins: [paginationMixin],
    data() {
        return {};
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Fn.EnterpriseStatement.enterpriseStatementList({
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        onDownload(item) {
            if (Device.weixin && !Device.windowsWechat) {
                this.$messageBox.alert('手机端浏览器暂不支持操作，请在电脑浏览器操作!');
                return;
            }
            this.$http.href('/member/mb/enterpriseExcel/exportEnterpriseStatementDetailList', {
                enterpriseStatementId: item.id,
            });
        },
    },
};
</script>

<style lang="scss">
.statement-item {
    background-color: $color-component-bgc;
    padding: $padding;
    > .header {
        > .label {
            font-weight: bold;
            font-size: 110%;
        }
    }
    > .body {
        margin-top: $margin;
        @include bodyDl(4em);
    }
    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
