<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <div class="pass-prove-info">
                <div class="avatar">
                    <XImage
                        :aspect-ratio="53/35"
                        :src="applyDetail.avatarUrl"
                        fit="cover"></XImage>
                </div>
                <div class="content">
                    <!--                    <dl>
                        <dt><div>学校</div></dt>
                        <dd>{{applyDetail.schoolOrgName}}</dd>
                    </dl>-->
                    <dl>
                        <dt><div>学员</div></dt>
                        <dd>{{applyDetail.fullName}}</dd>
                    </dl>
                    <dl>
                        <dt><div>实名证件号</div></dt>
                        <dd>{{applyDetail.idCardNo}}</dd>
                    </dl>
                    <dl>
                        <dt><div>培训项目</div></dt>
                        <dd>{{applyDetail.certCatNamePath}}【{{applyDetail.certExamTypeName}}】</dd>
                    </dl>
                    <dl>
                        <dt><div>课程学时</div></dt>
                        <dd>共 {{applyDetail.courseHour}} 学时</dd>
                    </dl>
                    <!--                <dl>
                        <dt><div>考试时间</div></dt>
                        <dd>{{applyDetail.examTime | moment}}</dd>
                    </dl>
                    <dl>
                        <dt><div>考试结果</div></dt>
                        <dd>成绩合格</dd>
                    </dl>-->
                </div>
            </div>

            <Tab
                class="ma-t"
                @change="onTabChange"
                average
            >
                <TabPanel
                    label="培训内容(学时)"
                    sign="trainingContent"
                >
                    <Collapse border>
                        <CollapseItem
                            v-for="(cat,i) in courseCatListData"
                            :key="cat.id"
                            :fold="!!i"
                            :label="cat.courseCatName"
                        >
                            <template #extra>
                                <div class="extra tc-success">{{cat.courseHour}}</div>
                            </template>
                            <CellGroup
                                v-if="cat.courseDetailList && cat.courseDetailList.length"
                                border
                            >
                                <Cell
                                    v-for="(item,index) in cat.courseDetailList"
                                    :key="item.id"
                                    style="background-color: transparent;"
                                >
                                    <template #label>
                                        <div class="label">{{index+1}}.{{item.courseName}}</div>
                                    </template>
                                    <template #extra>
                                        <div class="extra tc-success">{{item.courseHour}}</div>
                                    </template>
                                </Cell>
                            </CellGroup>
                        </CollapseItem>
                    </Collapse>
                </TabPanel>
                <TabPanel
                    label="学习记录"
                    sign="trainingContent"
                >
                    <CellGroup border>
                        <Cell
                            v-for="item in listData"
                            :key="item.id"
                            direction="column">
                            <div class="training-records-item">
                                <div class="content">
                                    <div class="label">{{ item.courseName }}</div>
                                    <div
                                        class="extra"
                                        style="white-space: nowrap">
                                        <div>开始时间: {{ item.beginTime | moment('YYYY-MM-DD HH:mm') }}</div>
                                        <div>结束时间: {{ item.endTime | moment('YYYY-MM-DD HH:mm') }}</div>
                                        <div @click="()=>{onFaceComparison(item)}">人脸对比: <span class="tc-primary">查看照片</span></div>
                                    </div>
                                </div>
                            </div>
                        </Cell>
                    </CellGroup>
                    <Pagination
                        ref="infinite"
                        :disabled="paginationDisabled"
                        @load="onload"
                    />
                </TabPanel>
            </Tab>
            <Dialog
                :show="faceComparisonDialog.show"
                ref="faceComparisonDialog">
                <div class="face-comparison-dialog-avatar">
                    <XImage
                        width="100%"
                        :aspect-ratio="53/35"
                        :src="faceComparisonDialog.imgSrc"
                        fit="cover"></XImage>
                    <div class="time">{{faceComparisonDialog.time|moment('YYYY-MM-DD HH:mm:ss')}}</div>
                </div>
            </Dialog>
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins';

export default {
    name: 'ScorePage',
    mixins: [paginationMixin],
    data() {
        return {
            certExamApplyId: this.$route.params.id,
            detail: {},
            tabSign: 'trainingContent',
            faceComparisonDialog: {
                imgSrc: '',
                time: '',
            },
        };
    },
    computed: {
        applyNo() {
            return this.$route.params.applyNo;
        },
        courseCatListData() {
            const { certExamCourseCatList } = this.detail;
            return certExamCourseCatList || [];
        },
        applyDetail() {
            return this.detail.applyDetail || {};
        },
    },
    methods: {
        async getApplyInfo() {
            const { data } = await this.$api.Member.Ce.CertExamApply.applyInfo(
                {
                    applyNo: this.applyNo,
                },
                {
                    isLoading: true,
                },
            );
            this.detail = data;
        },
        async getListData(params) {
            const { certCatId } = this;
            console.log(certCatId);
            const res = await this.$api.Member.Ce.CertExamApply.applyCourseLogList({
                applyNo: this.applyNo,
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        onTabChange({ sign }, index) {
            console.log(sign, index);
            this.tabSign = sign;
        },
        onFaceComparison(item) {
            if (!item.recognitionImage) {
                this.$messageBox.alert('未获取到照片');
                return;
            }
            if (!this.$refs.faceComparisonDialog) return;
            this.$refs.faceComparisonDialog.open();
            this.faceComparisonDialog.imgSrc = item.recognitionImage || '';
            this.faceComparisonDialog.time = item.beginTime || '';
        },
    },
    created() {
        this.getApplyInfo();
    },
};
</script>

<style lang="scss">
.pass-prove-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: $padding;
    background-color: $color-component-bgc;
    font-size: $little * 100%;
    > .avatar {
        width: 200px;
        flex: 0 0 auto;
        margin-right: $margin;
    }
    > .content {
        flex: 1 1 1%;
        @include bodyDl(5em);
    }
}
.training-records-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .content {
        flex: 1 1 1%;
        > .label {
        }
        > .extra {
            color: $color-text-minor;
        }
    }
    > .extra {
        margin-left: $margin-small;
    }
}
.face-comparison-dialog-avatar {
    position: relative;
    flex: 0 0 auto;
    > .time {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#000, 0.5);
        color: #fff;
        //font-size: $small * 100%;
        line-height: 1;
        text-align: center;
        padding: $padding-small;
    }
}
</style>
