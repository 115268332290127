<!-- Created by henian.xu on 2020/5/26. -->

<template>
    <Page>
        <Container>
            <template v-if="detail">
                <div
                    v-if="detail.enterpriseCooperationSn"
                    class="pa-a dp-fx fx-row fx-jc-sb fx-ai-c"
                >
                    <XLabel
                        theme="g6"
                        :label="`合作号:${detail.enterpriseCooperationSn}`" />
                    <XLabel :label="detail.enterpriseCooperationStatusName" />
                </div>
                <CellGroup border>
                    <Cell
                        label="合作名称"
                        :extra="detail.enterpriseCooperationName"
                    />
                    <Cell
                        label="培训学员"
                        :to="`/enterprise/student/${detail.id}`">
                        <template #extra>
                            <div class="extra tc-main">{{detail.quantity}}</div>
                        </template>
                    </Cell>
                </CellGroup>
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel label="费用明细" />
                </div>
                <CellGroup border>
                    <Cell
                        v-for="(item,index) in detail.detailList"
                        :key="item.id"
                        :label="`${index+1}、${item.name}`"
                        :extra="`${item.quantity}*${item.price}元/人`"
                    ></Cell>
                </CellGroup>
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-fe fx-ai-c">
                    <XLabel>合计：<span class="tc-main">{{detail.totalAmount}}</span>元</XLabel>
                </div>
                <CellGroup
                    class="ma-t"
                    border
                >
                    <Cell
                        label="经办人备注"
                        direction="column"
                    >
                        <template #extra>
                            <div v-html="detail.enterpriseRemarks || '无'"></div>
                        </template>
                    </Cell>
                    <Cell
                        label="培训人员汇总表"
                        link
                        @click="onDowLoad"
                    >
                        <template #extra>
                            <div class="tc-primary">下载表格</div>
                        </template>
                    </Cell>
                </CellGroup>

                <template v-if="detail.enterpriseCooperationStatus === 98">
                    <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                        <XLabel label="不通过原因：" />
                    </div>
                    <div
                        class="pa-l pa-r tc-danger"
                        style="word-break: break-all">{{detail.auditOpinion}}</div>
                </template>

                <CellGroup
                    v-if="detail.enterpriseCooperationStatus === 10 || detail.enterpriseCooperationStatus === 100"
                    class="ma-t"
                    border
                >
                    <Cell
                        label="结算方式"
                        :extra="detail.settlementMethodName"
                    />
                    <template v-if="detail.settlementMethod===1">
                        <Cell
                            label="预付金额(元)"
                        >
                            <template #extra>
                                <div class="price">{{detail.prepaidAmount}}</div>
                            </template>
                        </Cell>
                        <Cell
                            v-if="detail.prepaidAttachmentList && detail.prepaidAttachmentList.length"
                            label="预付款凭证"
                            direction="column"
                        >
                            <template #extra>
                                <ImageList
                                    :columns="3"
                                    preview
                                    :data="detail.prepaidAttachmentList"
                                />
                            </template>
                        </Cell>
                    </template>
                </CellGroup>
                <CellGroup
                    v-if="detail.enterpriseCooperationStatus === 10 || detail.enterpriseCooperationStatus === 100"
                    class="ma-t"
                    border
                >
                    <Cell
                        label="合作备注"
                        direction="column"
                    >
                        <template #extra>
                            <div v-html="detail.remarks || '无'"></div>
                        </template>
                    </Cell>
                </CellGroup>
                <CellGroup
                    v-if="detail.enterpriseCooperationStatus === 100"
                    class="ma-t"
                    border
                >
                    <Cell label="结算金额(元)">
                        <template #extra>
                            <div class="price">{{detail.settlementAmount}}</div>
                        </template>
                    </Cell>
                    <Cell
                        label="结算时间"
                        :extra="detail.settlementTime | moment"
                    />
                    <Cell
                        v-if="detail.settlementAttachmentList && detail.settlementAttachmentList.length"
                        label="结算款凭证"
                        direction="column"
                    >
                        <template #extra>
                            <ImageList
                                :columns="3"
                                preview
                                :data="detail.settlementAttachmentList"
                            />
                        </template>
                    </Cell>
                    <Cell
                        label="结算备注"
                        direction="column"
                    >
                        <template #extra>
                            <div v-html="detail.settlementRemarks || '无'"></div>
                        </template>
                    </Cell>
                </CellGroup>
            </template>
        </Container>
    </Page>
</template>

<script>
export default {
    name: 'Detail',
    data() {
        return {
            id: this.$route.params.id,
            detail: null,
        };
    },
    methods: {
        async getDetail() {
            const { data } = await this.$api.Member.Mb.Enterprise.cooperationDetail(this.id);
            this.detail = data;
        },
        onDowLoad() {
            this.$http.href('/member/mb/enterpriseExcel/exportCooperationMemberList', {
                enterpriseCooperationId: this.detail.id,
            });
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
</style>
