<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <!--<XVideo
                src="//player.alicdn.com/video/aliyunmedia.mp4"
            />
            <div class="course-info">
                <div class="label">3.安全生产法律法规</div>
                <div class="info">
                    <span>学时:2/30</span>
                </div>
            </div>-->

            <div v-if="listData && listData.length">
                <div
                    v-for="courseCat in listData"
                    :key="courseCat.courseCatType"
                    :label="courseCat.courseCatTypeName"
                >
                    <div class="pa-l pa-r"><Divider :label="courseCat.courseCatTypeName"></Divider></div>
                    <Collapse
                        v-if="courseCat.certExamCourseCatList && courseCat.certExamCourseCatList.length"
                        border>
                        <CollapseItem
                            v-for="cat in courseCat.certExamCourseCatList"
                            :key="cat.id"
                            :label="cat.courseCatName"
                        >
                            <CellGroup
                                v-if="cat.courseDetailList && cat.courseDetailList.length"
                                border
                            >
                                <Cell
                                    v-for="(item,index) in cat.courseDetailList"
                                    :key="item.id"
                                    style="background-color: transparent;"
                                    @click="()=>{onItem(item)}"
                                >
                                    <template #label>
                                        <div class="label">
                                            <Badge
                                                theme="g7"
                                                plain
                                            >{{item.courseTypeName}}</Badge>
                                            {{index+1}}.{{item.courseName}}
                                        </div>
                                    </template>
                                    <template #extra>
                                        <div class="extra tc-success">{{(item.completionRatio * 100).toFixed(0)}}%</div>
                                    </template>
                                </Cell>
                            </CellGroup>
                        </CollapseItem>
                    </Collapse>
                </div>
            </div>
            <div
                v-else
                class="pa-a ta-c tc-minor"
            >暂无数据</div>
        </Container>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import { Device } from 'utils';
import Divider from 'components/widget/decoration/Divider.vue';
import { toDataURL } from 'utils/QRCode';
import Url from 'url';
import Qs from 'qs';
import { state, getCourseList } from './courseStore';

export default {
    name: 'IndexPage',
    components: { Divider },
    data() {
        return {
            certExamApplyId: this.$route.params.id,
        };
    },
    computed: {
        ...mapGetters('User', ['userInfo']),
        listData() {
            const { listData, certExamApplyId } = state;
            if (this.certExamApplyId && +this.certExamApplyId !== +certExamApplyId) {
                getCourseList(this.certExamApplyId);
                return [];
            }
            return listData;
        },
    },
    methods: {
        async onItem(item) {
            // :to="`/course/detail/${item.id}?certExamApplyId=${certExamApplyId}`"
            if (item.courseAuthType && item.courseAuthType & 1 && (Device.windowsWechat || Device.desktop)) {
                const baseUri = Url.parse(window.location.href.split('#')[0]);
                const baseQuery = Qs.parse(baseUri.query) || {};
                baseQuery.shareUrl = `/course/courseOutAuth/${this.certExamApplyId}/${item.id}`;
                baseQuery.authType = `course`;
                baseUri.search = Qs.stringify(baseQuery);
                const qrcodeUrl = await toDataURL(baseUri.format());
                // console.log(baseUri.format());
                const node = this.$createElement('div', [
                    this.$createElement(
                        'div',
                        {
                            class: 'tc-minor ma-b',
                            props: {},
                        },
                        ['请微信扫码进行人脸验证，验证通过后，点击“认证完成”开始课程学习!'],
                    ),
                    this.$createElement('XImage', {
                        props: {
                            src: qrcodeUrl,
                        },
                    }),
                ]);
                let isPass = false;
                await this.$messageBox.confirm(node, '温馨提示', {
                    cancelBtnText: '取消',
                    confirmBtnText: '认证完成',
                    isClickOtherClose: false,
                    validCallback: async action => {
                        if (action !== 'confirm') return true;
                        const {
                            // data,
                            success,
                            code,
                            msg,
                        } = await this.$api.Member.Ce.CertExamCourse.certExamCourseDetail(
                            {
                                certExamApplyId: this.certExamApplyId,
                                certExamCourseDetailId: item.id,
                            },
                            {
                                isHandleError: true,
                            },
                        );

                        if (!(!success && code === 4031)) {
                            isPass = true;
                            return true;
                        }

                        return Promise.reject(new Error(`<div class="tc-danger">${msg}</div>`));
                    },
                });
                if (!isPass) return;
            }
            this.$router.push(`/course/detail/${item.id}?certExamApplyId=${this.certExamApplyId}`);
        },
    },
    activated() {
        if (+this.certExamApplyId === +state.certExamApplyId) {
            getCourseList(this.certExamApplyId);
        }
    },
};
</script>

<style lang="scss" scoped>
.course-info {
    padding: $padding;
    background-color: $color-component-bgc;
    > .label {
        font-weight: bold;
    }
    > .info {
        text-align: right;
        color: $color-text-minor;
        margin-top: $margin;
    }
}
</style>
