<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container>
            <List
                class="ma-t"
                padding
                margin
            >
                <CredentialsItem
                    v-for="item in listData"
                    :key="item.id"
                    :dataset="item"
                    :to="`/credentials/detail/${item.id}`"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins';

export default {
    name: 'CredentialsListPage',
    mixins: [paginationMixin],
    data() {
        return {};
    },
    computed: {},
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Mb.MemberApplyMaterials.memberApplyMaterialsList(params);
            this.listData = res.data;
            return res;
        },
    },
};
</script>

<style lang="scss">
</style>
