<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <div class="simulation-display-box">
                <div class="header">
                    <XIcon
                        content="f09c"
                        theme="main"
                    />
                    <XLabel :label="certExam.certCatName"></XLabel>
                </div>
                <div class="body">
                    <div class="inner">
                        <dl>
                            <dt>总　　分:</dt>
                            <dd>{{certExam.totalScore}}</dd>
                        </dl>
                        <dl>
                            <dt>合格分数:</dt>
                            <dd>{{certExam.passMark}}</dd>
                        </dl>
                        <dl>
                            <dt>考题数量:</dt>
                            <dd>{{certExam.questionNum}}</dd>
                        </dl>
                        <dl>
                            <dt>答题限时:</dt>
                            <dd>{{certExam.examTime}}分钟</dd>
                        </dl>
                    </div>
                    <div class="score">
                        <div class="value">{{paperStat.avgExamResult||0 | number(0)}}</div>
                        <div class="label">近3次模拟平均分</div>
                    </div>
                </div>
            </div>

            <List
                class="ma-t"
                border
            >
                <div
                    v-for="item in listData"
                    :key="item.id"
                    class="paper-item"
                >
                    <div class="header">
                        <div class="label">成绩: {{item.examScore}}分</div>
                        <!--只能查看近七天的试卷内容-->
                        <XButton
                            v-if="item.isCanView"
                            label="查看试卷"
                            theme="main"
                            radius
                            plain
                            :to="`/exam/result/${item.id}`"
                        ></XButton>
                        <!--                        <div
                            v-else
                            class="tc-minor fs-little">只能查看近七天的试卷内容</div>-->
                    </div>
                    <div class="body">
                        <dl>
                            <dt>答题时长:</dt>
                            <dd>{{getAnswerTime(item)}}</dd>
                        </dl>
                        <dl>
                            <dt>考试时间:</dt>
                            <dd>{{item.beginTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                        </dl>
                    </div>
                </div>
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="开始考试"
                theme="success"
                :disabled="submitting"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { paginationMixin, MockExamPaper } from 'mixins/index';
import { getMockExamPaper } from 'mixins/MockExamPaper';

export default {
    name: 'SimulationPage',
    mixins: [paginationMixin, MockExamPaper],
    data() {
        return {
            certExamApplyId: this.$route.params.id,
            submitting: false,
        };
    },
    methods: {
        getAnswerTime({ beginTime, finishTime }) {
            const millisecond = finishTime - beginTime;
            return this.$utils.formatDuration(millisecond);
        },
        async getListData(params) {
            const res = await this.$api.Member.Ce.CertExamPaper.paperList({
                certExamApplyId: this.certExamApplyId,
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        async onSubmit() {
            const { certExamApplyId, submitting } = this;
            if (submitting) return;
            this.submitting = true;
            let { certExamApplyPaperId } = this;
            if (certExamApplyPaperId) {
                try {
                    await this.$messageBox.confirm('您有未完成的考试?', '温馨提示', {
                        cancelBtnText: '重新考试',
                        confirmBtnText: '继续考试',
                        isClickOtherClose: false,
                    });
                    await this.$router.push(`/exam/paper/${certExamApplyPaperId}`);
                    setTimeout(() => {
                        this.submitting = false;
                    }, 500);
                    return;
                } catch (e) {
                    // noop
                }
            }
            const { data } = await this.$api.Member.Ce.CertExamPaper.exam({ certExamApplyId });
            getMockExamPaper(certExamApplyId);
            certExamApplyPaperId = data;
            await this.$router.push(`/exam/paper/${certExamApplyPaperId}`);
            setTimeout(() => {
                this.submitting = false;
            }, 500);
        },
    },
    activated() {
        this.refreshList();
    },
};
</script>

<style lang="scss">
.simulation-display-box {
    background-color: $color-component-bgc;
    overflow: hidden;
    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;

        > .inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                > dt {
                    flex: 0 0 auto;
                }
                > dd {
                    flex: 1;
                }
            }
        }
        > .score {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            > .value {
                //font-weight: bold;
                font-size: 500%;
                color: $color-success;
                line-height: 1;
                &:after {
                    content: '分';
                    font-size: $body-font-size;
                }
            }
            > .label {
                color: $color-text-minor;
            }
        }
    }
}
.paper-item {
    background-color: $color-component-bgc;
    overflow: hidden;
    > .header {
        margin: $margin;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    > .body {
        margin: $margin;

        dl {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            + dl {
                margin-top: $margin-small;
            }
            > dt {
                flex: 0 0 auto;
            }
            > dd {
                flex: 1;
            }
        }
    }
}
</style>
