<!-- Created by henian.xu on 2020/4/15. -->

<template>
    <div class="apply-list">
        <List
            class="ma-t"
            padding
            margin
        >
            <ApplyItem
                v-for="item in listData"
                :key="item.id"
                :data="item"
            />
        </List>
        <Pagination
            ref="infinite"
            :disabled="paginationDisabled"
            @load="onload"
        />
    </div>
</template>

<script>
import { paginationMixin } from 'mixins/index';
import { state } from './applyListStore';

export default {
    name: 'ApplyList',
    mixins: [paginationMixin],
    data() {
        return {
            needRefresh: false,
        };
    },
    props: {
        applyStatus: {
            type: [String, Number],
            default: '',
        },
        activated: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        needRefresh_() {
            return state.needRefresh;
        },
    },
    watch: {
        needRefresh_(val) {
            if (!val) return;
            this.needRefresh = true;
        },
        activated(val) {
            if (!val) return;
            if (this.needRefresh) {
                this.refreshList();
                state.needRefresh = false;
                this.needRefresh = false;
            }
        },
    },
    methods: {
        async getListData(params) {
            const { applyStatus } = this;
            console.log(applyStatus);
            const res = await this.$api.Member.Ce.CertExamApply.applyList(
                {
                    applyStatus,
                    ...params,
                },
                {
                    isRepeat: true,
                },
            );
            this.needRefresh = false;
            state.needRefresh = false;
            this.listData.push(...res.data);
            return res;
        },
    },
};
</script>

<style lang="scss">
</style>
