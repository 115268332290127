<!-- Created by henian.xu on 2020/5/26. -->

<template>
    <div
        class="schedule-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div class="header">
            <XIcon
                content="f08a"
                theme="main"
            />
            <XLabel>{{data.certCatName}}</XLabel>
        </div>
        <div class="body">
            <div class="inner">
                <dl>
                    <dt><div>报　　名</div>:</dt>
                    <dd>【{{data.certExamTypeName}}】({{data.certExamApplyTypeName}})</dd>
                </dl>
                <dl>
                    <dt><div>编班名称</div>:</dt>
                    <dd>{{data.verticalGroupingName}}</dd>
                </dl>
                <dl
                    v-if="noList"
                    class="tc-g5">
                    <dt><div>编班批次</div>:</dt>
                    <dd>{{data.verticalGroupingSn}}</dd>
                </dl>
                <dl :class="noList?'tc-g5':''">
                    <dt><div>编班时间</div>:</dt>
                    <dd>{{data.verticalGroupingTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                </dl>
                <dl v-if="!noList">
                    <dt><div>班级群</div>:</dt>
                    <dd>
                        <Linker
                            class="tc-primary"
                            :to="`/groupQRCode/schedule/${data.id}`"
                        >点我加入，教务通知不再错过!</Linker>
                    </dd>
                </dl>
            </div>
        </div>
        <CellGroup
            v-if="!noList"
            class="footer"
        >
            <Cell
                label="考试详情"
                link
            />
        </CellGroup>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'ScheduleItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        noList: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.schedule-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                    margin-right: $margin-small;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    @include clearfix;
                    > div {
                        width: 5em;
                        text-align: justify;
                        overflow: hidden;
                        margin-bottom: -($body-font-size * $line-height);
                        float: left;
                        &:after {
                            display: inline-block;
                            content: '';
                            padding-left: 100%;
                        }
                    }
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
    > .footer {
        border-top: 1px solid $color-border;
    }
}
</style>
