<!-- Created by henian.xu on 2020/4/21. -->

<template>
    <Page>
        <Container>
            <CellGroup border>
                <Cell
                    v-for="(item,index) in listData"
                    :key="index"
                    :label="`${item.questionTypeName}  答案为: ${item.questionType!==2?item.questionAnswerStr:item.questionAnswerStr==='A'?'对':'错'}`"
                    :extra="`${item.questionNum}题`"
                    link
                    @click="onItem(item,index)"
                ></Cell>
            </CellGroup>
        </Container>
    </Page>
</template>

<script>
import { state, getPracticeDetail } from './practiceStore';

export default {
    name: 'CategoryPage',
    data() {
        return {
            certExamApplyId: this.$route.params.id,
        };
    },
    computed: {
        practiceDetail() {
            const { certExamApplyId } = this;
            const { detail } = state;
            if (detail === null || +detail.certExamApplyId !== +certExamApplyId) {
                getPracticeDetail(certExamApplyId);
                return {};
            }
            return detail;
        },
        listData() {
            const { practiceDetail } = this;
            if (!practiceDetail.certExamApplyId) return [];
            return practiceDetail.questionAnswerGroupList;
        },
    },
    methods: {
        onItem(item) {
            const { certExamApplyId } = this;
            this.$router.push(
                `/exam/practice/6/${certExamApplyId}?questionType=${item.questionType}&questionAnswer=${
                    item.questionAnswer
                }`,
            );
        },
    },
};
</script>

<style lang="scss">
</style>
