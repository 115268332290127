<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container>
            <XForm border>
                <FormItem label="证件">
                    <XInput/>
                </FormItem>
                <FormItem label="失效日期">
                    <XInput/>
                </FormItem>
                <FormItem direction="column">
                    <template #label>
                        <div class="label">
                            证件正面
                            <Linker
                                to="/"
                                class="tc-primary"
                            >查看示例</Linker>
                        </div>
                    </template>
                    <Upload action="/"/>
                </FormItem>
                <FormItem direction="column">
                    <template #label>
                        <div class="label">
                            证件反面
                            <Linker
                                to="/"
                                class="tc-primary"
                            >查看示例</Linker>
                        </div>
                    </template>
                    <Upload action="/"/>
                </FormItem>
            </XForm>
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="确认提交"
                theme="success"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
export default {
    name: 'AddCredentialsPage',
    data() {
        return {
            tabList: [
                {
                    key: '1',
                    label: '全部',
                },
                {
                    key: '2',
                    label: '生效中',
                },
                {
                    key: '3',
                    label: '已失效',
                },
            ],
            tabShowed: {},
        };
    },
    computed: {
        courseTabKey() {
            const { tabList } = this;
            return this.$route.query.courseTabKey || tabList[0].key;
        },
        tabIndex: {
            get() {
                const { courseTabKey, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = item.key === courseTabKey;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        courseTabKey: tab.key,
                    },
                });
                // this.refreshList();
            },
        },
    },
    watch: {
        tabIndex: {
            handler(val) {
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
    methods: {},
};
</script>

<style lang="scss">
</style>
