<!-- Created by henian.xu on 2020/4/21. -->

<template>
    <Page>
        <Container>
            <CellGroup border>
                <Cell
                    class="notice-item"
                    v-for="item in listData"
                    :key="item.id"
                    :to="`/system/notice/detail/${item.id}`"
                >
                    <template #label>
                        <div class="label fw-bold">{{item.messageTitle}}</div>
                    </template>
                    <template #subLabel>
                        <div class="sub-label">{{item.messageContent}}</div>
                    </template>
                    <template #extra>
                        <div class="extra ta-r">
                            <div class="fs-little">{{item.sendTime|moment}}</div>
                            <div class="">{{item.sendTime|moment('HH:mm')}}</div>
                        </div>
                    </template>
                </Cell>
            </CellGroup>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { pageMixin, paginationMixin } from 'mixins/index';

export default {
    name: 'NoticePage',
    mixins: [pageMixin, paginationMixin],
    data() {
        return {};
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Comm.Ms.UserMessage.userMessageList(params);
            this.listData.push(...res.data);
            this.userInfoUpdated();
            return res;
        },
    },
};
</script>

<style lang="scss">
.notice-item {
    .sub-label {
        color: $gray6;
        @include text-line(1);
    }
}
</style>
