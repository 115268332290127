<!-- Created by henian.xu on 2020/7/24. -->

<template>
    <Dialog
        ref="dialog"
        label="初次学习本课程请签名"
        :show-close-btn="showCloseBtn"
        @close="onDialogClose"
    >
        <div
            class="ta-c pa-a"
            v-if="uploadUrl"
        >
            <EditSignature
                class="dp-ib"
                is-batch
                @input="(val)=>{onEditSignatureInput(val)}"
            ></EditSignature>
            <div
                v-if="errorMessage"
                class="tc-danger"
            >{{errorMessage}}</div>
        </div>
        <!--<template #footer>
            <div class="footer">
                <XButton theme="success">确认</XButton>
            </div>
        </template>-->
    </Dialog>
</template>

<script>
import Url from 'url';
import Qs from 'qs';

export default {
    name: 'CourseRecognitionSignatureDialog',
    data() {
        return {
            promise: null,
            errorMessage: '',
        };
    },
    props: {
        params: {
            type: Object,
            default: () => ({}),
        },
        showCloseBtn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        uploadUrl() {
            const { params } = this;
            if (!params.certExamApplyId) return '';
            const url = {
                pathname: '/member/ce/certExamCourse/recognition',
                search: Qs.stringify(params),
            };
            return Url.format(url);
        },
    },
    methods: {
        async validate() {
            const promise = new Promise((resolve, reject) => {
                this.promise = {
                    resolve,
                    reject,
                };
            });
            this.$refs.dialog.open();
            return promise;
        },
        async onEditSignatureInput(val) {
            console.log('onEditSignatureInput', val);
            this.errorMessage = '';
            const { msg, success } = await this.$api.Member.Ce.CertExamApply.saveSignature(
                {
                    ...this.params,
                    signature: val,
                },
                {
                    isHandleError: true,
                },
            );
            if (!success) {
                this.errorMessage = msg;
                return;
            }
            if (this.promise) {
                this.promise.resolve();
                this.promise = null;
            }
            this.$refs.dialog.close();
        },
        onDialogClose() {
            if (this.promise) {
                this.promise.reject();
                this.promise = null;
            }
        },
    },
    beforeDestroy() {
        if (this.promise) {
            this.promise.reject({ destroy: true });
            this.promise = null;
        }
    },
};
</script>

<style lang="scss">
</style>
