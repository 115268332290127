<!-- Created by henian.xu on 2020/9/25. -->

<template>
    <div
        class="schedule-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div class="header">
            <XIcon
                content="f08a"
                theme="main"
            />
            <XLabel :label="data.certCatName"></XLabel>
        </div>
        <div class="body">
            <CellGroup border>
                <Cell
                    label="编班时间:"
                    :extra="data.verticalGroupingTime | moment('YYYY-MM-DD HH:mm:ss')"
                ></Cell>
                <Cell
                    focus-extra
                    label="状态:"
                    :extra="data.verticalGroupingStatusName"
                ></Cell>
                <Cell
                    v-if="data.appraisalSubject===1 || data.appraisalSubject===3"
                    direction="column">
                    <div class="label dp-fx fx-jc-sb fx-ai-c">
                        <div>理论单项考试:</div>
                        <div>{{data.theoryExamResult}}</div>
                    </div>
                    <template #extra>
                        <div class="extra">时间: {{data.theoryExamTimeBegin | moment('YYYY-MM-DD HH:mm:ss')}} - {{data.theoryExamTimeEnd | moment('YYYY-MM-DD HH:mm:ss')}}</div>
                    </template>
                </Cell>
                <Cell
                    v-if="data.appraisalSubject===2 || data.appraisalSubject===3"
                    direction="column">
                    <div class="label dp-fx fx-jc-sb fx-ai-c">
                        <div>实操单项考试:</div>
                        <div>{{data.practiceExamResult}}</div>
                    </div>
                    <template #extra>
                        <div class="extra">时间: {{data.practiceExamTimeBegin | moment('YYYY-MM-DD HH:mm:ss')}} - {{data.practiceExamTimeEnd | moment('YYYY-MM-DD HH:mm:ss')}}</div>
                    </template>
                </Cell>
                <Cell
                    label="考试详情:"
                    link
                ></Cell>
            </CellGroup>
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'ScheduleItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.schedule-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();
    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
}
</style>
