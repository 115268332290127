<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <div class="bor-b">
            <SearchBox
                v-model="formModel.fullName"
                @input="onSearchBoxInput"
            ></SearchBox>
        </div>
        <Container>
            <List
                class="ma-t"
                padding
                margin
            >
                <StudentItem
                    v-for="(item,index) in listData"
                    :key="item.id"
                    :data="item"
                    @change="onStudentItemChange($event,index)"
                    :to="`/enterprise/student/detail/${item.id}`"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins/index';
import { debounce } from 'utils/Common';

export default {
    name: 'StudentPage',
    mixins: [paginationMixin],
    data() {
        return {
            formModel: {
                fullName: '',
            },
            enterpriseCooperationId: this.$route.params.id,
        };
    },
    methods: {
        async getListData(params) {
            const { enterpriseCooperationId } = this;
            const res = await this.$api.Member.Mb.Enterprise.cooperationMemberList({
                enterpriseCooperationId,
                ...this.formModel,
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        onStudentItemChange(data, index) {
            if (data) this.listData.splice(index, 1, data);
        },
        onSearchBoxInput: debounce(function handler() {
            this.refreshList();
        }),
    },
};
</script>

<style lang="scss">
</style>
