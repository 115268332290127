<!-- Created by henian.xu on 2024/8/15. -->

<template>
    <Page>
        <Container v-if="isDesktop">
            <div class="pa-a ta-c tc-danger">此页面仅在手机端要用!</div>
        </Container>
        <Container v-else>
            <div class="ta-c pa-a">
                <Upload
                    ref="upload"
                    :action="uploadUrl"
                    :max-files="1"
                    :compress="{maxWidth:1900,maxHeight:1900}"
                    :item-height="'120%'"
                    accept="image/*"
                    capture="camera"
                    placeholder="点我拍照，人脸验证"
                    icon-content="f0a2"
                    icon-size="super"
                    @start="onUploadStart"
                    @error="onUploadError"
                    @success="onUploadSuccess"
                ></Upload>
            </div>
            <div
                v-if="errorMessage"
                class="pa-l pa-r ta-c tc-danger"
            >{{errorMessage}}</div>
            <div
                v-if="isSuccess"
                class="pa-l pa-r ta-c tc-success"
            >
                <div>已验证通过</div>
                <div>请在电脑端点击“认证完成”开始课程学习!</div>
            </div>
        </Container>
    </Page>
</template>

<script>
import Qs from 'qs';
import Url from 'url';
import { Device } from 'utils';

export default {
    name: 'CourseOutAuthPage',
    data() {
        return {
            certExamApplyId: this.$route.params.certExamApplyId,
            certExamCourseDetailId: this.$route.params.certExamCourseDetailId,
            errorMessage: '',
            isSuccess: false,
        };
    },
    computed: {
        isDesktop() {
            return Device.windowsWechat || Device.desktop;
        },
        uploadUrl() {
            const { certExamApplyId, certExamCourseDetailId } = this;
            if (!certExamApplyId) return '';
            const url = {
                pathname: '/member/ce/certExamCourse/recognition',
                search: Qs.stringify({ certExamApplyId, certExamCourseDetailId }),
            };
            return Url.format(url);
        },
    },
    methods: {
        onUploadStart() {
            this.errorMessage = '';
            this.isSuccess = false;
        },
        onUploadError(error) {
            this.errorMessage = error.message;
            this.$refs.upload.clear();
        },
        async onUploadSuccess(data) {
            console.log('onUploadStart', data);
            if (this.promise) {
                this.promise.resolve();
                this.promise = null;
            }
            this.$refs.upload.clear();
            this.isSuccess = true;
        },
    },
};
</script>

<style lang="scss">
</style>
