<!-- Created by henian.xu on 2020/4/30. -->

<template>
    <Page>
        <Container>
            <template v-if="materialsStatus!==30 && materialsStatus!==100">
                <div
                    v-if="isNeedSignature && signatureList && signatureList.length"
                    class="signature-wrap"
                >
                    <XButton
                        style="min-width: 6em;"
                        v-for="field in signatureList"
                        :key="field.name"
                        theme="main"
                        radius
                        @click="()=>{onSignature(field.name)}"
                    >{{field.label}}</XButton>
                </div>
                <div
                    v-if="isNeedPMImage"
                    class="signature-wrap"
                >
                    <template v-if="isPreviewDownload">
                        <XButton
                            style="min-width: 6em;"
                            theme="main"
                            radius
                            is-a
                            target="_blank"
                            :to="downUrl"
                        >下载PDF原件</XButton>
                        <div class="desc tc-danger">或长按图片并保存图片</div>
                    </template>
                    <template v-else>
                        <XButton
                            v-for="(item,index) in materialsNum"
                            :key="item"
                            style="min-width: 6em;"
                            theme="main"
                            radius
                            @click="()=>{selectFile(index)}"
                        >上传原件<template v-if="materialsNum>1">(第{{index + 1}}页)</template></XButton>
                    </template>
                </div>
            </template>
            <template v-for="(item,index) in materialsNum">
                <XImage
                    v-if="previewUrl"
                    :key="item"
                    width="100%"
                    :src="`${previewUrl}&index=${index}`"
                ></XImage>
            </template>
            <EditSignature
                v-show="false"
                ref="editSignature"
                @input="(val)=>{onEditSignatureInput(val)}"
            ></EditSignature>
            <Upload
                v-show="false"
                cropper
                ref="upload"
                accept="image/*"
                action="/comm/rs/attachment/uploadFileLibrary"
            ></Upload>
        </Container>
        <PageBar position="bottom">
            <XButton
                icon="f011"
                class="item fx-jc-fs"
                link
                @click="$router.back()"
            ></XButton>
            <!--<XButton
                v-if="isNeedSignature"
                class="item"
                label="签名"
                theme="main"
                @click="()=>{onSignature()}"
            ></XButton>-->
        </PageBar>
    </Page>
</template>

<script>
import { hasOwn } from 'utils/Common';
import { getMaterialsValueList, MaterialsDetailMixin } from 'pages/Materials/store';

export default {
    name: 'PreviewMaterialsPage',
    mixins: [MaterialsDetailMixin],
    data() {
        return {
            isNeedSignature: hasOwn(this.$route.query, 'isNeedSignature'),
            isNeedPMImage: hasOwn(this.$route.query, 'isNeedPMImage'),
            isPreviewDownload: hasOwn(this.$route.query, 'isPreviewDownload'),
            detail: {},
            currentSignatureField: '',
            now: Date.now(),
        };
    },
    head() {
        const { isNeedSignature } = this;
        let res = null;
        if (isNeedSignature) {
            res = {
                title: '材料签名',
            };
        }
        return res;
    },
    computed: {
        previewUrl() {
            const { materialsValueType, materialsValueId, materialsId, isPreviewDownload, now } = this;
            const fn = isPreviewDownload ? 'previewPaperMaterials' : 'previewMaterials';
            return `${
                this.$globalVar.isDev ? this.$globalVar.appConfig.AXIOS_DEFAULT_CONFIG.baseURL : '/rpc'
            }/comm/rs/jasper/${fn}?materialsId=${materialsId}&valueType=${materialsValueType}&valueId=${materialsValueId}&_T=${now}`;
        },
        downUrl() {
            const { materialsValueType, materialsValueId, materialsId, now } = this;
            return `${
                this.$globalVar.isDev ? this.$globalVar.appConfig.AXIOS_DEFAULT_CONFIG.baseURL : '/rpc'
            }/comm/rs/jasper/downPaperMaterials?materialsId=${materialsId}&valueType=${materialsValueType}&valueId=${materialsValueId}&_T=${now}`;
        },
    },
    methods: {
        onSignature(fieldName) {
            this.currentSignatureField = fieldName;
            this.$refs.editSignature.open();
        },
        async onEditSignatureInput(val) {
            console.log(val);
            const { materialsValueType, materialsValueId, materialsId, currentSignatureField } = this;
            const { msg } = await this.$api.Member.Sv.MaterialsValue.saveMaterials({
                valueType: materialsValueType,
                valueId: materialsValueId,
                materialsId,
                materialsValue: JSON.stringify({ [currentSignatureField]: val }),
            });
            this.now = new Date().getTime();
            await getMaterialsValueList({ valueType: materialsValueType, valueId: materialsValueId });
            if (msg) this.$messageBox.tips(msg);
        },
        async saveMaterials(paperMaterialsImage) {
            const { materialsValueType, materialsValueId, materialsId, initDataJson } = this;
            const { msg } = await this.$api.Member.Sv.MaterialsValue.saveMaterials({
                valueType: materialsValueType,
                valueId: materialsValueId,
                materialsId,
                materialsValue: JSON.stringify({ paperMaterialsImage }),
            });
            initDataJson.paperMaterialsImage = paperMaterialsImage;
            this.now = new Date().getTime();
            await getMaterialsValueList({ valueType: materialsValueType, valueId: materialsValueId });
            if (msg) this.$messageBox.tips(msg);
        },
        async selectFile(index) {
            const { address, name } = await this.$refs.upload.selectFile();
            if (!address || !name) {
                this.$messageBox.alert('上传失败!');
                return null;
            }
            const { materialsNum, initDataJson } = this;
            let paperMaterialsImage = address;
            if (materialsNum > 1) {
                const oldPaperMaterialsImage = ((initDataJson || {}).paperMaterialsImage || '').split(',');
                console.log(initDataJson);
                const arr = Array(materialsNum)
                    .fill('')
                    .reduce((pre, cur, i) => {
                        pre.push(oldPaperMaterialsImage[i] || '');
                        return pre;
                    }, []);
                arr.splice(index, 1, address);
                paperMaterialsImage = arr.join(',');
            }
            return this.saveMaterials(paperMaterialsImage);
        },
    },
};
</script>

<style lang="scss">
.signature-wrap {
    padding: $padding;
    > .x-button {
        + .x-button {
            margin-left: $margin-small;
        }
    }
    .desc {
        font-size: $little * 100%;
        color: $color-text-minor;
        margin-top: $margin-small;
    }
}
</style>
