<!-- Created by henian.xu on 2020/3/27. -->

<template>
    <Page>
        <Container>
            <Article
                :label="detail.title"
                :author="detail.author"
                :source="detail.source"
                :time="detail.releaseTime"
            >
                <div
                    v-if="isRichText"
                    class="pa-l pa-r pa-b"
                    v-html="detail.content"
                ></div>
                <template v-if="isImage">
                    <ImageList
                        :prop="{src:'imageUrl',label:'desc'}"
                        :data="imageFileList"
                    />
                </template>
                <XVideo
                    v-if="isVideo"
                    :src="videoFileUrl"
                    :cover="detail.titleImageUrl"
                />
            </Article>
            <!--<div class="pa-a bc-w ma-t">
                <XLabel>
                    <XIcon
                        content="f10d"
                        class="ma-rs"
                    />评论
                </XLabel>
                <List>
                    <CommentItem></CommentItem>
                </List>
            </div>-->
        </Container>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins/index';

export default {
    name: 'DetailPage',
    mixins: [pageMixin],
    data() {
        return {
            cmsId: this.$route.params.id,
            detail: {},
        };
    },
    computed: {
        cmsArticleType() {
            return +this.detail.cmsArticleType;
        },
        isRichText() {
            return this.cmsArticleType === 1;
        },
        isImage() {
            return this.cmsArticleType === 2;
        },
        isVideo() {
            return this.cmsArticleType === 3;
        },
        imageFileList() {
            return this.detail.imageFileList;
        },
        videoFileUrl() {
            return this.detail.videoFileUrl;
        },
    },
    async created() {
        const { data } = await this.$api.Comm.Pt.Cms.cmsArticleDetail(this.cmsId);
        this.detail = data;
    },
};
</script>

<style lang="scss">
</style>
