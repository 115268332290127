<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <PageBar direction="column">
            <Tab v-model="tabIndex">
                <TabPanel
                    v-for="item in tabList"
                    :key="item.id"
                    :sign="item.id"
                    :label="item.name"
                />
            </Tab>
        </PageBar>
        <CooperationList
            v-for="(item,key,index) in tabShowed"
            :key="`${key}:${index}`"
            ref="cooperationList"
            v-show="tabKey === key"
            :cooperation-status="key"
        />
        <PageBar
            position="bottom"
        >
            <XButton
                class="item"
                label="添加"
                theme="main"
                @click="onAdd"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { Device } from 'utils';
import CooperationList from './_CooperationList';

const { mapGetters: mapGettersDic } = createNamespacedHelpers('Dictionary');

export default {
    name: 'CredentialsListPage',
    components: { CooperationList },
    data() {
        return {
            tabKey_: this.$route.query.tabKey,
            tabShowed: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        const { name } = from;
        console.log(name);
        next(vm => {
            // 是 企业合作申请 返回时刷新数据
            if (name === 'EnterpriseCooperationAdd') {
                const { cooperationList } = vm.$refs;
                cooperationList.forEach(component => {
                    const { cooperationStatus } = component;
                    if ((cooperationStatus && cooperationStatus !== '5') || !component.refreshList) return;
                    component.refreshList();
                });
            }
        });
    },
    computed: {
        ...mapGettersDic([
            'enterpriseCooperationStatusList',
            'enterpriseCooperationStatusMap',
            // add
        ]),
        tabList() {
            return [
                {
                    id: '',
                    name: '全部',
                },
                ...this.enterpriseCooperationStatusList.filter(item => item.id !== 99),
            ];
        },
        tabKey() {
            const { tabList, tabKey_ } = this;
            return `${tabKey_ || ''}` || `${(tabList[0] || {}).id || ''}`;
        },
        tabIndex: {
            get() {
                const { tabKey, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = `${item.id}` === tabKey;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                this.tabKey_ = tab.id;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        tabKey: this.tabKey_,
                    },
                });
            },
        },
    },
    watch: {
        tabKey: {
            handler(val) {
                if (!this.$utils.isDef(val)) return;
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
    methods: {
        onAdd() {
            if (Device.weixin && !Device.windowsWechat) {
                this.$messageBox.alert('手机端浏览器暂不支持操作，请在电脑浏览器操作!');
                return;
            }
            this.$router.push('/enterprise/cooperation/add');
        },
    },
};
</script>

<style lang="scss">
</style>
