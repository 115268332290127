<!-- Created by henian.xu on 2022/10/26. -->

<template>
    <div
        class="admit-card-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners">
        <div class="header">
            <XIcon
                content="f08b"
                theme="main"
            />
            <XLabel>{{data.verticalGroupingName}}</XLabel>
        </div>
        <div class="body">
            <div class="inner">
                <dl>
                    <dt>准考证号:</dt>
                    <dd>{{data.admissionTicketNumber}}</dd>
                </dl>
                <dl>
                    <dt>科目:</dt>
                    <dd>{{data.testSubjects}}</dd>
                </dl>
                <dl>
                    <dt>考试时间:</dt>
                    <dd>{{data.examTime}}</dd>
                </dl>
                <dl>
                    <dt>证考:</dt>
                    <dd>{{data.certCatNamePath}}</dd>
                </dl>
            </div>
            <XIcon
                content="f012"
                size="small"
                theme="g5"
            />
        </div>
        <div class="footer">
            <XButton
                link
                theme="main">下载准考证</XButton>
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'AdmitCardItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.admit-card-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
    > .footer {
        margin: $padding 0;
        text-align: right;
    }
}
</style>
