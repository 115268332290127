/**
 * Created by henian.xu on 2020/5/9.
 *
 */

import Vue from 'vue';
import { Api } from 'api/index';

export const state = Vue.observable({
    invoiceDetail: null,
});

export async function getInvoiceDetail(orderId) {
    const { data } = await Api.Member.Ex.OrderInvoice.invoiceDetail({
        orderId,
    });
    state.invoiceDetail = data;
}
