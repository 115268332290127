<!-- Created by henian.xu on 2020/11/20. -->

<template>
    <Page>
        <Container>
            <List
                v-if="listData && listData.length"
                class="ma-t"
                padding
                margin
            >
                <OnlyTrainingItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    @click="()=>{onItem(item)}"
                ></OnlyTrainingItem>
            </List>

            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { pageMixin, paginationMixin } from 'mixins';
import OnlyTrainingItem from './_OnlyTrainingItem.vue';

export default {
    name: 'ListPage',
    components: { OnlyTrainingItem },
    mixins: [pageMixin, paginationMixin],
    data() {
        return {
            certCatId: '',
        };
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Ce.OnlyTrainingApply.applyList(params);
            console.log(res.data);
            this.listData.push(...res.data);
            return res;
        },
        onAdd() {
            const { certCatPicker } = this.$refs;
            if (!certCatPicker) return;
            certCatPicker.open();
        },
        async onCertCatInput(val) {
            const { data: skuId } = await this.$api.Member.Gd.Goods.searchSku({
                goodsType: '8',
                certExamType: '5',
                certCatId: val,
            });

            const { data, code, success, msg } = await this.$api.Member.Ex.Order.buyNowConfirm(
                {
                    skuId,
                    quantity: 1,
                },
                {
                    isHandleError: true,
                },
            );
            if (!success) {
                if (code === 1200) {
                    await this.$messageBox.confirm(msg, '温馨提示', {
                        confirmBtnText: '立即实名',
                    });
                    this.$router.push(`/user/idCardAuth`);
                } else {
                    this.$messageBox.alert(msg);
                }
                return;
            }
            this.$router.push(`/order/new/${data.uuid}`);
            console.log('onCertCatInput', val, skuId);
        },
        async onItem(item) {
            this.$router.replace(`/onlyTraining/applyMaterials/${item.id}`);
        },
    },
};
</script>

<style lang="scss">
</style>
