<!-- Created by henian.xu on 2021/10/26. -->

<template>
    <CellGroup border>
        <Cell
            v-for="(item,index) in materialsList"
            :key="index"
            :label="`${index+1}.${item.materialsName}`"
            :link="(isEdit||item._expired) && item.isEditMaterials"
            @click="()=>{onEdit(item)}"
        >
            <template #extra>
                <div class="extra">
                    <template v-if="(item.materialsStatus && item.materialsStatus !== 10) || !item.isEditMaterials">
                        <XButton
                            v-if="item.isNeedSignature && isEdit"
                            theme="primary"
                            radius="all"
                            size="small"
                            lock-text
                            @click.prevent.stop="()=>{onPreview(item)}"
                        >{{item.isSignature?'已':'未'}}签名</XButton>
                        <XButton
                            v-else-if="item.isNeedPMImage && isEdit"
                            theme="primary"
                            radius="all"
                            size="small"
                            lock-text
                            @click.prevent.stop="()=>{onPreview(item)}"
                        >{{item.isUploadPMImage?'已':'未'}}上传</XButton>
                        <XButton
                            v-else
                            theme="success"
                            radius="all"
                            size="small"
                            lock-text
                            @click.prevent.stop="()=>{onPreview(item)}"
                        >预览</XButton>
                    </template>
                    <span :class="[{'tc-danger':item._expired}]">{{item.materialsStatusName}}</span>
                </div>
            </template>
        </Cell>
    </CellGroup>
</template>

<script>
import { MaterialsListMixin, getMaterialsValueList } from 'pages/Materials/store';

export default {
    name: 'MaterialsList',
    mixins: [MaterialsListMixin],
    data() {
        return {};
    },
    props: {
        valueType: {
            type: [String, Number],
            default: '',
        },
        valueId: {
            type: [String, Number],
            default: '',
        },
    },
    computed: {
        materialsValueType() {
            return this.valueType || '';
        },
        materialsValueId() {
            return this.valueId || '';
        },
    },
    methods: {
        async onEdit(item) {
            // :to="(isEdit||item._expired) && item.isEditMaterials?`/materials/editMaterials/${item.materialsId}`:''"
            const { isEdit, materialsValueType, materialsValueId } = this;
            const { materialsId, _expired, isEditMaterials } = item;
            if (!((isEdit || _expired) && isEditMaterials)) return null;
            const query = {
                valueType: materialsValueType,
                valueId: materialsValueId,
            };
            return this.$router.push({
                path: `/materials/editMaterials/${materialsId}`,
                query,
            });
        },
        async onPreview(item) {
            const { isNeedSignature, isNeedPMImage, materialsId } = item;
            const { materialsValueType, materialsValueId } = this;
            const query = {
                valueType: materialsValueType,
                valueId: materialsValueId,
            };
            if (isNeedSignature) query.isNeedSignature = true;
            if (isNeedPMImage) query.isNeedPMImage = true;
            return this.$router.push({
                path: `/materials/previewMaterials/${materialsId}`,
                query,
            });
        },
        refreshList() {
            const { materialsListKey, materialsValueType, materialsValueId } = this;
            if (!materialsListKey) return;
            getMaterialsValueList({ valueType: materialsValueType, valueId: materialsValueId });
            console.log('refreshList');
        },
    },
};
</script>

<style lang="scss">
</style>
