/**
 * Created by henian.xu on 2020/5/9.
 *
 */

import Vue from 'vue';
import { Api } from 'api/index';

export const state = Vue.observable({
    trainingLiveDetail: null,
});

export async function getTrainingLiveDetail(trainingLiveId) {
    const { data } = await Api.Member.Ce.TrainingLive.detail({
        trainingLiveId,
    });
    state.trainingLiveDetail = data;
}
