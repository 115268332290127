<!-- Created by henian.xu on 2020/4/21. -->

<template>
    <Page>
        <Container>
            <Article :label="detail.messageTitle">
                <div
                    class="info fx-jc-c"
                    slot="info"
                >
                    <span class="item">时间: {{ detail.sendTime|moment('YYYY-MM-DD HH:mm') }}</span>
                </div>
                <div
                    class="pa-l pa-r pa-b"
                    style="white-space: pre-wrap"
                    v-html="detail.messageContent"
                ></div>
            </Article>
        </Container>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins/index';

export default {
    name: 'NoticePage',
    mixins: [pageMixin],
    data() {
        return {
            id: this.$route.params.id,
            detail: {},
        };
    },
    methods: {
        async getDetail() {
            const { id } = this;
            if (!id) return null;
            const res = await this.$api.Comm.Ms.UserMessage.userMessageDetail(id);
            this.detail = res.data;
            return res;
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
</style>
