<!-- Created by henian.xu on 2020/5/26. -->

<template>
    <Container>
        <!--{{tabIndex}}
        {{tabShowed}}-->
        <List
            class="ma-t"
            padding
            margin
        >
            <CooperationItem
                v-for="item in listData"
                :key="item.id"
                :data="item"
                :to="`/enterprise/cooperation/detail/${item.id}`"
            />
        </List>
        <Pagination
            ref="infinite"
            :disabled="paginationDisabled"
            @load="onload"
        />
    </Container>
</template>

<script>
import { paginationMixin } from 'mixins/index';

export default {
    name: 'CooperationList',
    mixins: [paginationMixin],
    data() {
        return {};
    },
    props: {
        cooperationStatus: {
            type: String,
            required: true,
        },
    },
    methods: {
        async getListData(params) {
            const { cooperationStatus } = this;
            const res = await this.$api.Member.Mb.Enterprise.cooperationList({
                cooperationStatus,
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
    },
};
</script>

<style lang="scss">
</style>
