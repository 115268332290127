<!-- Created by henian.xu on 2020/5/26. -->

<template>
    <div
        class="schedule-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div class="header">
            <XIcon
                content="f08a"
                theme="main"
            />
            <XLabel>{{data.certCatName}}</XLabel>
        </div>
        <div class="body">
            <div class="inner">
                <dl>
                    <dt><div>学校</div>:</dt>
                    <dd>{{data.schoolOrgName}}</dd>
                </dl>
                <dl>
                    <dt><div>编班名称</div>:</dt>
                    <dd>{{data.subsidyGroupingName}}</dd>
                </dl>
                <dl>
                    <dt><div>编批次号</div>:</dt>
                    <dd>{{data.subsidyGroupingSn}}</dd>
                </dl>
            </div>
        </div>
        <CellGroup
            class="footer"
        >
            <Cell
                label="立即申请补贴"
                link
            />
        </CellGroup>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'ScheduleItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" scoped>
.schedule-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                    margin-right: $margin-small;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    @include clearfix;
                    > div {
                        width: 5em;
                        text-align: justify;
                        overflow: hidden;
                        margin-bottom: -($body-font-size * $line-height);
                        float: left;
                        &:after {
                            display: inline-block;
                            content: '';
                            padding-left: 100%;
                        }
                    }
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
    > .footer {
        border-top: 1px solid $color-border;
    }
}
</style>
