<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container v-if="order.id">
            <div class="refund-detail-apply-info">
                <div class="body">
                    <div class="inner">
                        <dl>
                            <dt>申请单号:</dt>
                            <dd>{{refundApply.applySn}}</dd>
                        </dl>
                        <dl>
                            <dt>提交时间:</dt>
                            <dd>{{refundApply.applyTime | moment}}</dd>
                        </dl>
                        <dl>
                            <dt>状　　态:</dt>
                            <dd>{{refundApply.refundApplyStatusName}}</dd>
                        </dl>
                        <dl>
                            <dt>状态更新:</dt>
                            <dd>{{refundApply.applyTime | moment('YYYY-MM-DD HH:mm')}}</dd>
                        </dl>
                    </div>
                </div>
            </div>
            <OrderItem
                :data="order"
                only-show
                style="box-shadow:none;border:none"
            ></OrderItem>
            <div class="ma-t"></div>
            <CellGroup border>
                <Cell label="试算可退款">
                    <template #extra>
                        <div class="price tc-success">{{refundApply.estimateRefundAmount}}</div>
                    </template>
                </Cell>
                <Cell
                    label="退款事由"
                    direction="column"
                >
                    <template #extra>
                        <div class="extra ma-ts">{{refundApply.refundReason}}</div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                试算明细
            </div>
            <CellGroup border>
                <Cell label="订单可扣款金额">
                    <template #extra>
                        <div class="price">{{(refundApply.totalAmount - refundApply.refundedAmount)||0 | price}}</div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                可扣款金额默认为订单实付金额，若发送过退款，则可退款金额=订单实付金额-已退款金额。
            </div>
            <CellGroup border>
                <Cell label="税点扣款">
                    <template #extra>
                        <div class="tc-price">-<span class="price">{{detail.taxAmount}}</span></div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                若订单已开票，申请退款则需要扣除税点
            </div>
            <CellGroup border>
                <Cell label="证考服务扣款">
                    <template #extra>
                        <div class="tc-price">-<span class="price">{{detail.certExamServiceAmount}}</span></div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                包括报名材料审核、排班等服务
            </div>
            <CellGroup border>
                <Cell label="课时学习扣款">
                    <template #extra>
                        <div class="tc-price">-<span class="price">{{detail.courseServiceAmount}}</span></div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                已点播学习课程{{courseServiceList.length}}节
            </div>
            <CellGroup border>
                <Cell label="练习扣款">
                    <template #extra>
                        <div class="tc-price">-<span class="price">{{detail.practiceServiceAmount}}</span></div>
                    </template>
                </Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <template v-for="item in practiceServiceList">
                    {{item.practiceTypeName}} {{item.useTimeMin}} 分钟，
                </template>合计试题练习 {{detail.practiceTotalUseTime}} 分钟。
            </div>
        </Container>
        <PageBar
            v-if="order.id&&refundApplyStatus===10"
            position="bottom">
            <XButton
                class="item"
                label="取消申请"
                theme="success"
                @click="()=>{onCancelApply()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
export default {
    name: 'DetailPage',
    data() {
        return {
            applyId: this.$route.query.applyId,
            detail: {},
        };
    },
    computed: {
        order() {
            return this.detail.order || {};
        },
        refundApply() {
            return this.detail.refundApply || {};
        },
        refundApplyStatus() {
            return +this.refundApply.refundApplyStatus || 0;
        },
        courseServiceList() {
            return this.detail.courseServiceList || [];
        },
        practiceServiceList() {
            return this.detail.practiceServiceList || [];
        },
        orderDetailList() {
            return this.order.orderDetailList || [];
        },
        orderType() {
            return +this.order.orderType || 1;
        },
        orderDetail() {
            return this.orderDetailList[0] || {};
        },
        specDesc() {
            return (this.orderDetail.specDesc || '').split(':').pop();
        },
        isSendEmail() {
            const { detail } = this;
            if (!detail) return false;
            const { invoiceType } = detail;
            return `${invoiceType}` === '1' || `${invoiceType}` === '3';
        },
        isEnterprise() {
            const { detail } = this;
            if (!detail) return false;
            const { invoiceTitleType } = detail;
            return `${invoiceTitleType}` === '1';
        },
    },
    methods: {
        async getDetail() {
            const { applyId } = this;
            const { data } = await this.$api.Member.Ex.RefundApply.detail({
                id: applyId,
            });
            this.detail = data;
        },
        async onCancelApply() {
            const { applyId } = this;
            await this.$messageBox.confirm('是否确认取消申请退款？');
            const { msg } = await this.$api.Member.Ex.RefundApply.cancelApply({
                id: applyId,
            });
            await this.getDetail();
            if (msg) this.$messageBox.tips(msg);
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
.refund-detail-apply-info {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    overflow: hidden;
    margin-bottom: $margin;

    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
}
</style>
