<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <Page>
        <Container>
            <CellGroup>
                <Cell
                    v-for="item in listData"
                    :key="item.id"
                    :label="item.examName"
                    :to="`/experience/paper/${item.id}`"
                />
            </CellGroup>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins/index';

export default {
    name: 'IndexPage',
    mixins: [paginationMixin],
    data() {
        return {};
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Sv.ExamPaperExperience.examPaperExperienceList({
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
    },
};
</script>

<style lang="scss">
</style>
