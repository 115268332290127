<!-- Created by henian.xu on 2020/1/3. -->

<template>
    <Linker
        class="user-box"
        to="/user/info"
    >
        <div class="inner">
            <Avatar
                class="avatar"
                size="1.5rem"
                radius="all"
                border
                :src="userInfo.avatar"
            />
            <div class="info">
                <div class="nickname">
                    {{userInfo.nickName}}
                </div>
                <div class="account">
                    账号: {{userInfo.userName}}
                </div>
                <div class="real-name">
                    <Badge
                        :theme="userInfo.isRealNameAuth?'warning':'g6'"
                        class="pa-l pa-r"
                    >
                        <XIcon
                            class="ma-rs"
                            content="f03d"
                        />{{userInfo.isRealNameAuth?'已实名':'未实名'}}
                    </Badge>
                </div>
                <!--<div class="nickname">
                    <XIcon
                        content="f080"
                        theme="success"></XIcon> {{userInfo.nickName}}</div>-->
            </div>
        </div>
    </Linker>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'UserBox',
    data() {
        return {};
    },
    computed: {
        ...mapGetters('User', ['userInfo']),
    },
};
</script>

<style lang="scss">
.user-box {
    display: block;
    background-color: $color-main;
    padding: $padding-big $padding;
    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding-small $padding-big;
        margin-bottom: $margin;
        /*.avatar {
            flex: 0 0 auto;
            width: 150px;
            height: 150px;
            border-radius: 100vw;
            border: 1px solid #fff;
        }*/
        .info {
            flex: 1 1 1%;
            margin-left: $margin;
            line-height: 1.5;
            color: #fff;

            > .account,
            > .nickname {
                font-size: $little * 100%;
            }
        }
    }
}
</style>
