<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <PageBar>
            <Tab
                average
                v-model="tabIndex">
                <TabPanel
                    v-for="item in tabList"
                    :key="item.key"
                    :label="item.label"
                />
            </Tab>
        </PageBar>
        <Container>
            <div class="ma-t"></div>
            <!--{{tabIndex}}
            {{tabShowed}}-->
            <List
                padding
                margin
            >
                <CertificateReceiveItem
                    v-for="item in listData"
                    :key="item.id"
                    :dataset="item"
                    :to="`/certificateReceive/detail/${item.id}`"
                    @change="onOrderItem"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { paginationMixin } from 'mixins/index';
import CertificateReceiveItem from './_CertificateReceiveItem';
import { state } from './store';

export default {
    name: 'ListPage',
    mixins: [paginationMixin],
    components: { CertificateReceiveItem },
    data() {
        return {
            paginationDisabled: true,
            tabList: [
                {
                    certificateReceiveStatus: '',
                    label: '全部',
                },
                {
                    certificateReceiveStatus: '10',
                    label: '待确认',
                },
                {
                    certificateReceiveStatus: '20',
                    label: '待到校自取',
                },
                {
                    certificateReceiveStatus: '30',
                    label: '待寄送',
                },
                {
                    certificateReceiveStatus: '100',
                    label: '已领证',
                },
            ],
            tabShowed: {},
            certificateReceiveStatus_: this.$route.params.certificateReceiveStatus,
        };
    },
    computed: {
        tabListMap() {
            return this.tabList.reduce((pre, cur, __index) => {
                const { certificateReceiveStatus } = cur;
                pre[`${certificateReceiveStatus || ''}`] = {
                    ...cur,
                    __index,
                };
                return pre;
            }, {});
        },
        certificateReceiveStatus() {
            const { tabList, tabListMap, certificateReceiveStatus_ } = this;
            const tab = tabListMap[certificateReceiveStatus_] || tabList[0];
            const { certificateReceiveStatus } = tab;
            return `${certificateReceiveStatus || ''}`;
        },
        tabIndex: {
            get() {
                const { certificateReceiveStatus, tabList } = this;
                let index = -1;
                tabList.some((item, i) => {
                    const b = `${item.certificateReceiveStatus || ''}` === certificateReceiveStatus;
                    if (b) index = i;
                    return b;
                });
                return index < 0 ? 0 : index;
            },
            set(val) {
                const { tabList } = this;
                const tab = tabList[val];
                if (!tab) return;
                const { certificateReceiveStatus } = tab;
                console.log(certificateReceiveStatus);
                this.certificateReceiveStatus_ = `${certificateReceiveStatus || ''}`;
                this.$router.replace({
                    ...this.$route,
                    params: {
                        ...this.$route.params,
                        certificateReceiveStatus: this.certificateReceiveStatus_ || undefined,
                    },
                });
                this.refreshList();
            },
        },
        currentTab() {
            const { tabIndex, tabList } = this;
            return tabList[tabIndex] || tabList[0];
        },
    },
    watch: {
        tabIndex: {
            handler(val) {
                this.$set(this.tabShowed, val, true);
            },
            immediate: true,
        },
    },
    methods: {
        async getListData(params) {
            const { currentTab } = this;
            const res = await this.$api.Member.Ce.CertificateReceiveDetail.list({
                ...currentTab,
                ...params,
            });
            state.needRefresh = false;
            this.listData.push(...res.data);
            return res;
        },
        onOrderItem(data) {
            if (data === null) {
                this.refreshList();
            }
        },
    },
    created() {
        this.refreshList();
    },
    activated() {
        if (state.needRefresh) {
            this.refreshList();
        }
    },
};
</script>

<style lang="scss">
</style>
