<!-- Created by henian.xu on 2022/10/26. -->

<template>
    <div class="survey-topic-list">
        <div
            v-for="(item,index) in dataset"
            :key="item.evaluateId"
            class="item">
            <SurveyTopic
                :dataset="item"
                :disabled="disabled"
                :index="index+1"
                :value="(model[index]||{}).selectEvaluateDetailId"
                @input="($event)=>{onTopicInput($event,item,index)}"
            ></SurveyTopic>
        </div>
    </div>
</template>

<script>
import SurveyTopic from 'pages/Survey/_SurveyTopic';

export default {
    name: 'SurveyTopicList',
    components: { SurveyTopic },
    data() {
        return {};
    },
    props: {
        value: {
            type: Array,
            default: null,
        },
        dataset: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: null,
        },
    },
    computed: {
        model: {
            get() {
                const { value, dataset } = this;
                if (value !== null) return value;
                return dataset.map(item => {
                    const { id, selectEvaluateDetailId } = item;
                    return {
                        id,
                        selectEvaluateDetailId: selectEvaluateDetailId || 0,
                    };
                });
            },
            set(val) {
                if (this.value === null) return;
                this.$emit('input', val);
            },
        },
    },
    methods: {
        async saveAnswer(question, answer, index) {
            return new Promise((resolve, reject) => {
                if (!this.$listeners['confirm-answer']) {
                    resolve();
                    return;
                }
                this.$emit('confirm-answer', { question, answer, index, resolve, reject });
            });
        },
        async onTopicInput(val, item, index) {
            const res = {
                evaluateId: item.evaluateId,
                selectEvaluateDetailId: val,
                isConfirm: this.strictConfirm ? false : item.questionType !== 3,
            };
            if (res.isConfirm) await this.saveAnswer(item, res);

            const oldValue = this.value[index];
            if (!oldValue) {
                this.$emit('add-answer');
            }

            this.value.splice(index, 1, res);
            this.model = this.value;
        },
    },
};
</script>

<style lang="scss">
.survey-topic-list {
    //padding: $padding;
    > .item {
        + .item {
            margin-top: $margin;
        }
    }
}
</style>
