<!-- Created by henian.xu on 2020/11/20. -->

<template>
    <Page>
        <Container>
            <List
                v-if="listData && listData.length"
                class="ma-t"
                padding
                margin
            >
                <ScheduleItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    @click="()=>{onItem(item)}"
                ></ScheduleItem>
            </List>
            <div
                v-else
                class="pa-a ta-c tc-minor"
            >当前未找到，补贴申请资格</div>
        </Container>
    </Page>
</template>

<script>
import ScheduleItem from './_ScheduleItem';

export default {
    name: 'ListPage',
    components: { ScheduleItem },
    data() {
        return {
            listData: [],
        };
    },
    methods: {
        async getListData() {
            const { data } = await this.$api.Member.Ce.SubsidyApply.waitApplySubsidyList();
            console.log(data);
            this.listData = data;
        },
        async onItem(item) {
            const { skuId } = item;
            const { data, code, success, msg } = await this.$api.Member.Ex.Order.buyNowConfirm(
                {
                    skuId,
                    quantity: 1,
                },
                {
                    isHandleError: true,
                },
            );
            if (!success) {
                if (code === 1200) {
                    await this.$messageBox.confirm(msg, '温馨提示', {
                        confirmBtnText: '立即实名',
                    });
                    this.$router.push(`/user/idCardAuth`);
                } else {
                    this.$messageBox.alert(msg);
                }
                return;
            }
            this.$router.replace(`/order/new/${data.uuid}`);
            // if (msg) this.$messageBox.tips(msg);
        },
    },
    created() {
        this.getListData();
    },
};
</script>

<style lang="scss">
</style>
