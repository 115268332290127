<!-- Created by henian.xu on 2024/7/2. -->

<template>
    <Page>
        <container>
            <Collapse
                v-if="applyPracticalList && applyPracticalList.length"
                border>
                <CollapseItem
                    v-for="applyPractical in applyPracticalList"
                    :key="applyPractical.id"
                    :label="applyPractical.practicalSubjectCode"
                    :extra="`通过:${applyPractical.passNum||0}/${applyPractical.totalNum||0}`"
                >
                    <CellGroup
                        v-if="applyPractical.detailList && applyPractical.detailList.length"
                        border>
                        <Cell
                            v-for="(item,index) in applyPractical.detailList"
                            :key="item.id"
                            @click="onItem(item,index)"
                            focus-extra
                            direction="column"
                            link
                        >
                            <template #label>
                                <div class="label">{{item.practicalProjectCode}} {{item.practicalProjectName}}</div>
                            </template>
                            <template #extra>
                                <div class="apply-practical-info-wrap">
                                    <dl>
                                        <dt><div>考试时间</div></dt>
                                        <dd>{{item.examTimeBegin || '未提交' | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                                    </dl>
                                    <dl>
                                        <dt><div>考试用时</div></dt>
                                        <dd>{{item.examTime || '未提交'}}</dd>
                                    </dl>
                                    <dl>
                                        <dt><div>模拟成绩</div></dt>
                                        <dd>{{item.examScore || '未提交'}}
                                            <template v-if="item.examResultName">({{item.examResultName}})</template>
                                        </dd>
                                    </dl>
                                    <dl class="tc-minor">
                                        <dt><div>更新时间</div></dt>
                                        <dd>{{item.updateTime || '未提交' | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                                    </dl>
                                </div>
                            </template>
                        </Cell>
                    </CellGroup>
                </CollapseItem>
            </Collapse>
        </container>
    </Page>
</template>

<script>
export default {
    name: 'MockPracticalPage',
    data() {
        return {
            certExamApplyId: this.$route.params.certExamApplyId,
            applyPracticalList: [],
        };
    },
    methods: {
        async getApplyPracticalList() {
            const { data } = await this.$api.Member.Ce.CertExamApply.applyPracticalList({
                certExamApplyId: this.certExamApplyId,
            });
            this.applyPracticalList = data;
        },
        onItem(item) {
            this.$router.push({
                path: `/exam/saveMockPractical/${this.certExamApplyId}`,
                query: {
                    practicalProjectId: item.practicalProjectId,
                    practicalProjectCode: item.practicalProjectCode,
                    practicalProjectName: item.practicalProjectName,
                },
            });
        },
    },
    activated() {
        this.getApplyPracticalList();
    },
};
</script>

<style lang="scss">
.apply-practical-info-wrap {
    margin-top: $margin;
    font-size: $little * 100%;
    @include bodyDl(4em);
}
</style>
