<!-- Created by henian.xu on 2020/4/21. -->

<template>
    <Page>
        <Container>
            <XForm
                label-width="5em"
                border
                show-feedback
                ref="form"
                :model="formModel"
                :rules="formRules"
            >
                <FormItem
                    label="证件类型:"
                    prop="idCardType"
                >
                    <XSelect
                        data="[DICTIONARY]:idCardType"
                        v-model="formModel.idCardType"
                    ></XSelect>
                </FormItem>
                <template v-if="formModel.idCardType">
                    <FormItem
                        label="姓　　名:"
                        prop="fullName"
                    >
                        <XInput
                            v-model="formModel.fullName"></XInput>
                    </FormItem>
                    <FormItem
                        :label="`${idCardLabel}:`"
                        prop="idCard"
                    >
                        <XInput
                            v-model="formModel.idCard"></XInput>
                    </FormItem>
                </template>
            </XForm>
        </Container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="提交"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { mapActions } from 'vuex';
import { Valid } from 'utils';

export default {
    name: 'IdCardAuthPage',
    data() {
        return {
            formModel: {
                idCardType: '1',
                fullName: '',
                idCard: '',
            },
            formRules: {
                idCardType: {
                    required: true,
                    message: '不能为空',
                },
                fullName: {
                    required: true,
                    message: '不能为空',
                },
                idCard: [
                    {
                        required: true,
                        message: '不能为空',
                    },
                    {
                        validator: this.validIdCard,
                        message: '身份证号码错误',
                    },
                ],
            },
        };
    },
    computed: {
        idCardLabel() {
            const { formModel } = this;
            const map = {
                1: '身份证号',
                2: '护 照 号',
                4: '证 件 号',
                8: '证 件 号',
            };
            return map[formModel.idCardType] || '证 件 号';
        },
    },
    methods: {
        ...mapActions('User', {
            userUpdated: 'updated',
        }),
        validIdCard(rule, value, callback) {
            const { idCardType } = this.formModel;
            if (`${idCardType}` === '1') {
                Valid.iDCard(rule, value, callback);
                return;
            }
            callback();
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const params = {
                ...this.formModel,
            };
            const { msg } = await this.$api.Member.Mb.Member.realNameAuth(params);
            await this.userUpdated();
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
</style>
