<!-- Created by henian.xu on 2020/7/9. -->

<template>
    <Page>
        <Container fill>
            <div class="qrcode-wrap">
                <div class="header">
                    <div class="label">{{detail.certCatName}}</div>
                    <div class="desc">班级群</div>
                </div>
                <XImage
                    width="50%"
                    :aspect-ratio="1"
                    :src="groupQrCodeUrl"
                ></XImage>
                <div class="footer">
                    <div class="label">长按识别二维码立即加入</div>
                    <div class="desc">快来认识一起考试的同班同学吧!</div>
                </div>
            </div>
        </Container>
    </Page>
</template>

<script>
export default {
    name: 'Learn',
    data() {
        return {
            verticalGroupingId: this.$route.params.id,
            detail: {},
        };
    },
    computed: {
        groupQrCodeUrl() {
            return this.detail.groupQrCodeUrl || '';
        },
    },
    async created() {
        const { data } = await this.$api.Member.Ce.VerticalGrouping.verticalGroupingDetail(this.verticalGroupingId);
        this.detail = data;
    },
};
</script>

<style lang="scss">
.qrcode-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: $margin-big * 5;

    > .header {
        margin-bottom: $margin-big;
        > .label {
            font-size: $big * 100%;
            font-weight: bold;
            margin-bottom: $margin-small;
        }
    }
    > .footer {
        margin-top: $margin-big;
        > .desc {
            margin-top: $margin;
            color: $color-text-minor;
        }
    }
}
</style>
