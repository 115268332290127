<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <OrderGoodsItem
                :data="applyDetail"
            />

            <template v-if="applyStatus===40">
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c tc-danger">
                    <XLabel>不通过原因</XLabel>
                </div>
                <CellGroup border>
                    <Cell>
                        <template #label>
                            <div
                                class="tc-danger"
                                style="word-break: break-all"
                            >{{auditOpinion}}</div>
                        </template>
                    </Cell>
                </CellGroup>
            </template>

            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
                align="right"
                border
                show-feedback
                scroll-into-error
            >
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>工作信息</XLabel>
                </div>
                <FormItem
                    prop="companyName"
                    direction="column"
                    label="现就职单位全称"
                >
                    <XInput
                        placeholder="未就业可放空"
                        :disabled="!isEdit"
                        v-model="formModel.companyName"
                    />
                </FormItem>
                <FormItem
                    prop="workRegionId"
                    direction="column"
                    label="工作所在地区"
                >
                    <RegionPicker
                        v-model="formModel.workRegionId"
                        :disabled="!isEdit"
                    />
                </FormItem>
                <FormItem
                    prop="politicalLandscape"
                    direction="column"
                    label="政治面貌"
                >
                    <XSelect
                        data="[DICTIONARY]:politicalLandscape"
                        v-model="formModel.politicalLandscape"
                        :disabled="!isEdit"
                    />
                </FormItem>
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>报名材料</XLabel>
                </div>
                <CellGroup border>
                    <Cell
                        v-for="(item,index) in applyMaterialsList"
                        :key="index"
                        :label="`${index+1}.${item.applyMaterialsName}`"
                        :to="(isEdit||item._expired) && item.isEditMaterials?`/course/editMaterials/${certExamApplyId}/${item.applyMaterialsId}`:''"
                    >
                        <template #extra>
                            <div class="extra">
                                <template v-if="(item.applyMaterialsStatus && item.applyMaterialsStatus !== 10) || !item.isEditMaterials">
                                    <XButton
                                        v-if="item.isNeedSignature && isEdit && applyStatus!==10 && applyStatus!==40"
                                        theme="primary"
                                        radius="all"
                                        size="small"
                                        lock-text
                                        @click.prevent.stop="()=>{onPreview(item)}"
                                    >{{item.isSignature?'已':'未'}}签名</XButton>
                                    <XButton
                                        v-else-if="item.isNeedPMImage && isEdit"
                                        theme="primary"
                                        radius="all"
                                        size="small"
                                        lock-text
                                        @click.prevent.stop="()=>{onPreview(item)}"
                                    >{{item.isUploadPMImage?'已':'未'}}上传</XButton>
                                    <XButton
                                        v-else
                                        theme="success"
                                        radius="all"
                                        size="small"
                                        lock-text
                                        @click.prevent.stop="()=>{onPreview(item)}"
                                    >预览</XButton>
                                </template>
                                <span :class="[{'tc-danger':item._expired}]">{{item.applyMaterialsStatusName}}</span>
                            </div>
                        </template>
                    </Cell>
                </CellGroup>

                <template v-if="applyStatus===10 || applyStatus===40">
                    <div
                        key="formModel.signature.div"
                        class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                        <XLabel>材料确认签名</XLabel>
                    </div>
                    <FormItem
                        key="formModel.signature"
                        prop="signature"
                        direction="column"
                    >
                        <EditSignature
                            is-batch
                            v-model="formModel.signature"
                        ></EditSignature>
                    </FormItem>
                </template>

                <div
                    v-if="applyDetail.applyTemplateDesc"
                    class="pa-a tc-g6">
                    <XLabel>填报说明:</XLabel>
                    <XLabel :label="applyDetail.applyTemplateDesc"></XLabel>
                </div>
                <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                    <XLabel>报名备注</XLabel>
                </div>
                <FormItem
                    prop="memberMsg"
                    direction="column"
                >
                    <XInput
                        type="textarea"
                        :min-rows="2"
                        :max-rows="6"
                        v-model="formModel.memberMsg"
                        placeholder="如您想什么时候安排考试？有没有什么特别说明."
                        :disabled="!isEdit"
                    />
                </FormItem>
                <!--<FormItem
                    label="报名类型:"
                    prop="certExamApplyType"
                >
                    <XSelect
                        :data="certExamApplyType"
                        v-model="formModel.certExamApplyType"
                        :disabled="!isEdit"
                    />
                </FormItem>-->
            </XForm>
        </Container>
        <PageBar
            position="bottom"
        >
            <XButton
                v-if="isEdit"
                class="item"
                label="提交审核"
                theme="success"
                @click="()=>{onSubmit()}"
            ></XButton>
            <XButton
                v-else
                class="item"
                :label="applyDetail.applyStatusName"
                theme="primary"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import { state } from '../index/applyListStore';

export default {
    name: 'ApplyMaterialsPage',
    data() {
        return {
            certExamApplyId: this.$route.params.id,
            formModel: {
                memberMsg: '',
                certExamApplyType: '',
                companyName: '',
                workRegionId: '',
                signature: '',
                politicalLandscape: '',
            },
            formRules: {
                certExamApplyType: {
                    required: true,
                    message: '不能为空',
                },
                workRegionId: {
                    validator: this.validWorkRegionId,
                    message: '不能为空',
                },
                politicalLandscape: {
                    validator: this.validWorkRegionId,
                    message: '不能为空',
                },
                signature: {
                    required: true,
                    message: '不能为空',
                },
            },
            detail: {},
            isInitialized: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        const { name } = from;
        console.log(name);
        next(vm => {
            // 是编辑材料返回时刷新数据
            if (name === 'CourseEditMaterials' || name === 'CoursePreviewMaterials') {
                vm.getMaterialsDetail();
            }
        });
    },
    computed: {
        ...mapGetters('Dictionary', ['certExamApplyType', 'certExamApplyTypeMap']),
        applyDetail() {
            return this.detail.applyDetail || {};
        },
        applyMaterialsList() {
            return (this.detail.applyMaterialsList || []).map(item => {
                return {
                    ...item,
                    _expired: [97, 98, 99, 10].includes(item.applyMaterialsStatus) || !item.applyMaterialsStatus,
                };
            });
        },
        auditOpinion() {
            // 10 40 可编辑
            return this.applyDetail.auditOpinion || '无';
        },
        applyStatus() {
            // 10 40 可编辑
            return this.applyDetail.applyStatus;
        },
        isEdit() {
            const { applyStatus } = this;
            return applyStatus === 10 || applyStatus === 40;
        },
    },
    watch: {
        applyDetail(val) {
            if (!val || !val.id) return;
            if (this.isInitialized) return;
            this.formModel.memberMsg = val.memberMsg;
            this.formModel.certExamApplyType = val.certExamApplyType;
            this.formModel.companyName = val.companyName || '';
            this.formModel.workRegionId = val.workRegionId || '';
            this.formModel.politicalLandscape = val.politicalLandscape || '';
            const { form } = this.$refs;
            form.clearValidate();
            this.isInitialized = true;
        },
    },
    methods: {
        async validWorkRegionId(rule, value, callback) {
            const { companyName } = this.formModel;
            if (companyName && !value) {
                callback(new Error(rule.message));
                return;
            }
            callback();
        },
        async getMaterialsDetail() {
            const { data } = await this.$api.Member.Ce.CertExamApply.applyDetail(this.certExamApplyId);
            this.detail = data;
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const { formModel, certExamApplyId } = this;
            const { msg } = await this.$api.Member.Ce.CertExamApply.submitAudit({
                certExamApplyId,
                ...formModel,
            });
            state.needRefresh = true;
            this.$router.back();
            if (msg) this.$messageBox.tips(msg);
        },
        async onPreview(item) {
            const { isNeedSignature, isNeedPMImage, applyMaterialsId } = item;
            const { applyStatus, certExamApplyId } = this;
            const query = { applyStatus };
            if (isNeedSignature) query.isNeedSignature = true;
            if (isNeedPMImage) query.isNeedPMImage = true;
            this.$router.push({
                path: `/course/previewMaterials/${certExamApplyId}/${applyMaterialsId}`,
                query,
            });
        },
    },
    created() {
        this.getMaterialsDetail();
    },
};
</script>

<style lang="scss">
</style>
