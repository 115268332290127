/**
 * Created by henian.xu on 2020/4/21.
 *
 */

import Vue from 'vue';
import { Api } from 'api/index';

export const state = Vue.observable({
    detail: {},
});

export async function getPracticeDetail(certExamApplyId) {
    const { data } = await Api.Member.Ce.CertExamPaper.practiceExamPaperInfo({ certExamApplyId });
    state.detail = data;
}
