<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <Page>
        <PageBar class="pa-l pa-r">
            <span>已用时: {{countdownStr}}</span>
            <XButton
                label="交卷"
                theme="main"
                radius="all"
                @click="()=>{onSubmit()}"
            />
        </PageBar>
        <Container fill>
            <TopicList
                ref="topicList"
                v-if="isActivated && questionList && questionList.length"
                :data="currentQuestionList"
                v-model="answer"
                :index="swiperActiveIndex"
                :start-index="currentQuestionStartIndex"
                @index-change="onTopicListIndexChange"
            />
            <div
                v-else
                class="loading ta-c pa-a ma-tb"
            >
                <XImage
                    :src="$loadingIcon"
                    width="20%"
                ></XImage>
            </div>
        </Container>
        <PageBar
            position="bottom"
            class="pa-l pa-r"
        >
            <XButton
                label="上一题"
                theme="success"
                radius="all"
                :disabled="!topicListIndex"
                @click="onPrev"
            ></XButton>
            <AnswerCard
                :data="questionList"
                :value="answer"
                :index="topicListIndex"
                @index-change="onIndexChange"
                @submit="()=>{onSubmit()}"
            />
            <XButton
                label="下一题"
                theme="success"
                radius="all"
                :disabled="topicListIndex+1 === questionListLength"
                @click="onNext"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
import { formatDuration } from 'utils/index';
import { refMixin } from 'packages/mixins';

export default {
    name: 'PaperPage',
    mixins: [refMixin],
    data() {
        return {
            paperId: this.$route.params.id,
            defaultPageSize: 10,
            topicListIndex: 0,
            swiperActiveIndex: 0,
            answer: [],
            detail: {},
            countdownTimer: null,
            countdown: 1,
            isActivated: false,
        };
    },
    computed: {
        questionList() {
            return this.detail.questionTypeList || [];
        },
        halfDefaultPageSize() {
            return ~~(this.defaultPageSize / 2);
        },
        currentQuestionStartIndex() {
            const { topicListIndex, halfDefaultPageSize } = this;
            return Math.max(topicListIndex - halfDefaultPageSize, 0);
        },
        currentQuestionList() {
            const { defaultPageSize, currentQuestionStartIndex } = this;
            const index = currentQuestionStartIndex;
            if (!this.questionList) return null;
            const questionList = [...this.questionList];
            return questionList.splice(index, defaultPageSize);
        },
        questionListLength() {
            const { questionList } = this;
            return (questionList && questionList.length) || 0;
        },
        questionListTem() {
            const { currentQuestionList } = this;
            if (!currentQuestionList) return [];
            return currentQuestionList.map(item => {
                if (!item) return '';
                return item.id;
            });
        },
        countdownStr() {
            return formatDuration(this.countdown);
        },
    },
    methods: {
        async getPaperDetail() {
            const { data } = await this.$api.Member.Sv.ExamPaperExperience.getExamPaper(this.paperId);
            const { questionTypeList } = data;
            this.answer = Array(questionTypeList.length || 0).fill(null);
            this.detail = data;
            if (this.isActivated) {
                this.startCountdown();
            }
        },
        async onIndexChange(val) {
            const topicList = await this.getRefsChildren('topicList');
            const { halfDefaultPageSize } = this;
            this.topicListIndex = val;
            if (this.topicListIndex < halfDefaultPageSize) {
                this.swiperActiveIndex = this.topicListIndex;
                topicList.slideTo(this.swiperActiveIndex, 0, false);
            } else {
                this.swiperActiveIndex = halfDefaultPageSize;
                topicList.slideTo(halfDefaultPageSize, 0, false);
            }
        },
        onPrev() {
            if (!this.questionListLength) return;
            this.topicListIndex -= this.topicListIndex > 0 ? 1 : 0;
            this.onIndexChange(this.topicListIndex);
        },
        onNext() {
            if (!this.questionListLength) return;
            this.topicListIndex += this.topicListIndex < this.questionListLength - 1 ? 1 : 0;
            this.onIndexChange(this.topicListIndex);
        },
        async onTopicListIndexChange(activeIndex) {
            await this.$nextTick();
            const {
                swiperActiveIndex,
                topicListIndex,
                halfDefaultPageSize,
                questionListLength,
                // add
            } = this;
            const offsetIndex = activeIndex - swiperActiveIndex;
            this.topicListIndex = Math.min(Math.max(topicListIndex + offsetIndex, 0), questionListLength);
            if (this.topicListIndex < halfDefaultPageSize) {
                this.swiperActiveIndex = this.topicListIndex;
            } else {
                this.swiperActiveIndex = halfDefaultPageSize;
                const topicList = await this.getRefsChildren('topicList');
                topicList.slideTo(halfDefaultPageSize);
            }
        },
        startCountdown() {
            clearInterval(this.countdownTimer);
            this.countdown = 1000;
            this.countdownTimer = setInterval(() => {
                this.countdown = Math.max(this.countdown + 1000, 0);
            }, 1000);
        },
        async onSubmit() {
            const answer = this.answer.filter(Boolean);
            const { paperId, questionListLength } = this;
            const unanswered =
                questionListLength -
                answer.reduce((pre, cur) => {
                    return pre + (+cur.selectOption ? 1 : 0);
                }, 0);
            if (unanswered) {
                await this.$messageBox.confirm(`您还有 ${unanswered} 题未答是否确认交卷`);
            } else {
                await this.$messageBox.confirm(`您是否确认交卷`);
            }
            const {
                data: { isPass, score },
            } = await this.$api.Member.Sv.ExamPaperExperience.completeExamPaper({
                id: paperId,
                questionJson: JSON.stringify(answer),
            });

            await this.$messageBox.confirm(
                isPass ? `恭喜您考试通过，您的成绩为${score}分` : `很遗憾考试未通过，您的分数为${score}分`,
            );

            this.$router.back();
        },
    },
    async created() {
        this.getPaperDetail();
    },
    beforeDestroy() {
        clearInterval(this.countdownTimer);
    },
    activated() {
        setTimeout(() => {
            this.isActivated = true;
            this.startCountdown();
        }, 500);
    },
};
</script>

<style lang="scss">
</style>
