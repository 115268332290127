<!-- Created by henian.xu on 2020/5/26. -->

<template>
    <div
        class="only-training-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div class="inner">
            <div class="header">
                <XIcon
                    content="f08a"
                    theme="main"
                />
                <XLabel>{{data.certCatName}}</XLabel>
            </div>
            <div class="body">
                <div class="inner">
                    <dl>
                        <dt><div>报名</div>:</dt>
                        <dd>{{data.onlyTrainingApplyStatusName}}</dd>
                    </dl>
                    <dl>
                        <dt><div>报名时间</div>:</dt>
                        <dd>{{data.applyTime | moment()}}</dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="ctrl">
            <XIcon
                content="f012"
                theme="g5"
                size="small"
            ></XIcon>
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'OnlyTrainingItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" scoped>
.only-training-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .inner {
        flex: 1 1 1%;
        > .header {
            margin: $padding;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .x-icon {
                margin-right: $margin-small;
            }
            > .x-label {
                flex: 1;
                font-weight: bold;
            }
        }
        > .body {
            margin: $padding;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .inner {
                flex: 1;
                dl {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    + dl {
                        margin-top: $margin-small;
                    }
                    > dt {
                        flex: 0 0 auto;
                        margin-right: $margin-small;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        @include clearfix;
                        > div {
                            width: 5em;
                            text-align: justify;
                            overflow: hidden;
                            margin-bottom: -($body-font-size * $line-height);
                            float: left;
                            &:after {
                                display: inline-block;
                                content: '';
                                padding-left: 100%;
                            }
                        }
                    }
                    > dd {
                        flex: 1;
                    }
                }
            }
        }
        > .footer {
            border-top: 1px solid $color-border;
        }
    }
    > .ctrl {
        padding: $padding;
    }
}
</style>
