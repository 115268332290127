<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container v-if="userInfo.isEnterprise">
            <EnterpriseBox :data="enterpriseDetail"/>
            <CellGroup
                class="ma-t"
                border
            >
                <Cell
                    icon="f097"
                    icon-theme="main"
                    icon-size="big"
                    label="企业资料"
                    :extra="enterpriseDetail.enterpriseStatusName"
                    link
                    to="/enterprise/applyMaterials"
                />
                <Cell
                    icon="f097"
                    icon-theme="main"
                    icon-size="big"
                    label="企业合作"
                    link
                    @click="onCooperation"
                />
                <Cell
                    v-if="enterpriseAuthority && (enterpriseAuthority & 2)"
                    icon="f097"
                    icon-theme="main"
                    icon-size="big"
                    label="实训补充材料"
                    to="/enterprise/supplementMaterials"
                />
                <Cell
                    icon="f097"
                    icon-theme="main"
                    icon-size="big"
                    label="企业开票信息"
                    to="/enterprise/invoiceInfo"
                />
                <!--                <Cell
                    icon="f097"
                    icon-theme="main"
                    icon-size="big"
                    label="培训月报表"
                    to="/enterprise/monthStat"
                />-->
                <Cell
                    icon="f097"
                    icon-theme="main"
                    icon-size="big"
                    label="培训结算单"
                    to="/enterprise/statement"
                />
                <Cell
                    icon="f092"
                    icon-theme="main"
                    icon-size="big"
                    label="企业领证"
                    to="/enterprise/certificateReceive/list"
                />
            </CellGroup>
            <GridGroup
                :column="2"
                class="ma-t"
            >
                <Grid label="累计培训人次">
                    <template #icon>
                        <div class="tc-primary fs-super">{{cooperationMemberStat.totalNum}}</div>
                    </template>
                </Grid>
                <Grid label="已发证">
                    <template #icon>
                        <div class="tc-success fs-super">{{cooperationMemberStat.status100}}</div>
                    </template>
                </Grid>
            </GridGroup>
            <GridGroup class="ma-t">
                <Grid label="材料待提交">
                    <template #icon>
                        <div class="fs-super">{{cooperationMemberStat.status10}}</div>
                    </template>
                </Grid>
                <Grid label="审核不通过">
                    <template #icon>
                        <div class="tc-danger fs-super">{{cooperationMemberStat.status40}}</div>
                    </template>
                </Grid>
                <Grid label="未编班">
                    <template #icon>
                        <div class="fs-super">{{cooperationMemberStat.status30}}</div>
                    </template>
                </Grid>
                <Grid label="待补考">
                    <template #icon>
                        <div class="fs-super">{{cooperationMemberStat.status60}}</div>
                    </template>
                </Grid>
                <Grid label="已编班">
                    <template #icon>
                        <div class="fs-super">{{cooperationMemberStat.status50}}</div>
                    </template>
                </Grid>
                <Grid label="待发证">
                    <template #icon>
                        <div class="fs-super">{{cooperationMemberStat.status70}}</div>
                    </template>
                </Grid>
            </GridGroup>
            <div class="pa-a">
                <XButton
                    theme="primary"
                    size="big"
                    lock-text
                    radius
                    fill
                    @click="onExportApplyList"
                >导出学员学习状态明细表</XButton>
            </div>
            <GridGroup :column="2">
                <Grid label="近期编班待考试学员">
                    <template #icon>
                        <div class="tc-primary fs-super">{{cooperationMemberStat.statusWaitExam}}</div>
                    </template>
                </Grid>
                <Grid label="近期待考试编班数量">
                    <template #icon>
                        <div class="tc-success fs-super">{{cooperationMemberStat.groupingStatus20}}</div>
                    </template>
                </Grid>
            </GridGroup>
            <div class="pa-a">
                <XButton
                    theme="primary"
                    size="big"
                    lock-text
                    radius
                    fill
                    @click="exportVerticalGroupingApplyList"
                >导出学员考试信息明细表</XButton>
            </div>
        </Container>
        <template v-else>
            <Container>
                <XForm
                    ref="form"
                    :model="formModel"
                    :rules="formRules"
                    align="right"
                    border
                    show-feedback
                >
                    <FormItem
                        label="企业名称"
                        prop="enterpriseName">
                        <XInput v-model="formModel.enterpriseName"></XInput>
                    </FormItem>
                    <FormItem
                        label="负责人"
                        prop="principalName">
                        <XInput v-model="formModel.principalName"></XInput>
                    </FormItem>
                    <FormItem
                        label="负责人电话"
                        prop="principalTel">
                        <XInput v-model="formModel.principalTel"></XInput>
                    </FormItem>
                    <FormItem
                        label="企业所在地区"
                        prop="regionId">
                        <RegionPicker v-model="formModel.regionId"></RegionPicker>
                    </FormItem>
                    <FormItem
                        label="详细地址"
                        prop="address"
                        direction="column"
                    >
                        <XInput
                            type="textarea"
                            :min-rows="2"
                            :max-rows="6"
                            v-model="formModel.address"></XInput>
                    </FormItem>
                </XForm>
            </Container>
            <PageBar position="bottom">
                <XButton
                    class="item"
                    label="下一步"
                    theme="success"
                    @click="()=>{onSubmit()}"
                ></XButton>
            </PageBar>
        </template>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins/index';
import EnterpriseBox from 'pages/Enterprise/_EnterpriseBox';
import { enterpriseMixin } from 'pages/Enterprise/enterpriseStore';
import { Device } from 'utils/Device';

export default {
    name: 'IndexPage',
    components: { EnterpriseBox },
    mixins: [pageMixin, enterpriseMixin],
    data() {
        return {
            formModel: {
                enterpriseName: '',
                principalName: '',
                principalTel: '',
                regionId: '',
                address: '',
            },
            formRules: {
                enterpriseName: {
                    required: true,
                    message: '不能为空',
                },
                principalName: {
                    required: true,
                    message: '不能为空',
                },
                principalTel: {
                    required: true,
                    message: '不能为空',
                },
                regionId: {
                    required: true,
                    message: '不能为空',
                },
                address: {
                    required: true,
                    message: '不能为空',
                },
            },
        };
    },
    computed: {
        enterpriseAuthority() {
            return this.enterpriseDetail.enterpriseAuthority || 0;
        },
    },
    methods: {
        onCooperation() {
            if (this.enterpriseDetail.enterpriseStatus !== 100) {
                this.$messageBox.alert('企业资料审核未通过!');
                return;
            }
            this.$router.push('/enterprise/cooperation');
        },
        onExportApplyList() {
            if (Device.weixin && !Device.windowsWechat) {
                this.$messageBox.alert('手机端浏览器暂不支持操作，请在电脑浏览器操作!');
                return;
            }
            this.$http.href('/member/mb/enterpriseExcel/exportApplyList');
        },
        exportVerticalGroupingApplyList() {
            if (Device.weixin && !Device.windowsWechat) {
                this.$messageBox.alert('手机端浏览器暂不支持操作，请在电脑浏览器操作!');
                return;
            }
            this.$http.href('/member/mb/enterpriseExcel/exportVerticalGroupingApplyList');
        },
        async onSubmit() {
            await this.$refs.form.validate();
            const { msg } = this.$api.Member.Mb.Enterprise.saveEnterpriseInfo(this.formModel);
            this.$router.push('/enterprise/applyMaterials');
            this.userInfoUpdated();
            if (msg) this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
</style>
