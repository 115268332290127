<!-- Created by henian.xu on 2020/3/31. -->

<template>
    <Page>
        <Container>
            <div class="score-detail-box">
                <div class="header">
                    <XIcon
                        content="f08a"
                        theme="main"
                    />
                    <div class="x-label">{{applyDetail.certExamTypeName}}</div>
                </div>
                <div class="body">
                    <div class="inner">
                        <div class="cart-cat">{{applyDetail.certCatNamePath}}</div>
                        <dl>
                            <dt><div>报名</div>:</dt>
                            <dd><Badge>{{applyDetail.applyStatusName}}</Badge></dd>
                        </dl>
                        <dl>
                            <dt><div>学校</div>:</dt>
                            <dd>{{applyDetail.schoolOrgName}}</dd>
                        </dl>
                        <dl>
                            <dt><div>学员</div>:</dt>
                            <dd>{{applyDetail.fullName}}</dd>
                        </dl>
                        <dl>
                            <dt><div>实名证件</div>:</dt>
                            <dd>{{applyDetail.idCardNo}}</dd>
                        </dl>
                        <dl>
                            <dt><div>报名时间</div>:</dt>
                            <dd>{{applyDetail.examApplyTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                        </dl>
                        <dl>
                            <dt><div>成绩总评</div>:</dt>
                            <dd>{{applyDetail.overallRatingResultName}}</dd>
                        </dl>
                        <dl>
                            <dt><div>学习群</div>:</dt>
                            <dd>
                                <span @click.stop.prevent="()=>{}">
                                    <Linker
                                        class="tc-primary"
                                        :to="`/groupQRCode/learn/${applyDetail.id}`"
                                    >点我加入学习群</Linker>
                                </span>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>考试科目成绩</XLabel>
            </div>
            <CellGroup border>
                <Cell
                    v-for="item in applyDetail.subjectList"
                    :key="item.id"
                    :label="item.appraisalSubjectName"
                    :extra="item.examResult"
                ></Cell>
            </CellGroup>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>学习完成度</XLabel>
                <div class="extra">累计学时 {{applyDetail.totalLearnHour || 0}}</div>
            </div>

            <div v-if="listData && listData.length">
                <div
                    v-for="courseCat in listData"
                    :key="courseCat.courseCatType"
                    :label="courseCat.courseCatTypeName"
                >
                    <div class="pa-l pa-r"><Divider :label="courseCat.courseCatTypeName"></Divider></div>

                    <Collapse
                        v-if="courseCat.certExamCourseCatList && courseCat.certExamCourseCatList.length"
                        border>
                        <CollapseItem
                            v-for="(cat,i) in courseCat.certExamCourseCatList"
                            :key="cat.id"
                            :fold="!!i"
                            :label="cat.courseCatName"
                        >
                            <CellGroup
                                v-if="cat.courseDetailList && cat.courseDetailList.length"
                                border
                            >
                                <Cell
                                    v-for="(item,index) in cat.courseDetailList"
                                    :key="item.id"
                                    style="background-color: transparent;"
                                >
                                    <template #label>
                                        <div class="label">
                                            <Badge
                                                theme="g7"
                                                plain
                                            >{{item.courseTypeName}}</Badge>
                                            {{index+1}}.{{item.courseName}}
                                        </div>
                                    </template>
                                    <template #extra>
                                        <div
                                            v-if="userInfo.servantId!==2"
                                            class="extra tc-success">{{item.completionRatio * 100}}%</div>
                                    </template>
                                </Cell>
                            </CellGroup>
                        </CollapseItem>
                    </Collapse>
                </div>
            </div>

        </Container>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import Divider from 'components/widget/decoration/Divider.vue';
import { state, getCourseList } from './courseStore';

export default {
    name: 'ScorePage',
    components: { Divider },
    data() {
        return {
            certExamApplyId: this.$route.params.id,
            detail: {},
        };
    },
    computed: {
        ...mapGetters('User', ['userInfo']),
        listData() {
            const { listData, certExamApplyId } = state;
            if (this.certExamApplyId && +this.certExamApplyId !== +certExamApplyId) {
                getCourseList(this.certExamApplyId);
                return [];
            }
            return listData;
        },
        applyDetail() {
            return this.detail.applyDetail || {};
        },
    },
    methods: {
        async getMaterialsDetail() {
            const { data } = await this.$api.Member.Ce.CertExamApply.applyDetail(this.certExamApplyId);
            this.detail = data;
        },
    },
    created() {
        this.getMaterialsDetail();
    },
};
</script>

<style lang="scss">
.score-detail-box {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    overflow: hidden;

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1 1 1%;
            > .cart-cat {
                margin-bottom: $margin-small;
            }
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                    margin-right: $margin-small;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    @include clearfix;
                    > div {
                        width: 5em;
                        text-align: justify;
                        overflow: hidden;
                        margin-bottom: -($body-font-size * $line-height);
                        float: left;
                        &:after {
                            display: inline-block;
                            content: '';
                            padding-left: 100%;
                        }
                    }
                }
                > dd {
                    flex: 1;
                }
            }
            > .dl-wrap {
                margin-top: $margin-small;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                > dl {
                    flex: 0 0 50%;
                    + dl {
                        margin-top: 0;
                    }

                    &:nth-child(n + 3) {
                        margin-top: $margin-small;
                    }
                }
            }
        }
        > .x-icon {
            flex: 0 0 auto;
        }
    }
    > .footer {
        padding: $padding 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $color-border;
        > .x-button {
            flex: 1;
            color: $color-main;

            + .x-button {
                border-left: 1px solid $color-border;
            }

            > .badge {
                position: absolute;
                top: $padding-small;
                right: $padding;
            }
        }
    }
}
</style>
