<!-- Created by henian.xu on 2020/10/15. -->

<template>
    <Page>
        <Container>
            <div
                class="upload-wrap"
                @click="()=>{onUpload()}"
            >
                <XIcon
                    v-show="!uploading"
                    content="f04c"
                    size="1rem"
                    theme="main"
                ></XIcon>
                <Upload
                    v-show="uploading"
                    class="fx-jc-c fx-as-s"
                    placeholder="导入学员名单"
                    :columns="2.5"
                    :size="300*1024*1024"
                    ref="upload"
                    accept=".pdf"
                    action="/member/mb/enterprise/saveEnterprisePracticeMaterials"
                    :max-files="1"
                    @start="(val)=>{onUploadStart(val)}"
                    @success="(val)=>{onUploadSuccess(val)}"
                    @error="(val)=>{onUploadError(val)}"
                ></Upload>
                <div class="label">上传实训补充材料</div>
                <div class="tc-minor ma-ts">请先与编班老师沟通确认学员名单</div>
                <div class="desc">
                    <h4>实操培训材料盖章pdf扫描上传，顺序如下：</h4>
                    <ol>
                        <li>实操培训证明；</li>
                        <li>实操培训鉴定单位企业标准化三级以上达标证书；</li>
                        <li>实操师傅材料（师傅工作经验证明、身份证、毕业证、职称证、操作证等等）；</li>
                        <li>实操培训信息汇总表；</li>
                        <li>实操培训鉴定表（按学员名单顺序）。</li>
                    </ol>
                </div>
            </div>
            <div class="pa-a tc-minor">历史记录</div>

            <List
                v-if="listData && listData.length"
                padding
                margin>
                <div
                    v-for="item in listData"
                    :key="item.id"
                    class="supplement-materials-item">
                    <div class="header">
                        <div class="label">{{item.fileName}}</div>
                    </div>
                    <div class="body">
                        <dl>
                            <dt><div>关联编班</div></dt>
                            <dd v-if="item.verticalGroupingId">{{item.verticalGroupingName}}</dd>
                            <dd v-else>未关联</dd>
                        </dl>
                        <dl>
                            <dt><div>关联时间</div></dt>
                            <dd v-if="item.verticalGroupingId">{{item.relationTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                            <dd v-else>未关联</dd>
                        </dl>
                        <dl>
                            <dt><div>上传时间</div></dt>
                            <dd>{{item.insertTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                        </dl>
                    </div>
                    <div class="footer">
                        <XButton
                            theme="success"
                            link
                            @click="onDownload(item)"
                        >下载文件</XButton>
                    </div>
                </div>
            </List>

            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { Device } from 'utils/Device';
import { paginationMixin } from 'mixins/index';

export default {
    name: 'SupplementMaterialsPage',
    mixins: [paginationMixin],
    data() {
        return {
            uploading: false,
        };
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Mb.Enterprise.enterprisePracticeMaterialsList({
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        onUploadStart() {
            this.uploading = true;
        },
        onUploadSuccess() {
            this.uploading = false;
            this.$refs.upload.clear();
            this.refreshList();
        },
        onUploadError(err) {
            this.uploading = false;
            this.$refs.upload.clear();
            this.$messageBox.alert(err.message || '上传失败!');
        },
        async onUpload() {
            await this.$http.post('/member/mb/enterprise/saveEnterprisePracticeMaterials');
            this.$refs.upload.selectFile();
        },
        onDownload(item) {
            if (Device.weixin && !Device.windowsWechat) {
                this.$messageBox.alert('手机端浏览器暂不支持操作，请在电脑浏览器操作!');
                return;
            }
            this.$http.href('/downloadFile', {
                fileAddress: item.fileAddressUrl,
                fileName: item.fileName,
            });
        },
    },
};
</script>

<style lang="scss">
.upload-wrap {
    padding: $padding-big * 2 $padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $color-component-bgc;
    > .label {
        margin-top: $margin;
    }
    > .desc {
        margin-top: $margin-small;
        //font-size: $little * 100%;
        color: $color-text-minor;
        align-self: stretch;
        text-align: left;

        > ol {
            margin-left: 1.2em;
        }
    }
}
.supplement-materials-item {
    background-color: $color-component-bgc;
    padding: $padding;
    > .header {
        > .label {
            font-weight: bold;
            font-size: 110%;
        }
    }
    > .body {
        margin-top: $margin;
        @include bodyDl(4em);
    }
    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
