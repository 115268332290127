<!-- Created by henian.xu on 2020/5/8. -->

<template>
    <Page>
        <container>
            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>查找题目关键字：</XLabel>
            </div>
            <div class="pa-a bc-w">
                <XInput
                    v-model="keywords"
                    @keydown.enter="onQuery"
                ></XInput>
            </div>

            <div class="pa-a tc-g6 dp-fx fx-row fx-jc-sb fx-ai-c">
                <XLabel>查找结果：</XLabel>
            </div>
            <template v-if="listData && listData.length">
                <CellGroup border>
                    <Cell
                        v-for="(item,index) in listData"
                        :key="index"
                        :label="item.questionTypeName||item.name"
                        :extra="`${item.questionNum}题`"
                        link
                        @click="onItem(item,index)"
                    ></Cell>
                </CellGroup>
            </template>
            <div
                v-else
                class="pa-a ta-c tc-minor">暂无数据</div>
        </container>
        <PageBar position="bottom">
            <XButton
                class="item"
                label="重置"
                theme="second"
                @click="onReset"
            ></XButton>
            <XButton
                class="item"
                label="查询"
                theme="success"
                @click="onQuery"
            ></XButton>
        </PageBar>
    </Page>
</template>

<script>
export default {
    name: 'SearchQuestion',
    data() {
        return {
            certExamApplyId: this.$route.params.id,
            keywords: '',
            listData: [],
        };
    },
    methods: {
        async getListData() {
            const {
                certExamApplyId,
                keywords,
                // add
            } = this;
            console.log(keywords);
            const { data } = await this.$api.Member.Ce.CertExamPaper.questionTypeList({
                certExamApplyId,
                keywords,
            });
            this.listData = data;
        },
        onReset() {
            this.keywords = '';
            this.onQuery();
        },
        onQuery() {
            this.getListData();
        },
        onItem(item) {
            const { certExamApplyId, keywords } = this;
            this.$router.push(
                `/exam/practice/7/${certExamApplyId}?questionType=${item.questionType}&keywords=${keywords}`,
            );
        },
    },
    created() {
        this.onQuery();
    },
};
</script>

<style lang="scss">
</style>
