<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container>
            <XImage
                v-if="previewUrl"
                width="100%"
                :src="`${previewUrl}`"
            ></XImage>
        </Container>
    </Page>
</template>

<script>
export default {
    name: 'DetailPage',
    data() {
        return {
            memberApplyMaterialsId: this.$route.params.memberApplyMaterialsId,
        };
    },
    computed: {
        previewUrl() {
            return `${
                this.$globalVar.isDev ? this.$globalVar.appConfig.AXIOS_DEFAULT_CONFIG.baseURL : '/rpc'
            }/comm/rs/jasper/previewMemberMaterials?memberApplyMaterialsId=${this.memberApplyMaterialsId}`;
        },
    },
};
</script>

<style lang="scss">
</style>
