<!-- Created by henian.xu on 2022/10/26. -->

<template>
    <Page>
        <Container>
            <div class="pa-t"></div>
            <List
                padding
                margin
            >
                <SurveyItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    :to="`/survey/detail/${item.id}`"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { pageMixin, paginationMixin } from 'mixins';
import SurveyItem from 'pages/Survey/_SurveyItem';

export default {
    name: 'SurveyIndexPage',
    components: { SurveyItem },
    mixins: [pageMixin, paginationMixin],
    data() {
        return {};
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Ce.SurveyItem.surveyItemList({
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
    },
    activated() {
        this.refreshList();
    },
};
</script>

<style lang="scss">
</style>
