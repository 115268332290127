<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <Page>
        <Container v-if="detail">
            <TrainingLiveItem :data="detail"></TrainingLiveItem>

            <XImage
                width="100%"
                :src="detail.qrCodeUrl"></XImage>
            <!--            <div class="training-live-qrcode-wrap">
                <div class="inner">
                    <XImage
                        width="100%"
                        :src="detail.qrCodeUrl"></XImage>
                </div>
            </div>-->
        </Container>
    </Page>
</template>

<script>
import { getTrainingLiveDetail, state } from './TrainingLiveStore';

export default {
    name: 'TrainingLiveDetailPage',
    data() {
        return {
            trainingLiveId: this.$route.params.id,
        };
    },
    computed: {
        detail() {
            const { trainingLiveId } = this;
            const { trainingLiveDetail } = state;
            if (!trainingLiveDetail || `${trainingLiveDetail.id}` !== `${trainingLiveId}`) {
                getTrainingLiveDetail(trainingLiveId);
                return null;
            }
            return trainingLiveDetail;
        },
        deliveryAddress() {
            const { detail } = this;
            if (!detail) return {};
            console.log(detail);
            return {
                consignee: detail.deliveryConsignee,
                phone: detail.deliveryPhone,
                regionId: detail.deliveryRegionId,
                address: detail.deliveryAddress,
                regionNamePath: detail.deliveryRegionNamePath,
            };
        },
        isSendEmail() {
            const { detail } = this;
            if (!detail) return false;
            const { invoiceType } = detail;
            return `${invoiceType}` === '1' || `${invoiceType}` === '3';
        },
        isEnterprise() {
            const { detail } = this;
            if (!detail) return false;
            const { invoiceTitleType } = detail;
            return `${invoiceTitleType}` === '1';
        },
    },
};
</script>

<style lang="scss">
/*.training-live-qrcode-wrap {
    //padding: $padding-big;

    > .inner {
    }
}*/
</style>
