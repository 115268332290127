<!-- Created by henian.xu on 2022/10/26. -->

<template>
    <Page>
        <Container>
            <div class="pa-a tc-danger">请长按以下准考证保存图片，自行打印。</div>
            <XImage
                v-if="admitCardUrl"
                :src="admitCardUrl"
                width="100%"></XImage>
        </Container>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins';

export default {
    name: 'AdmitCardDetailPage',
    mixins: [pageMixin],
    data() {
        return {
            admitCardId: this.$route.params.id,
        };
    },
    computed: {
        admitCardUrl() {
            const { admitCardId } = this;
            const { AXIOS_DEFAULT_CONFIG = {} } = this.$globalVar.appConfig || {};
            const url = `/comm/rs/jasper/previewAdmitCard?admitCardId=${admitCardId}`;
            return `${AXIOS_DEFAULT_CONFIG.baseURL || ''}${url}`;
        },
    },
    methods: {
        async getDetail() {
            const { data } = await this.$api.Comm.Rs.Jasper.previewAdmitCard(this.admitCardId);
            console.log(data);
            this.detail = data;
        },
    },
};
</script>

<style lang="scss">
</style>
