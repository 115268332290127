<!-- Created by henian.xu on 2022/10/26. -->

<template>
    <Page>
        <Container>
            <div class="pa-t"></div>
            <List
                padding
                margin
            >
                <AdmitCardItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    @click="()=>{onAdmitCardItem(item)}"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </Container>
    </Page>
</template>

<script>
import { pageMixin, paginationMixin } from 'mixins';
import AdmitCardItem from 'pages/AdmitCard/_AdmitCardItem';

export default {
    name: 'AdmitCardIndexPage',
    components: { AdmitCardItem },
    mixins: [pageMixin, paginationMixin],
    data() {
        return {};
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Ce.VerticalGrouping.admitCardList({
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        async onAdmitCardItem(item) {
            if (item.isNeedCompleteSurvey && item.surveyItemId) {
                await this.$messageBox.confirm(
                    '根据培训教学大纲要求，请考生先完成本次培训满意度调查，才能下载准考证，是否立即进行？',
                );
                this.$router.push(`/survey/detail/${item.surveyItemId}`);
            } else {
                this.$router.push(`/admitCard/detail/${item.id}`);
            }
        },
    },
    activated() {
        this.refreshList();
    },
};
</script>

<style lang="scss">
</style>
