<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div class="serve-page">
        <div class="serve-page-nav">
            <Tab
                v-if="!queryFormModel.queryStr"
                v-model="tabIndex"
            >
                <TabPanel
                    v-for="item in servantCat"
                    :key="item.id"
                    :label="item.name"
                />
            </Tab>
            <QueryBar
                :label="queryFormModel.queryStr?'筛选':''"
                placeholder="筛选"
                :value="queryFormModel.queryStr"
                @reset="()=>{onQueryBarReset()}"
                @confirm="()=>{onQueryBarConfirm()}"
            >
                <XForm border>
                    <FormItem label="筛选名称:">
                        <XInput
                            placeholder="请输入名称"
                            v-model="queryFormModel.keywords"
                        ></XInput>
                    </FormItem>
                    <FormItem
                        label="选择证考:"
                        direction="column"
                    >
                        <CertCatPicker
                            ref="certCatPicker"
                            v-model="queryFormModel.certCatId"
                        ></CertCatPicker>
                    </FormItem>
                </XForm>
            </QueryBar>
        </div>
        <div
            ref="scrollbar"
            class="serve-page-content"
        >
            <List
                padding="all"
                column="2"
            >
                <GoodsItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                    :to="`/serve/detail/${item.id}`"
                />
            </List>
            <Pagination
                ref="infinite"
                :disabled="paginationDisabled"
                @load="onload"
            />
        </div>
    </div>
</template>

<script>
import { paginationMixin } from 'mixins/index';
import { createNamespacedHelpers } from 'vuex';
import { scrollMemory, refMixin } from 'packages/mixins';

const { mapGetters } = createNamespacedHelpers('ServantCat');
export default {
    name: 'ServePage',
    mixins: [paginationMixin, refMixin, scrollMemory],
    data() {
        return {
            paginationDisabled: true,
            queryCatId: this.$route.query.catId,
            queryFormModel: {
                queryStr: '',
                keywords: '',
                certCatId: '',
            },
        };
    },
    computed: {
        ...mapGetters(['servantCat', 'servantCatMap']),
        catId() {
            const { queryCatId, servantCat } = this;
            return queryCatId || (servantCat[0] || {}).id || 0;
        },
        tabIndex: {
            get() {
                const { servantCatMap, catId } = this;
                const cat = servantCatMap[catId] || {};
                return cat.__index || 0;
            },
            set(val) {
                const { servantCat } = this;
                const cat = servantCat[val];
                if (!cat) return;
                this.queryCatId = cat.id;
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        catId: cat.id,
                    },
                });
            },
        },
    },
    watch: {
        catId: {
            handler(val) {
                if (!val) return;
                this.refreshList();
            },
            immediate: true,
        },
    },
    methods: {
        async getListData(params) {
            const query = {
                ...params,
            };
            const { queryFormModel } = this;
            const { keywords, certCatId } = queryFormModel;
            if (keywords || certCatId) {
                query.goodsName = keywords;
                query.certCatId = certCatId;
            } else {
                query.servantGoodsCatId = this.catId;
            }
            const res = await this.$api.Member.Gd.Goods.goodsList(query);
            this.listData.push(...res.data);
            return res;
        },
        async onQueryBarReset() {
            const certCatPicker = await this.getRefsChildren('certCatPicker');
            certCatPicker.onReset();
            const { queryFormModel } = this;
            queryFormModel.keywords = '';
            queryFormModel.certCatId = '';
        },
        async onQueryBarConfirm() {
            const { queryFormModel } = this;
            const { keywords, certCatId } = queryFormModel;
            if (keywords || certCatId) {
                const certCatPicker = await this.getRefsChildren('certCatPicker');
                const certCatPickerName = certCatPicker.getItemName();
                queryFormModel.queryStr = `${certCatPickerName}${keywords && certCatId ? ':' : ''}${keywords}`;
            } else {
                queryFormModel.queryStr = '';
            }
            this.refreshList();
        },
    },
};
</script>

<style lang="scss">
.serve-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    &-content {
        flex: 1;
        @include setScrollAxis('y');
    }
    &-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .tab {
            flex: 1;
            overflow: hidden;
            ~ .query-bar {
                flex: 0 0 auto;
            }
        }
        > .query-bar {
            flex: 1;
        }
    }
}
</style>
