/**
 * Created by henian.xu on 2020/4/9.
 *
 */

import Vue from 'vue';
import { Api } from 'api/index';

export const state = Vue.observable({
    cmsCatalogList: null,
});

export async function getCmsCatalogList() {
    const { data } = await Api.Comm.Pt.Cms.cmsCatalogList();
    state.cmsCatalogList = data;
}
